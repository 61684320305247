<template>
    <div
        id="search"
        class="searchCont uk-section-default uk-light uk-background-cover uk-background-blend-multiply top2 uk-animation-fade"
        uk-parallax="bgy: -300"
    >
        <div class="uk-container uk-container-center">
            <div
                class="uk-text-center uk-padding-small uk-animation-slide-top-medium"
            >
                <h1 class="uk-margin-small"><b>Run new report</b></h1>
                <h4 class="uk-text-uppercase uk-margin-small">
                    Search background history and online activity.
                </h4>
            </div>
            <div
                class="uk-container uk-padding-large uk-padding-remove-bottom tabs"
                uk-scrollspy="cls:uk-animation-fade"
            >
                <ul
                    class="uk-subnav uk-subnav-pill uk-flex uk-flex-center"
                    uk-switcher="animation: uk-animation-fade; connect: .searchSwitch"
                >
                    <li>
                        <a href="#">Name</a
                        ><span
                            class="triangle"
                            uk-icon="icon:triangle-down; ratio:2;"
                        ></span>
                    </li>
                    <li>
                        <a href="#">Address</a
                        ><span
                            class="triangle"
                            uk-icon="icon:triangle-down; ratio:2;"
                        ></span>
                    </li>
                    <li>
                        <a href="#">Phone</a
                        ><span
                            class="triangle"
                            uk-icon="icon:triangle-down; ratio:2;"
                        ></span>
                    </li>
                </ul>
            </div>
            <div
                class="uk-section uk-padding-small uk-padding-remove-top formWrap"
                uk-scrollspy="cls:uk-animation-fade"
            >
                <div
                    class="uk-container searchForm uk-padding-small uk-section-muted uk-text-left uk-border-rounded"
                >
                    <ul class="uk-switcher uk-margin searchSwitch">
                        <li>
                            <form
                                class="uk-grid-small"
                                method="post"
                                @submit.prevent="validateForm('searchPerson')"
                                data-vv-scope="searchPerson"
                                uk-grid
                            >
                                <div class="uk-width-1-4@m">
                                    <label>First Name</label>
                                    <VInputWithValidation
                                        v-model="formData.person.first_name"
                                        v-validate="'required'"
                                        name="first_name"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="FIRST NAME"
                                        autocomplete="off"
                                        :error="
                                            errors.first(
                                                'searchPerson.first_name'
                                            )
                                        "
                                        :valid="first_name.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-1-4@m">
                                    <label>Last Name</label>
                                    <VInputWithValidation
                                        v-model="formData.person.last_name"
                                        v-validate="'required'"
                                        name="last_name"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="LAST NAME"
                                        autocomplete="off"
                                        :error="
                                            errors.first(
                                                'searchPerson.last_name'
                                            )
                                        "
                                        :valid="last_name.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-expand@m">
                                    <label>State</label>
                                    <VSelectWithValidation
                                        v-model="formData.person.state"
                                        v-validate="'required'"
                                        name="person_state"
                                        class="uk-border-rounded"
                                        :error="
                                            errors.first('searchPerson.state')
                                        "
                                        :valid="person_state.valid"
                                    >
                                        <option selected disabled value=""
                                            >State</option
                                        >
                                        <option
                                            :model="state.alpha_2"
                                            v-for="(state, idx) in statesList"
                                            :key="idx"
                                            >{{ state.name }}</option
                                        >
                                    </VSelectWithValidation>
                                </div>
                                <div class="uk-width-expand@m">
                                    <label>Age</label>
                                    <VInputWithValidation
                                        v-model="formData.person.age"
                                        v-validate="'required'"
                                        name="age"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="AGE"
                                        autocomplete="off"
                                        :error="
                                            errors.first('searchPerson.age')
                                        "
                                        :valid="age.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-1-6@m">
                                    <div class="uk-visible@m">&nbsp;</div>
                                    <button
                                        class="uk-button uk-button-default uk-width-1-1 uk-text-center uk-border-rounded search"
                                        type="submit"
                                    >
                                        SEARCH
                                    </button>
                                </div>
                            </form>
                        </li>
                        <li>
                            <form
                                class="uk-grid-small"
                                method="post"
                                @submit.prevent="validateForm('searchAddress')"
                                data-vv-scope="searchAddress"
                                uk-grid
                            >
                                <div class="uk-width-1-5@m">
                                    <label>Street Address</label>
                                    <VInputWithValidation
                                        v-model="
                                            formData.address.street_address_1
                                        "
                                        v-validate="'required'"
                                        name="street_address_1"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="123 Fake St"
                                        autocomplete="off"
                                        :error="
                                            errors.first(
                                                'searchAddress.street_address_1'
                                            )
                                        "
                                        :valid="street_address_1.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-1-5@m">
                                    <label>Building/Apt #</label>
                                    <VInputWithValidation
                                        v-model="
                                            formData.address.street_address_2
                                        "
                                        name="street_address_2"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="Apt 1"
                                        autocomplete="off"
                                        :error="
                                            errors.first(
                                                'searchAddress.street_address_2'
                                            )
                                        "
                                        :valid="street_address_2.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-1-5@m">
                                    <label>City</label>
                                    <VInputWithValidation
                                        v-model="formData.address.city"
                                        v-validate="'required'"
                                        name="city"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="Los Angeles"
                                        autocomplete="off"
                                        :error="
                                            errors.first('searchAddress.city')
                                        "
                                        :valid="city.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-expand@m">
                                    <label>State</label>
                                    <VSelectWithValidation
                                        v-model="formData.address.state"
                                        v-validate="'required'"
                                        name="address_state"
                                        class="uk-border-rounded"
                                        :error="
                                            errors.first('searchAddress.state')
                                        "
                                        :valid="address_state.valid"
                                    >
                                        <option selected disabled value=""
                                            >State</option
                                        >
                                        <option
                                            :model="state.alpha_2"
                                            v-for="(state, idx) in statesList"
                                            :key="idx"
                                            >{{ state.name }}</option
                                        >
                                    </VSelectWithValidation>
                                </div>
                                <div class="uk-width-expand@m">
                                    <label>Postal Code</label>
                                    <VInputWithValidation
                                        v-model="formData.address.postal_code"
                                        v-validate="'required'"
                                        name="postal_code"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="12345"
                                        autocomplete="off"
                                        :error="
                                            errors.first(
                                                'searchAddress.postal_code'
                                            )
                                        "
                                        :valid="postal_code.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-1-6@m">
                                    <div class="uk-visible@m">&nbsp;</div>
                                    <button
                                        class="uk-button uk-button-default uk-width-1-1 uk-text-center uk-border-rounded search"
                                        type="submit"
                                    >
                                        SEARCH
                                    </button>
                                </div>
                            </form>
                        </li>
                        <li>
                            <form
                                class="uk-grid-small"
                                method="post"
                                @submit.prevent="validateForm('searchPhone')"
                                data-vv-scope="searchPhone"
                                uk-grid
                            >
                                <div class="uk-width-2-3@m">
                                    <label>Phone Number</label>
                                    <VInputWithValidation
                                        v-model="formData.phone.phone_number"
                                        v-validate="'required'"
                                        name="phone_number"
                                        type="tel"
                                        class="uk-border-rounded"
                                        placeholder="PHONE NUMBER"
                                        autocomplete="off"
                                        :error="
                                            errors.first(
                                                'searchPhone.phone_number'
                                            )
                                        "
                                        :valid="phone_number.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-1-3@m">
                                    <div class="uk-visible@m">&nbsp;</div>
                                    <button
                                        class="uk-button uk-button-default uk-width-1-1 uk-text-center uk-border-rounded search"
                                        type="submit"
                                    >
                                        SEARCH
                                    </button>
                                </div>
                            </form>
                        </li>
                    </ul>
                </div>
                <div
                    class="uk-text-center uk-padding-small uk-padding-remove-bottom"
                >
                    <span uk-label-success uk-icon="lock"></span> Secure and
                    private search
                </div>
            </div>
        </div>
        <div class="uk-overlay-primary uk-padding-small">
            <div class="uk-container uk-container-center uk-text-center">
                <p class="uk-text-meta">
                    Reveal things someone may be hiding about their past, secret
                    social media accounts, personal relationships you may not
                    know about.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapFields } from "vee-validate";
import { States } from "@/mixins/States";
import VInputWithValidation from "@/components/VInputWithValidation";
import VSelectWithValidation from "@/components/VSelectWithValidation";

export default {
    name: "Search",
    mixins: [States],
    components: {
        VInputWithValidation,
        VSelectWithValidation
    },
    data() {
        return {
            formData: {
                person: {
                    first_name: "",
                    last_name: "",
                    state: "",
                    age: ""
                },
                phone: {
                    phone_number: ""
                },
                address: {
                    street_address_1: "",
                    street_address_2: "",
                    city: "",
                    state: "",
                    postal_code: ""
                }
            }
        };
    },
    computed: {
        ...mapFields([
            "first_name",
            "last_name",
            "person_state",
            "phone_number",
            "street_address_1",
            "street_address_2",
            "city",
            "age",
            "address_state",
            "postal_code"
        ])
    },
    methods: {
        validateForm(formName) {
            this.$validator.validateAll(formName).then(result => {
                if (result) {
                    const submitMethod = formName + "Submit";
                    this[submitMethod]();
                }
            });
        },
        searchPersonSubmit() {
            this.$store.dispatch("search/saveSearch", {
                type: "person",
                params: this.formData.person
            });
            this.$router.push({ name: "search" });
        },
        searchPhoneSubmit() {
            this.$store.dispatch("search/saveSearch", {
                type: "phone",
                params: this.formData.phone
            });
            this.$router.push({ name: "search" });
        },
        searchAddressSubmit() {
            this.$store.dispatch("search/saveSearch", {
                type: "address",
                params: this.formData.address
            });
            this.$router.push({ name: "search" });
        }
    }
};
</script>
