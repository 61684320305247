import _ from "lodash";
import axios from "axios";
import Cookies from "js-cookie";
import * as types from "../mutation-types";

// state
export const state = {
    user: null,
    access_token: Cookies.get("access_token")
};

// getters
export const getters = {
    user: state => state.user,
    token: state => state.access_token,
    check: state => state.user !== null,
    getFormattedPhoneNumber: state => {
        if (_.isEmpty(state.user) || _.isEmpty(state.user.phone_number)) {
            return "";
        }

        let match = state.user.phone_number.match(
            /^(1|)?(\d{3})(\d{3})(\d{4})$/
        );

        if (match) {
            var intlCode = match[1] ? "+1 " : "";
            return [
                intlCode,
                "(",
                match[2],
                ") ",
                match[3],
                "-",
                match[4]
            ].join("");
        }

        return state.user.phone_number;
    }
};

// mutations
export const mutations = {
    [types.SAVE_TOKEN](state, { access_token, remember }) {
        state.access_token = access_token;
        Cookies.set("access_token", access_token, {
            expires: remember ? 365 : null
        });
    },

    [types.FETCH_USER_SUCCESS](state, { user }) {
        state.user = user;
    },

    [types.FETCH_USER_FAILURE](state) {
        state.access_token = null;
        Cookies.remove("access_token");
    },

    [types.LOGOUT](state) {
        state.user = null;
        state.access_token = null;

        Cookies.remove("access_token");
    },

    [types.UPDATE_USER](state, { user }) {
        state.user = user;
    }
};

// actions
export const actions = {
    saveToken({ commit }, payload) {
        commit(types.SAVE_TOKEN, payload);
    },

    async fetchUser({ commit }) {
        try {
            const { data } = await axios.get("/api/user");
            commit(types.FETCH_USER_SUCCESS, { user: data });
        } catch (e) {
            commit(types.FETCH_USER_FAILURE);
        }
    },

    updateUser({ commit }, payload) {
        commit(types.UPDATE_USER, payload);
    },

    async logout({ commit }) {
        try {
            await axios.post("/api/logout");
        } catch (e) {
            //do nothing
        }

        commit(types.LOGOUT);
    }
};
