import _ from "lodash";
import Vue from "vue";
import store from "@/store";
import routes from "./routes";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import { sync } from "vuex-router-sync";

Vue.use(VueRouter);
Vue.use(Meta);

// The middleware for every page of the application
const globalMiddleware = ["check-auth"];

// Load middleware modules dynamically
const routeMiddleware = resolveMiddleware(
    require.context(
        // relative path to the middleware directory
        "@/middleware",
        // include sub directories
        false,
        // reg expression to find js files
        /.*\.js$/
    )
);

const router = createRouter();

sync(store, router);

const routerHelperFuncs = {
    methods: {
        goToAnchorTag(uri) {
            const uriParams = uri.split("#");
            this.$router.push({ path: uriParams[0] }, () => {
                this.$router.push("#" + uriParams[1]);
            });
        }
    }
};

export { router, routerHelperFuncs };

// Begin support functions

/**
 * Create a new router instance
 *
 * @return {VueRouter}
 */
function createRouter() {
    const router = new VueRouter({
        // add 'scroll to top on page load' config
        scrollBehavior,
        mode: "history",
        routes
    });

    router.beforeEach(beforeEach);
    router.afterEach(afterEach);

    return router;
}

/**
 * Scroll Behavior
 *
 * @link https://router.vuejs.org/en/advanced/scroll-behavior.html
 *
 * @param  {VueRouter} to
 * @param  {VueRouter} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
function scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition;
    }

    if (to.hash) {
        router.app.$nextTick(() =>
            store.dispatch("page/updateLoadingStatus", false)
        );
        return { selector: to.hash, offset: { y: 100 } };
    }

    const [component] = router.getMatchedComponents({ ...to }).slice(-1);

    if (component && component.scrollToTop === false) {
        return {};
    }

    return { x: 0, y: 0 };
}

/**
 * Global after hook
 *
 * @param {VueRouter} to
 */
async function afterEach(to) {
    await router.app.$nextTick();

    // Get the "next" components and resolve them
    const components = await resolveComponents(
        router.getMatchedComponents({ ...to })
    );

    // Save current component name in store
    router.app.$nextTick(() =>
        store.dispatch("page/updateComponentName", components.pop().name)
    );
}

/**
 * Global router guard. Will see if user is attempting to go
 * to another component and, if so, will perform the following:
 *  - Start the loading bar
 *  - Check if "next" component has a middleware value
 *      - If so, will run that middleware
 *      - Finally go to the "next" as defined by the middleware
 *
 * @param {VueRouter} to
 * @param {VueRouter} from
 * @param {Function} next
 */
async function beforeEach(to, from, next) {
    // Get the "next" components and resolve them
    const components = await resolveComponents(
        router.getMatchedComponents({ ...to })
    );

    // If "to" doesn't contain any components, continue there now
    if (
        components.length === 0 ||
        components[components.length - 1] === undefined
    ) {
        return next();
    }

    router.app.$nextTick(() => store.dispatch("page/updatePageError", ""));

    // Start loading bar
    if (
        !_.isEmpty(components[components.length - 1]) &&
        (_.isEmpty(components[components.length - 1].pageLoader) ||
            !components[components.length - 1].pageLoader.disabled)
    ) {
        router.app.$nextTick(() =>
            store.dispatch("page/updateLoadingStatus", true)
        );
    }

    // Get the middleware for all the matched components
    const middleware = getMiddleware(components);

    // Finally call each middleware
    callMiddleware(middleware, to, from, (...args) => {
        // Set the application layout only if "next()" was called with no args
        if (args.length === 0) {
            //router.app.setLayout(components[0].layout || '')
        }

        next(...args);
    });
}

/**
 * Resolve async components
 *
 * @param {Array} components
 * @return {Array}
 */
function resolveComponents(components) {
    return Promise.all(
        components.map(component => {
            return typeof component === "function" ? component() : component;
        })
    );
}

/**
 * Merge global middleware with the component's middleware
 *
 * @param {Array} components
 * @return {Array}
 */
function getMiddleware(components) {
    const middleware = [...globalMiddleware];

    components
        .filter(c => c.middleware)
        .forEach(component => {
            if (Array.isArray(component.middleware)) {
                middleware.push(...component.middleware);
            } else {
                middleware.push(component.middleware);
            }
        });

    return middleware;
}

/**
 * Call each middleware
 *
 * @param {Array} middleware
 * @param {VueRouter} to
 * @param {VueRouter} from
 * @param {Function} next
 */
function callMiddleware(middleware, to, from, next) {
    // Flip the array of middlewares so that we call component specific middleware before global
    const stack = middleware.reverse();

    const _next = (...args) => {
        // Stop if "_next" was called with an argument or the stack is empty
        if (args.length > 0 || stack.length === 0) {
            if (args.length > 0) {
                store.dispatch("page/updateLoadingStatus", false);
            }

            return next(...args);
        }

        const currentMiddleware = stack.pop();
        if (typeof currentMiddleware === "function") {
            currentMiddleware(to, from, _next);
        } else if (routeMiddleware[currentMiddleware]) {
            routeMiddleware[currentMiddleware](to, from, _next);
        } else {
            throw Error(`Undefined middleware [${currentMiddleware}]`);
        }
    };

    _next();
}

/**
 * @param  {Object} requireContext
 * @return {Object}
 */
function resolveMiddleware(requireContext) {
    return requireContext
        .keys()
        .map(file => [
            file.replace(/(^.\/)|(\.js$)/g, ""),
            requireContext(file)
        ])
        .reduce(
            (guards, [name, guard]) => ({ ...guards, [name]: guard.default }),
            {}
        );
}
