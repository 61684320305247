var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"general-info-checklist uk-text-center"},[_c('transition',{attrs:{"enter-active-class":"uk-animation-slide-bottom-medium","leave-active-class":"uk-animation-slide-top-medium uk-animation-reverse"}},[(_vm.isProgressSectionShown)?_c('div',[_c('div',{staticClass:"uk-text-large uk-padding"},[_c('span',[_vm._v("Searching for "+_vm._s(_vm.currentChecklistItem.title))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.percentageVal)+"%")]),_vm._v(" "),_c('div',{staticClass:"uk-flex uk-flex-center"},[_c('progress',{staticClass:"uk-progress uk-width-1-3@m uk-width-1-1",attrs:{"max":_vm.percentageMax},domProps:{"value":_vm.percentageVal}})])]),_vm._v(" "),_c('ul',{staticClass:"search-list uk-grid"},_vm._l((_vm.checklistItems),function(item,index){return _c('li',{key:index,class:[
                        {
                            'active-checklist': _vm.isChecklistItemActive(
                                index
                            ),
                            'completed-checklist': _vm.isChecklistItemCompleted(
                                index
                            )
                        },
                        'uk-width-1-3@m',
                        'uk-text-muted'
                    ]},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isChecklistItemCompleted(index)),expression:"isChecklistItemCompleted(index)"}],attrs:{"uk-icon":"check"}}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isChecklistItemSpinnerShown(index)),expression:"isChecklistItemSpinnerShown(index)"}],attrs:{"uk-spinner":"ratio:.4;"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(item))])])}),0)]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"enter-active-class":"uk-animation-slide-bottom-medium","leave-active-class":"uk-animation-slide-top-medium uk-animation-reverse"}},[(_vm.isSuccessSectionShown)?_c('div',{staticClass:"uk-text-large uk-padding"},[_c('span',{staticClass:"success-message",attrs:{"uk-icon":"check"}}),_vm._v(" GENERAL\n            INFORMATION RESEARCH COMPLETE\n        ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }