<template>
    <div>
        <label v-if="label" :for="getIdValue" class="uk-form-label">{{
            label
        }}</label>
        <div class="uk-form-controls">
            <select
                :id="getIdValue"
                :name="name"
                :value="value"
                :class="[
                    { 'uk-form-danger': hasError, 'uk-form-success': valid },
                    'uk-select'
                ]"
                @change="updateValue"
                @blur="$emit('blur')"
                @focus="$emit('focus')"
            >
                <slot></slot>
            </select>
            <p v-show="error" class="form-error">{{ error }}</p>
        </div>
    </div>
</template>

<script>
import _ from "lodash/core";
export default {
    $_veeValidate: {
        name() {
            return this.getIdValue;
        },
        value() {
            return this.value;
        }
    },
    name: "VSelectWithValidation",
    props: {
        name: {
            type: String,
            required: true
        },
        value: {
            type: null,
            default: () => null
        },
        error: {
            type: String,
            default: ""
        },
        valid: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        }
    },
    data: () => ({
        innerValue: ""
    }),
    computed: {
        getIdValue() {
            if (!_.isEmpty(this.id)) {
                return this.id;
            }
            return this.name.match(/^[^[]+/)[0];
        },
        hasError() {
            const hasError = !_.isBoolean(this.error)
                ? !_.isEmpty(this.error)
                : this.error;
            return !this.valid && hasError;
        }
    },
    methods: {
        updateValue(e) {
            this.$emit("input", e.target.value);
        }
    }
};
</script>
