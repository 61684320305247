<template>
    <div class="uk-width-2-3@m">
        <br class="uk-hidden@m" />
        <div id="reports-main" class="">
            <div class="uk-card uk-width-1-1">
                <div
                    class="uk-card-header uk-padding-small uk-background-primary uk-light"
                >
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-expand@m uk-width-1-1">
                            <h3
                                class="uk-margin-remove-bottom uk-text-left@m uk-text-center"
                            >
                                Your Reports
                            </h3>
                        </div>
                        <div class="uk-width-auto@m uk-width-1-1">
                            <div class="uk-margin" v-show="showActiveReports">
                                <select
                                    class="uk-select uk-text-small"
                                    @change="sortBy($event)"
                                    :sortByInput="sortByInput"
                                >
                                    <option>Sort by:</option>
                                    <option value="latsOpened"
                                        >Recently Viewed</option
                                    >
                                    <option value="createdDate"
                                        >Search Date</option
                                    >
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="isPageLoading"
                    :class="[
                        { 'uk-animation-fade': !isPageLoading },
                        'uk-animation-reverse',
                        'module-spinner',
                        'active-reports-spinner'
                    ]"
                >
                    <div class="uk-position-center" uk-spinner></div>
                </div>
                <div
                    v-else
                    :class="[
                        { 'uk-animation-fade': !isPageLoading },
                        'uk-card-body',
                        'uk-padding-small'
                    ]"
                >
                    <div
                        class="active-reports-cont uk-margin-small"
                        v-if="showActiveReports"
                    >
                        <!-- report card -->
                        <div
                            class="uk-card uk-padding-remove uk-text-center uk-text-left@m"
                            v-for="activeReport in activeReports"
                            :key="activeReport.id"
                        >
                            <div
                                class="uk-card uk-card-default uk-margin-small"
                            >
                                <div class="uk-card-header uk-padding-small">
                                    <div class="uk-grid uk-grid-collapse">
                                        <div
                                            class="uk-width-3-4@m uk-width-1-1"
                                        >
                                            <h3 class="uk-margin-remove-bottom">
                                                {{ activeReport.fullName }}
                                                <span class="uk-text-meta">{{
                                                    activeReport.city +
                                                        ", " +
                                                        activeReport.state
                                                }}</span>
                                            </h3>
                                            <p
                                                class="uk-text-meta uk-margin-remove"
                                            >
                                                Search date:
                                                {{ activeReport.createdDate
                                                }}<br />
                                                <span class="uk-text-meta"
                                                    >Last viewed:
                                                    {{
                                                        activeReport.latsOpened
                                                    }}</span
                                                >
                                            </p>
                                        </div>
                                        <div
                                            class="uk-width-1-4@m uk-width-1-1 uk-flex uk-flex-middle uk-margin-small"
                                        >
                                            <span class="uk-width-1-1">
                                                <router-link
                                                    :to="{
                                                        name: 'report',
                                                        params: {
                                                            id: activeReport.id,
                                                            type: 'person'
                                                        }
                                                    }"
                                                    class="uk-button uk-button-primary uk-width-1-1 uk-margin-remove"
                                                    >View Report</router-link
                                                >
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    :id="'report-' + activeReport.id"
                                    class="uk-card-body uk-padding-small"
                                >
                                    <div class="uk-grid-collapse" uk-grid>
                                        <button
                                            class="uk-button uk-button-small uk-button-default uk-width-auto@m uk-width-1-3@s uk-width-1-1"
                                            @click.prevent="
                                                confirmDelete(activeReport.id)
                                            "
                                        >
                                            <i class="fas fa-trash-alt"></i>
                                            <span>Delete</span>
                                        </button>
                                        <a
                                            :href="activeReport.downloadLink"
                                            target="_blank"
                                            class="uk-button uk-button-small uk-button-default uk-width-auto@m uk-width-1-3@s uk-width-1-1"
                                        >
                                            <i class="fas fa-download"></i>
                                            Download
                                        </a>
                                        <router-link
                                            to="search"
                                            class="uk-button uk-button-small uk-button-default uk-width-auto@m uk-width-1-3@s uk-width-1-1"
                                        >
                                            <i class="fas fa-search"></i> Search
                                            again
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- // end report card -->
                    </div>
                    <div class="uk-margin-small" v-else>
                        <div
                            class="uk-card uk-padding-remove uk-text-center uk-text-left@m"
                        >
                            <div
                                class="uk-card uk-card-default uk-margin-small"
                            >
                                <div class="uk-card-body uk-padding-small">
                                    No Records Found
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UIkit from "uikit";
import _ from "lodash/core";
export default {
    props: ["activeReports", "isPageLoading"],
    name: "ActiveReports",
    data() {
        return {
            sortByInput: ""
        };
    },
    computed: {
        showActiveReports() {
            return !_.isEmpty(this.activeReports);
        }
    },
    methods: {
        confirmDelete(reportId) {
            let self = this;
            UIkit.modal
                .confirm("Are you sure you want to delete this record?", {
                    labels: { ok: "Yes", cancel: "No" }
                })
                .then(function() {
                    self.deleteReport(reportId);
                });
        },
        deleteReport(reportId) {
            this.$emit("delete-report", reportId);
        },
        sortBy(event) {
            this.sortByInput = event.target.value;
            if (this.sortByInput === "latsOpened")
                this.activeReports.sort((a, b) =>
                    a.LatsOpened < b.LatsOpened ? 1 : -1
                );
            else if (this.sortByInput === "createdDate")
                this.activeReports.sort((a, b) =>
                    a.createdDate < b.createdDate ? 1 : -1
                );
        }
    }
};
</script>
