// config init data object mutators
export const SAVE_SITE_CONFIG_DATA = "SAVE_SITE_CONFIG_DATA";
export const SAVE_SEARCH_CONFIG_DATA = "SAVE_SEARCH_CONFIG_DATA";

// page.js
export const UPDATE_LOADING_STATUS = "UPDATE_LOADING_STATUS";
export const UPDATE_PAGE_ERROR = "UPDATE_PAGE_ERROR";
export const UPDATE_COMPONENT_NAME = "UPDATE_COMPONENT_NAME";

// auth.js
export const LOGOUT = "LOGOUT";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const UPDATE_USER = "UPDATE_USER";

// search.js
export const SAVE_SEARCH_FUNNEL = "SAVE_SEARCH_FUNNEL";
export const SAVE_CURRENT_SEARCH_DATA = "SAVE_CURRENT_SEARCH_DATA";
