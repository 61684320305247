<template>
    <div>
        <div>
            <div
                class="titleCont uk-section-muted uk-dark uk-animation-fade uk-padding-large"
            >
                <div class="uk-container uk-container-center uk-text-center">
                    <h1 class="uk-heading-primary">
                        Report on {{ getSearchParamString }} Complete
                    </h1>
                </div>
            </div>
            <div
                v-if="isPageLoading"
                :class="[
                    { 'uk-animation-fade': !isPageLoading },
                    'uk-animation-reverse',
                    'module-spinner'
                ]"
            >
                <div class="uk-position-center" uk-spinner="ratio: 3"></div>
            </div>
            <div
                v-else
                :class="[
                    { 'uk-animation-fade': !isPageLoading },
                    'uk-card-body',
                    'uk-padding-small'
                ]"
            >
                <div
                    class="offerCont uk-section-primary uk-padding uk-animation-slide-bottom"
                >
                    <div
                        class="uk-container uk-container-center uk-text-center"
                    >
                        <h2>{{ getSelectedPackageTitle }} OFFER</h2>
                        <h3>
                            Unlimited Reports -
                            <b>Only {{ getSelectedPackagePrice }}</b>
                        </h3>
                    </div>
                </div>
                <div class="uk-section-muted uk-padding-small">
                    <div
                        class="uk-container uk-container-center uk-text-center"
                    >
                        <h3>
                            <span uk-icon="check"></span>
                            ACT NOW AND UNLOCK YOUR SPECIAL OFFER TODAY
                        </h3>
                    </div>
                </div>
                <div class="uk-section-default">
                    <div class="uk-container">
                        <div
                            class="uk-grid-collapse uk-child-width-expand@s uk-text-center"
                            uk-grid
                        >
                            <div class="uk-width-2-5@m uk-width-1-1">
                                <div
                                    class="uk-background-default uk-text-left@m uk-text-center uk-padding-remove-top "
                                >
                                    <div
                                        class="infoCont uk-text-large uk-padding uk-padding-remove-left"
                                    >
                                        <div>
                                            <h2>Report may contain:</h2>
                                        </div>
                                        <ul
                                            class="uk-flex uk-flex-wrap uk-list uk-animation-slide-left"
                                        >
                                            <li class="uk-width-1-1"></li>
                                            <li class="uk-width-1-2@s success">
                                                <span uk-icon="warning"></span>
                                                <span>Background info</span>
                                            </li>
                                            <li class="uk-width-1-2@s success">
                                                <span uk-icon="check"></span>
                                                <span
                                                    >Social Media Profiles</span
                                                >
                                            </li>
                                            <li class="uk-width-1-2@s success">
                                                <span uk-icon="check"></span>
                                                <span>Court Records</span>
                                            </li>
                                            <li class="uk-width-1-2@s success">
                                                <span uk-icon="check"></span>
                                                <span>Relationships</span>
                                            </li>
                                            <li class="uk-width-1-2@s success">
                                                <span uk-icon="check"></span>
                                                <span>Financials</span>
                                            </li>
                                            <li class="uk-width-1-2@s success">
                                                <span uk-icon="warning"></span>
                                                <span>Arrests</span>
                                            </li>
                                            <li class="uk-width-1-2@s success">
                                                <span uk-icon="check"></span>
                                                <span>Previous Addresses</span>
                                            </li>
                                            <li class="uk-width-1-2@s success">
                                                <span uk-icon="warning"></span>
                                                <span>Phone Records</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        class="uk-background-muted uk-padding-small faq"
                                    >
                                        <span class="uk-text-primary"
                                            >FREQUENTLY ASKED QUESTIONS</span
                                        >
                                        <hr />
                                        <p class="uk-text-small">
                                            *WHAT ARE THE BILLING TERMS?<br /><br />
                                            Your $1.99 trial lasts for 3 (three)
                                            days. By clicking "Start My Trial",
                                            you are providing your electronic
                                            signature authorizing Unfaithful to
                                            charge your card as described. You
                                            also agree that unless you cancel
                                            your account during the 3-day trial
                                            period you will be billed $29.95
                                            every 30 days until you cancel your
                                            account, which can be done any time,
                                            hassle-free from your account page
                                            or by contacting our US-based member
                                            care center at 1-800-555-4321.
                                        </p>
                                        <hr />
                                        <p>
                                            PUBLIC RECORDS AREN'T FREE?<br /><br />
                                            Unfortunately, we have to pay for
                                            the information we provide. Free
                                            reports would put us out of
                                            business.
                                        </p>
                                        <hr />
                                        <p>
                                            IS MY ACCOUNT PRIVATE?<br /><br />
                                            Yes. TruthFinder accounts and your
                                            searches are completely private.
                                            <b
                                                >No one will ever know you did a
                                                search on them.</b
                                            >
                                        </p>
                                        <hr />
                                        <p>
                                            HOW DO I GET MY REPORT?<br /><br />
                                            Just sign up for Unfaithful.com and
                                            instantly see your report. Find out
                                            the truth now.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <form
                                    class="uk-background-default uk-padding uk-dark uk-text-left"
                                    @submit.prevent="validateBeforeSubmit"
                                >
                                    <div
                                        class="uk-card buyCard uk-animation-slide-bottom"
                                    >
                                        <div
                                            class="uk-card-header uk-background-primary uk-light uk-padding-small"
                                        >
                                            <h3 class="uk-card-title">
                                                OFFER DETAILS
                                            </h3>
                                        </div>
                                        <div
                                            v-show="
                                                errors.has('selected_package')
                                            "
                                        >
                                            <p>
                                                {{
                                                    errors.first(
                                                        "selected_package"
                                                    )
                                                }}
                                            </p>
                                        </div>
                                        <div
                                            v-for="(item, index) in packages"
                                            :key="'package-' + index"
                                            class="uk-card-body uk-background-muted uk-padding-small"
                                        >
                                            <div
                                                v-if="index === 0"
                                                class="uk-tile uk-tile-primary uk-padding-remove deal"
                                            >
                                                OUTSTANDING DEAL
                                            </div>
                                            <label
                                                :class="[
                                                    {
                                                        dealPackage: index === 0
                                                    },
                                                    'uk-flex',
                                                    'uk-flex-center'
                                                ]"
                                            >
                                                <span
                                                    class="uk-width-auto@m uk-width-1-6 uk-text-center"
                                                >
                                                    <br />
                                                    <input
                                                        v-model="
                                                            formData.selected_package
                                                        "
                                                        name="selected_package"
                                                        class="uk-checkbox"
                                                        :value="item.id"
                                                        v-validate="'required'"
                                                        type="radio"
                                                    />
                                                </span>
                                                <span
                                                    class="uk-width-expand@m uk-width-2-3 uk-text-left uk-padding-small"
                                                >
                                                    <span
                                                        class="packageTitle uk-margin-remove"
                                                    >
                                                        {{ item.title }}
                                                    </span>
                                                    <span
                                                        class="packageDesc uk-text-muted"
                                                    >
                                                        {{ item.description }}
                                                    </span>
                                                </span>
                                                <span
                                                    class="uk-width-auto@m uk-width-1-3 uk-text-right uk-float-right uk-padding-small"
                                                >
                                                    <span
                                                        v-if="
                                                            isPackageTrial(item)
                                                        "
                                                        class="packagePrice"
                                                    >
                                                        <sup>$</sup>
                                                        {{ item.trial_price }}
                                                        <sup>*</sup>
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="packagePrice"
                                                    >
                                                        <sup>$</sup>
                                                        {{ item.price }}
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                        <div class="uk-card-footer"></div>
                                    </div>
                                    <div
                                        class="uk-card buyCard uk-animation-slide-bottom"
                                    >
                                        <div
                                            class="uk-card-header uk-background-primary uk-light uk-padding-small"
                                        >
                                            <h3 class="uk-card-title">
                                                STEP 2 - ACTIVATE YOUR ACCOUNT
                                            </h3>
                                        </div>
                                        <div
                                            class="uk-card-body uk-background-default uk-padding-small security"
                                        >
                                            <div class="uk-flex uk-flex-center">
                                                <div
                                                    class="uk-width-auto@m uk-width-1-6"
                                                >
                                                    <br />
                                                    <span
                                                        uk-icon="icon: lock; ratio: 2"
                                                    ></span>
                                                </div>
                                                <div
                                                    class="uk-width-expand uk-text-left uk-padding-small"
                                                >
                                                    <span
                                                        class="uk-margin-remove"
                                                        >SECURITY IS OUR TOP
                                                        PRIORITY</span
                                                    >
                                                    <p
                                                        class="uk-text-muted uk-margin-remove"
                                                    >
                                                        Unfaithful utilizes
                                                        advanced technologies to
                                                        protect your information
                                                        and ensure your
                                                        transaction is secure.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="uk-card-body uk-background-muted uk-padding-small"
                                        >
                                            <div>
                                                <ul
                                                    class="uk-list uk-grid-small"
                                                    uk-grid
                                                >
                                                    <li
                                                        class="uk-width-1-1"
                                                    ></li>
                                                    <li
                                                        class="uk-width-1-1 uk-width-1-2@s"
                                                    >
                                                        <VInputWithValidation
                                                            name="first_name"
                                                            placeholder="First Name"
                                                            autocomplete="off"
                                                            :error="
                                                                errors.first(
                                                                    'first_name'
                                                                )
                                                            "
                                                            :valid="
                                                                first_name.valid
                                                            "
                                                            v-model="
                                                                formData.first_name
                                                            "
                                                            v-validate="
                                                                'required'
                                                            "
                                                        ></VInputWithValidation>
                                                    </li>
                                                    <li
                                                        class="uk-width-1-1 uk-width-1-2@s"
                                                    >
                                                        <VInputWithValidation
                                                            name="last_name"
                                                            placeholder="Last Name"
                                                            autocomplete="off"
                                                            :error="
                                                                errors.first(
                                                                    'last_name'
                                                                )
                                                            "
                                                            :valid="
                                                                last_name.valid
                                                            "
                                                            v-model="
                                                                formData.last_name
                                                            "
                                                            v-validate="
                                                                'required'
                                                            "
                                                        ></VInputWithValidation>
                                                    </li>
                                                    <li class="uk-width-1-1">
                                                        <VInputWithValidation
                                                            name="address"
                                                            placeholder="Billing Address"
                                                            autocomplete="off"
                                                            :error="
                                                                errors.first(
                                                                    'address'
                                                                )
                                                            "
                                                            :valid="
                                                                address.valid
                                                            "
                                                            v-model="
                                                                formData.address
                                                            "
                                                            v-validate="
                                                                'required'
                                                            "
                                                        ></VInputWithValidation>
                                                    </li>
                                                    <li
                                                        class="uk-width-1-1 uk-width-1-2@s"
                                                    >
                                                        <VSelectWithValidation
                                                            name="state"
                                                            :error="
                                                                errors.first(
                                                                    'state'
                                                                )
                                                            "
                                                            :valid="state.valid"
                                                            v-model="
                                                                formData.state
                                                            "
                                                            v-validate="
                                                                'required'
                                                            "
                                                        >
                                                            <option
                                                                selected
                                                                disabled
                                                                value=""
                                                            >
                                                                State
                                                            </option>
                                                            <option
                                                                v-for="(item,
                                                                idx) in statesList"
                                                                :model="
                                                                    item.alpha_2
                                                                "
                                                                :key="idx"
                                                            >
                                                                {{ item.name }}
                                                            </option>
                                                        </VSelectWithValidation>
                                                    </li>
                                                    <li
                                                        class="uk-width-1-1 uk-width-1-2@s"
                                                    >
                                                        <VInputWithValidation
                                                            name="postal_code"
                                                            placeholder="Zip"
                                                            autocomplete="off"
                                                            :error="
                                                                errors.first(
                                                                    'postal_code'
                                                                )
                                                            "
                                                            :valid="
                                                                postal_code.valid
                                                            "
                                                            v-model="
                                                                formData.postal_code
                                                            "
                                                            v-validate="
                                                                'required'
                                                            "
                                                        ></VInputWithValidation>
                                                    </li>
                                                    <li
                                                        class="uk-width-4-5@s uk-width-1-1"
                                                    >
                                                        <VInputWithValidation
                                                            name="cc_number"
                                                            placeholder="Credit Card Number"
                                                            autocomplete="off"
                                                            :error="
                                                                errors.first(
                                                                    'cc_number'
                                                                )
                                                            "
                                                            :valid="
                                                                cc_number.valid
                                                            "
                                                            v-model="
                                                                formData.cc_number
                                                            "
                                                            v-validate="
                                                                'required'
                                                            "
                                                        ></VInputWithValidation>
                                                    </li>
                                                    <li
                                                        class="uk-width-1-5@s uk-width-1-1 uk-text-center"
                                                    >
                                                        <img
                                                            src="/images/cc.png"
                                                        />
                                                    </li>
                                                    <li
                                                        class="uk-width-1-1 uk-width-2-5@m"
                                                    >
                                                        <VSelectWithValidation
                                                            name="cc_month"
                                                            :error="
                                                                errors.first(
                                                                    'cc_month'
                                                                )
                                                            "
                                                            :valid="
                                                                cc_month.valid
                                                            "
                                                            v-model="
                                                                formData.cc_month
                                                            "
                                                            v-validate="
                                                                'required'
                                                            "
                                                        >
                                                            <option
                                                                selected
                                                                disabled
                                                                value=""
                                                            >
                                                                Expiration Month
                                                            </option>
                                                            <option value="1"
                                                                >January</option
                                                            >
                                                            <option value="2"
                                                                >Feburary</option
                                                            >
                                                            <option value="3"
                                                                >March</option
                                                            >
                                                            <option value="4"
                                                                >April</option
                                                            >
                                                            <option value="5"
                                                                >May</option
                                                            >
                                                            <option value="6"
                                                                >June</option
                                                            >
                                                            <option value="7"
                                                                >July</option
                                                            >
                                                            <option value="8"
                                                                >August</option
                                                            >
                                                            <option value="9"
                                                                >September</option
                                                            >
                                                            <option value="10"
                                                                >October</option
                                                            >
                                                            <option value="11"
                                                                >November</option
                                                            >
                                                            <option value="12"
                                                                >December</option
                                                            >
                                                        </VSelectWithValidation>
                                                    </li>
                                                    <li
                                                        class="uk-width-1-1 uk-width-2-5@m"
                                                    >
                                                        <VSelectWithValidation
                                                            name="cc_year"
                                                            :error="
                                                                errors.first(
                                                                    'cc_year'
                                                                )
                                                            "
                                                            :valid="
                                                                cc_year.valid
                                                            "
                                                            v-model="
                                                                formData.cc_year
                                                            "
                                                            v-validate="
                                                                'required'
                                                            "
                                                        >
                                                            <option
                                                                selected
                                                                disabled
                                                                value=""
                                                            >
                                                                Expiration Year
                                                            </option>
                                                            <option
                                                                v-for="(item,
                                                                idx) in getYearList"
                                                                :model="item"
                                                                :key="idx"
                                                            >
                                                                {{ item }}
                                                            </option>
                                                        </VSelectWithValidation>
                                                    </li>
                                                    <li
                                                        class="uk-width-1-1 uk-width-1-5@m"
                                                    >
                                                        <VInputWithValidation
                                                            name="cvv2"
                                                            placeholder="CVV2"
                                                            autocomplete="off"
                                                            :error="
                                                                errors.first(
                                                                    'cvv2'
                                                                )
                                                            "
                                                            :valid="cvv2.valid"
                                                            v-model="
                                                                formData.cvv2
                                                            "
                                                            v-validate="
                                                                'required'
                                                            "
                                                        ></VInputWithValidation>
                                                    </li>
                                                    <li
                                                        class="uk-width-1-1 uk-text-center uk-text-meta uk-padding-small"
                                                    >
                                                        The charge will appear
                                                        on your statement as
                                                        Unfaithful LLC.
                                                    </li>
                                                </ul>
                                            </div>
                                            <hr />
                                            <div
                                                class="uk-card uk-card-default uk-card-body uk-width-1-1@m uk-padding-small uk-text-left reportReady"
                                            >
                                                <span
                                                    uk-icon="icon: lock; ratio: 1.4"
                                                    class="uk-position-top-right uk-padding-small"
                                                ></span>
                                                <h4
                                                    class="uk-margin-remove uk-width-2-3"
                                                >
                                                    Your Report is Ready...
                                                </h4>
                                                <hr />
                                                <div
                                                    class="uk-flex uk-flex-center"
                                                >
                                                    <div
                                                        class="uk-padding-small"
                                                    >
                                                        <img
                                                            class="uk-border-circle uk-background-default"
                                                            src="/images/avatar.png"
                                                            width="80"
                                                            height="80"
                                                        />
                                                    </div>
                                                    <div
                                                        class="uk-padding-small"
                                                    >
                                                        <h4
                                                            class="uk-card-title"
                                                        >
                                                            {{
                                                                getSearchParamString
                                                            }}
                                                        </h4>
                                                        <h5
                                                            class="uk-text-muted uk-margin-remove"
                                                        >
                                                            35 yo / Stockton, CA
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="uk-card-footer uk-background-muted uk-padding-small"
                                        >
                                            <hr />
                                            <div>
                                                <div
                                                    v-show="
                                                        errors.has(
                                                            'accept_terms'
                                                        )
                                                    "
                                                >
                                                    <p>
                                                        {{
                                                            errors.first(
                                                                "accept_terms"
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                                <div
                                                    class="uk-text-meta uk-text-center uk-margin"
                                                >
                                                    <label
                                                        :class="{
                                                            'uk-form-danger': errors.has(
                                                                'accept_terms'
                                                            )
                                                        }"
                                                    >
                                                        <input
                                                            :error="
                                                                errors.first(
                                                                    'accept_terms'
                                                                )
                                                            "
                                                            :valid="
                                                                accept_terms.valid
                                                            "
                                                            v-model="
                                                                formData.accept_terms
                                                            "
                                                            name="accept_terms"
                                                            v-validate="
                                                                'required'
                                                            "
                                                            class="uk-checkbox"
                                                            type="checkbox"
                                                        />
                                                        By clicking the button
                                                        below, I confirm that I
                                                        am over 18 years of age
                                                        and agree to the Terms
                                                        of Service and Privacy
                                                        Policy, and I agree to
                                                        receive email from
                                                        {{ getSiteName }}. I
                                                        agree to be billed
                                                        {{
                                                            getSelectedPackageBillingDesc
                                                        }}
                                                        until this subscription
                                                        is canceled.
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom uk-padding-small"
                                            >
                                                <button
                                                    type="submit"
                                                    class="uk-button uk-text-large uk-button-primary uk-width-1-1 uk-padding-small"
                                                >
                                                    START MY TRIAL
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import { PageHelpers } from "@/mixins/PageHelpers";
import { mapGetters } from "vuex";
import { mapFields } from "vee-validate";
import { States } from "@/mixins/States";
import VInputWithValidation from "../components/VInputWithValidation";
import VSelectWithValidation from "../components/VSelectWithValidation";

export default {
    name: "Buy",
    metaInfo: {
        title: "Purchase Report"
    },
    mixins: [States, PageHelpers],
    components: {
        VInputWithValidation,
        VSelectWithValidation
    },
    data: () => ({
        isPageLoading: true,
        search: {
            type: "",
            params: {}
        },
        packages: [],
        formData: {
            selected_package: null,
            first_name: "",
            last_name: "",
            address: "",
            state: "",
            postal_code: null,
            cc_number: null,
            cc_month: null,
            cc_year: null,
            cvv2: null,
            accept_terms: false
        }
    }),
    computed: {
        ...mapFields([
            "selected_package",
            "first_name",
            "last_name",
            "address",
            "state",
            "postal_code",
            "cc_number",
            "cc_month",
            "cc_year",
            "cvv2",
            "accept_terms"
        ]),
        ...mapGetters("search", {
            getSearchType: "searchType",
            getSearchParams: "searchParams"
        }),
        ...mapGetters("site", { getSiteName: "name" }),
        getSearchParamString() {
            if (this.search.type === "person") {
                return (
                    this.search.params.first_name +
                    " " +
                    this.search.params.last_name
                );
            } else if (this.search.type === "address") {
                return this.getLocationString(this.search.params);
            } else if (this.search.type === "phone") {
                return this.search.params.phone_number;
            }
            return "";
        },
        getYearList() {
            const currentYear = new Date().getFullYear();
            let months = [];

            for (let i = 0; i < 11; i++) {
                months.push(currentYear + i);
            }

            return months;
        },
        getSelectedPackageTitle() {
            let title;
            this.packages.forEach(item => {
                if (item.id === this.formData.selected_package) {
                    title = item.title;
                }
            });
            return title;
        },
        getSelectedPackagePrice() {
            let price;
            this.packages.forEach(item => {
                if (item.id === this.formData.selected_package) {
                    price = !_.isEmpty(item.trial_price)
                        ? item.trial_price + "*"
                        : item.price;
                }
            });
            return price;
        },
        getSelectedPackageBillingDesc() {
            let desc;
            this.packages.forEach(item => {
                if (item.id === this.formData.selected_package) {
                    if (this.isPackageTrial(item)) {
                        desc =
                            "$" +
                            item.trial_price +
                            " for a " +
                            item.trial_duration +
                            " day trial and $" +
                            item.price +
                            " every month";
                    } else {
                        desc = "$" + item.price + " every month";
                    }
                }
            });
            return desc;
        }
    },
    created() {
        this.$store.dispatch("page/updateLoadingStatus", true);
        this.search = {
            type: this.getSearchType,
            params: this.getSearchParams
        };

        axios
            .get("/api/packages")
            .then(result => {
                if (_.isEmpty(result.data)) {
                    this.$store.dispatch("page/updateLoadingStatus", false);
                    this.$store.dispatch(
                        "page/updatePageError",
                        "Page failed to load. Please refresh and try again"
                    );
                } else {
                    result.data.forEach(item => {
                        this.packages.push(item);
                    });
                    this.formData.selected_package = this.packages[0].id;
                }
                this.isPageLoading = false;
                this.$store.dispatch("page/updateLoadingStatus", false);
            })
            .catch(() => {
                this.isPageLoading = false;
                this.$store.dispatch("page/updateLoadingStatus", false);
                this.$store.dispatch(
                    "page/updatePageError",
                    "Page failed to load. Please refresh and try again"
                );
            });
    },
    mounted() {
        if (_.isEmpty(this.search.type) || _.isEmpty(this.search.params)) {
            // Redirect home.
            this.$router.push({ name: "home" });
        }
    },
    methods: {
        validateBeforeSubmit() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.submit();
                }
            });
        },
        submit() {
            axios
                .post(
                    `/api/package/${this.formData.selected_package}/purchase`,
                    this.formData
                )
                .then(() => {})
                .catch(error => {
                    const errors = error.response.data.errors;
                    if (
                        errors !== undefined &&
                        Object.keys(errors).length > 0
                    ) {
                        const fields = Object.keys(errors);

                        fields.forEach(name => {
                            //Add error to error bag
                            this.errors.add({
                                field: name,
                                msg: errors[name][0]
                            });
                            //Update the field's flag status'
                            this.$validator.flag(name, {
                                valid: false,
                                dirty: true
                            });
                        });
                    } else {
                        this.showNotification(
                            "danger",
                            "An error has occurred!"
                        );
                    }
                });
        },
        getLocationString(locationObj) {
            let locationArr = [];

            if (!_.isEmpty(locationObj.street_address_1)) {
                locationArr.push(locationObj.street_address_1);
            }

            if (!_.isEmpty(locationObj.street_address_2)) {
                locationArr.push(locationObj.street_address_2);
            }

            if (!_.isEmpty(locationObj.city)) {
                locationArr.push(locationObj.city);
            }

            if (!_.isEmpty(locationObj.state)) {
                locationArr.push(locationObj.state);
            }

            if (!_.isEmpty(locationObj.postal_code.toString())) {
                locationArr.push(locationObj.postal_code.toString());
            }

            return locationArr.join(" ");
        },
        isPackageTrial(packageObj) {
            return !_.isEmpty(packageObj.trial_price);
        }
    }
};
</script>
