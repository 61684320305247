<template>
    <div>
        <!--TOP SECTION-->
        <search></search>
        <!-- End Top Section -->
        <!-- HOME SECTION -->
        <div class="uk-section-muted uk-padding-large">
            <div class="uk-container">
                <div class="uk-grid uk-grid-small">
                    <!-- account status -->
                    <div class="uk-width-1-3@m">
                        <div
                            uk-sticky="media: 960; offset: 150"
                            class="uk-card uk-card-default uk-padding-remove uk-text-small"
                        >
                            <div
                                class="uk-card-header uk-background-primary uk-light uk-padding-small uk-text-left@m uk-text-center"
                            >
                                <h3>Account Status</h3>
                            </div>
                            <div
                                v-if="isPageLoading"
                                :class="[
                                    { 'uk-animation-fade': !isPageLoading },
                                    'uk-animation-reverse',
                                    'module-spinner'
                                ]"
                            >
                                <div
                                    class="uk-position-center"
                                    uk-spinner
                                ></div>
                            </div>
                            <div
                                v-else
                                :class="[
                                    { 'uk-animation-fade': !isPageLoading },
                                    'uk-card-body',
                                    'uk-padding-small'
                                ]"
                            >
                                <ul
                                    class="uk-list uk-grid-collapse uk-padding-small uk-padding-remove-top"
                                    uk-grid
                                >
                                    <li class="uk-width-1-2@m uk-width-1-2">
                                        <h4 class="uk-margin-remove-top">
                                            {{ user.name }}
                                        </h4>
                                    </li>
                                    <li
                                        class="uk-width-1-2@m uk-width-1-2 uk-margin-remove-top"
                                    >
                                        <router-link
                                            to="/settings"
                                            class="uk-button uk-button-default uk-button-small uk-width-1-1"
                                            >My Account</router-link
                                        >
                                    </li>
                                    <li class="uk-width-1-1">
                                        <p>{{ user.email }}</p>
                                    </li>
                                    <li class="uk-width-1-1 small">
                                        <hr />
                                    </li>
                                    <template
                                        v-for="(service, idx) in services"
                                    >
                                        <li
                                            class="uk-width-3-5"
                                            :key="'service-name-' + idx"
                                        >
                                            {{ service.serviceName }}
                                        </li>
                                        <li
                                            class="uk-width-2-5 uk-text-center"
                                            v-if="service.available"
                                            :key="'service-status-' + idx"
                                        >
                                            <i class="fas fa-check"></i>
                                            Available
                                        </li>
                                        <li
                                            class="uk-width-2-5"
                                            v-else
                                            :key="'service-unlock' + idx"
                                        >
                                            <button
                                                class="uk-button uk-button-primary uk-button-small uk-width-1-1"
                                                @click="
                                                    unlockService(service.id)
                                                "
                                            >
                                                UNLOCK
                                            </button>
                                        </li>
                                        <li
                                            class="uk-width-1-1 small uk-hidden@m"
                                            :key="'service-spacer-' + idx"
                                        >
                                            <hr />
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- Your Reports -->
                    <active-reports
                        v-on:delete-report="deleteReport"
                        :isPageLoading="isPageLoading"
                        :activeReports="activeReports"
                    ></active-reports>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { PageHelpers } from "@/mixins/PageHelpers";
import Search from "@/components/Search";
import ActiveReports from "@/components/dashboard/ActiveReports";

export default {
    middleware: "auth",
    name: "Dashboard",
    mixins: [PageHelpers],
    components: {
        Search,
        ActiveReports
    },
    metaInfo: {
        title: "Welcome"
    },
    data() {
        return {
            isPageLoading: true,
            pageLoadError: "",
            user: [],
            activeReports: [],
            services: []
        };
    },
    created() {
        this.user = this.$store.getters["auth/user"];
        axios
            .get("api/dashboard")
            .then(res => {
                this.activeReports = res.data.reports;
                this.services = res.data.services;
                this.isPageLoading = false;
                this.$store.dispatch("page/updateLoadingStatus", false);
            })
            .catch(err => {
                this.isPageLoading = false;
                this.$store.dispatch("page/updateLoadingStatus", false);
                this.$store.dispatch(
                    "page/updatePageError",
                    `Page failed to load with ${err}. Please refresh and try again`
                );
            });
    },
    methods: {
        unlockService() {
            //service unlock request
        },
        deleteReport(reportId) {
            this.isPageLoading = true;
            this.$store.dispatch("page/updateLoadingStatus", true);
            axios
                .delete("/api/dashboard/reports/" + reportId)
                .then(res => {
                    if (res.data.status === "success") {
                        this.activeReports = res.data.reports;
                    }
                    this.isPageLoading = false;
                    this.$store.dispatch("page/updateLoadingStatus", false);
                    this.showNotification(
                        res.data.status === "success" ? "success" : "danger",
                        res.data.status === "success"
                            ? res.data.message
                            : "An error has occurred!"
                    );
                })
                .catch(() => {
                    this.isPageLoading = false;
                    this.$store.dispatch("page/updateLoadingStatus", false);
                    this.showNotification("danger", "An error has occurred!");
                });
        }
    }
};
</script>
