<template>
    <div v-if="isShowing" class="pageLoader uk-position-top uk-position-fixed">
        <progress
            class="uk-margin-remove uk-progress"
            :value="percentage"
            max="100"
        ></progress>
        <div class="uk-position-right" uk-spinner></div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "PageLoader",
    data: () => ({
        isShowing: false,
        duration: 3000,
        tickTime: 110,
        intervalFunc: null,
        percentage: 0,
        percentageMax: 90
    }),
    computed: {
        ...mapGetters("page", {
            isPageLoading: "loading"
        })
    },
    watch: {
        isPageLoading(loading) {
            return loading ? this.start() : this.finish();
        }
    },
    methods: {
        start() {
            this.isShowing = true;

            if (this.intervalFunc) {
                clearInterval(this.intervalFunc);
                this.percentage = 0;
            }

            this.intervalFunc = setInterval(() => {
                this.percentage +=
                    (10000 / Math.floor(this.duration)) * Math.random();

                if (this.percentage >= this.percentageMax) {
                    clearInterval(this.intervalFunc);
                    this.intervalFunc = null;
                }
            }, this.tickTime);
        },
        finish() {
            this.percentage = 100;
            this.hide();
        },
        hide() {
            clearInterval(this.intervalFunc);
            this.intervalFunc = null;
            setTimeout(() => {
                this.isShowing = false;
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.percentage = 0;
                    }, 200);
                });
            }, 500);
        }
    }
};
</script>
