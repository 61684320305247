<template>
    <div>
        <div
            id="search"
            class="uk-section uk-section-primary uk-padding uk-padding-remove-bottom"
        >
            <div class="uk-container">
                <div class="uk-container">
                    <div class="uk-text-center uk-padding-small">
                        <span class="subTitle"
                            >Uncover information about anyone by searching for
                            their name, address, email or phone number.</span
                        >
                    </div>
                </div>
                <div
                    class="uk-container uk-padding-small uk-padding-remove-bottom tabs"
                    uk-scrollspy="cls:uk-animation-fade"
                >
                    <ul
                        class="uk-subnav uk-subnav-pill uk-flex uk-flex-center"
                        uk-switcher="animation: uk-animation-fade; connect: .searchSwitch"
                    >
                        <li>
                            <a href="#">Name</a
                            ><span
                                class="triangle"
                                uk-icon="icon:triangle-down; ratio:2;"
                            ></span>
                        </li>
                        <li>
                            <a href="#">Address</a
                            ><span
                                class="triangle"
                                uk-icon="icon:triangle-down; ratio:2;"
                            ></span>
                        </li>
                        <li>
                            <a href="#">Phone</a
                            ><span
                                class="triangle"
                                uk-icon="icon:triangle-down; ratio:2;"
                            ></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div
            class="uk-section initialLander uk-background-cover uk-background-top-center uk-padding-small uk-padding-remove-bottom"
        >
            <div
                class="uk-container uk-text-center uk-padding-large"
                uk-scrollspy="cls:uk-animation-scale-up"
            >
                <h1>Public Records Search</h1>
            </div>

            <div
                class="uk-section uk-padding-large uk-padding-remove-top"
                uk-scrollspy="cls:uk-animation-fade"
            >
                <div
                    class="searchForm uk-container uk-padding-small uk-section-muted uk-text-left uk-border-rounded"
                >
                    <ul class="uk-switcher uk-margin searchSwitch">
                        <li>
                            <form
                                class="uk-grid-small"
                                method="post"
                                @submit.prevent="validateForm('searchPerson')"
                                data-vv-scope="searchPerson"
                                uk-grid
                            >
                                <div class="uk-width-1-4@m">
                                    <label>First Name</label>
                                    <VInputWithValidation
                                        v-model="formData.person.first_name"
                                        v-validate="'required'"
                                        name="first_name"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="FIRST NAME"
                                        autocomplete="off"
                                        :error="
                                            errors.first(
                                                'searchPerson.first_name'
                                            )
                                        "
                                        :valid="first_name.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-1-4@m">
                                    <label>Last Name</label>
                                    <VInputWithValidation
                                        v-model="formData.person.last_name"
                                        v-validate="'required'"
                                        name="last_name"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="LAST NAME"
                                        autocomplete="off"
                                        :error="
                                            errors.first(
                                                'searchPerson.last_name'
                                            )
                                        "
                                        :valid="last_name.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-expand@m">
                                    <label>State</label>
                                    <VSelectWithValidation
                                        v-model="formData.person.state"
                                        v-validate="'required'"
                                        name="person_state"
                                        class="uk-border-rounded"
                                        :error="
                                            errors.first('searchPerson.state')
                                        "
                                        :valid="person_state.valid"
                                    >
                                        <option selected disabled value=""
                                            >State</option
                                        >
                                        <option
                                            :model="state.alpha_2"
                                            v-for="(state, idx) in statesList"
                                            :key="idx"
                                            >{{ state.name }}</option
                                        >
                                    </VSelectWithValidation>
                                </div>
                                <div class="uk-width-expand@m">
                                    <label>Age</label>
                                    <VInputWithValidation
                                        v-model="formData.person.age"
                                        v-validate="'required'"
                                        name="age"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="AGE"
                                        autocomplete="off"
                                        :error="
                                            errors.first('searchPerson.age')
                                        "
                                        :valid="age.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-1-6@m">
                                    <div class="uk-visible@m">&nbsp;</div>
                                    <button
                                        class="uk-button uk-button-default uk-width-1-1 uk-text-center uk-border-rounded search"
                                        type="submit"
                                    >
                                        SEARCH
                                    </button>
                                </div>
                            </form>
                        </li>
                        <li>
                            <form
                                class="uk-grid-small"
                                method="post"
                                @submit.prevent="validateForm('searchAddress')"
                                data-vv-scope="searchAddress"
                                uk-grid
                            >
                                <div class="uk-width-1-5@m">
                                    <label>Street Address</label>
                                    <VInputWithValidation
                                        v-model="
                                            formData.address.street_address_1
                                        "
                                        v-validate="'required'"
                                        name="street_address_1"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="123 Fake St"
                                        autocomplete="off"
                                        :error="
                                            errors.first(
                                                'searchAddress.street_address_1'
                                            )
                                        "
                                        :valid="street_address_1.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-1-5@m">
                                    <label>Building/Apt #</label>
                                    <VInputWithValidation
                                        v-model="
                                            formData.address.street_address_2
                                        "
                                        name="street_address_2"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="Apt 1"
                                        autocomplete="off"
                                        :error="
                                            errors.first(
                                                'searchAddress.street_address_2'
                                            )
                                        "
                                        :valid="street_address_2.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-1-5@m">
                                    <label>City</label>
                                    <VInputWithValidation
                                        v-model="formData.address.city"
                                        v-validate="'required'"
                                        name="city"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="Los Angeles"
                                        autocomplete="off"
                                        :error="
                                            errors.first('searchAddress.city')
                                        "
                                        :valid="city.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-expand@m">
                                    <label>State</label>
                                    <VSelectWithValidation
                                        v-model="formData.address.state"
                                        v-validate="'required'"
                                        name="address_state"
                                        class="uk-border-rounded"
                                        :error="
                                            errors.first('searchAddress.state')
                                        "
                                        :valid="address_state.valid"
                                    >
                                        <option selected disabled value=""
                                            >State</option
                                        >
                                        <option
                                            :model="state.alpha_2"
                                            v-for="(state, idx) in statesList"
                                            :key="idx"
                                            >{{ state.name }}</option
                                        >
                                    </VSelectWithValidation>
                                </div>
                                <div class="uk-width-expand@m">
                                    <label>Postal Code</label>
                                    <VInputWithValidation
                                        v-model="formData.address.postal_code"
                                        v-validate="'required'"
                                        name="postal_code"
                                        type="text"
                                        class="uk-border-rounded"
                                        placeholder="12345"
                                        autocomplete="off"
                                        :error="
                                            errors.first(
                                                'searchAddress.postal_code'
                                            )
                                        "
                                        :valid="postal_code.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-1-6@m">
                                    <div class="uk-visible@m">&nbsp;</div>
                                    <button
                                        class="uk-button uk-button-default uk-width-1-1 uk-text-center uk-border-rounded search"
                                        type="submit"
                                    >
                                        SEARCH
                                    </button>
                                </div>
                            </form>
                        </li>
                        <li>
                            <form
                                class="uk-grid-small"
                                method="post"
                                @submit.prevent="validateForm('searchPhone')"
                                data-vv-scope="searchPhone"
                                uk-grid
                            >
                                <div class="uk-width-2-3@m">
                                    <label>Phone Number</label>
                                    <VInputWithValidation
                                        v-model="formData.phone.phone_number"
                                        v-validate="'required'"
                                        name="phone_number"
                                        type="tel"
                                        class="uk-border-rounded"
                                        placeholder="PHONE NUMBER"
                                        autocomplete="off"
                                        :error="
                                            errors.first(
                                                'searchPhone.phone_number'
                                            )
                                        "
                                        :valid="phone_number.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-width-1-3@m">
                                    <div class="uk-visible@m">&nbsp;</div>
                                    <button
                                        class="uk-button uk-button-default uk-width-1-1 uk-text-center uk-border-rounded search"
                                        type="submit"
                                    >
                                        SEARCH
                                    </button>
                                </div>
                            </form>
                        </li>
                    </ul>
                </div>

                <div class="uk-text-center uk-margin-small">
                    <div class="secure">
                        <span
                            uk-label-success
                            uk-icon="icon:lock; ratio:1.5"
                        ></span>
                        Secure and private search
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-section uk-section-primary info">
            <div class="uk-container">
                <div class="uk-grid-match uk-child-width-1-3@m" uk-grid>
                    <div
                        class="uk-text-center"
                        uk-scrollspy="cls:uk-animation-scale-up"
                    >
                        <div uk-icon="icon: users; ratio: 5"></div>
                        <h4 class="uk-margin-remove">PERSONAL INFORMATION</h4>
                        <p>
                            Uncover information pertaining to background
                            details, addresses, education, career & financial
                            history etc.
                        </p>
                    </div>
                    <div
                        class="uk-text-center"
                        uk-scrollspy="cls:uk-animation-scale-up"
                    >
                        <div uk-icon="icon: world; ratio: 5"></div>
                        <h4 class="uk-margin-remove">PUBLIC RECORDS</h4>
                        <p>
                            Reveal past and current encounters with the law such
                            as criminal records, arrests, bankruptcies etc.
                        </p>
                    </div>
                    <div
                        class="uk-text-center"
                        uk-scrollspy="cls:uk-animation-scale-up"
                    >
                        <div uk-icon="icon: phone; ratio: 5"></div>
                        <h4 class="uk-margin-remove">REVERSE PHONE</h4>
                        <p>
                            Search for someone you know using their phone number
                            or find out information about unknown numbers.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div id="why">
            <div
                class="uk-section-muted uk-dark uk-animation-fade uk-padding-large pitch"
            >
                <div class="uk-container uk-container-center uk-text-center">
                    <div class="uk-heading-primary">
                        Why <span>{{ getSiteName }}</span> ?
                    </div>
                    <div
                        class="uk-padding-large uk-padding-remove-horizontal uk-padding-remove-bottom"
                    >
                        <p>
                            We're always hard at work collecting information so
                            that your searches can return the most comprehensive
                            reports available on the market.
                        </p>
                        <p>
                            We have millions of consumer records containing over
                            a billion pieces of current and accurate
                            information.
                        </p>
                        <br />

                        <div
                            class="uk-child-width-expand@s uk-text-center uk-grid-small uk-padding-remove"
                            uk-grid
                        >
                            <div>
                                <div
                                    class="uk-card uk-card-default uk-card-body uk-padding-remove uk-border-rounded"
                                >
                                    <div
                                        class="identity-feature uk-background-cover uk-background-blend-screen uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                                    ></div>
                                    <h3
                                        class="uk-text-primary uk-padding-small uk-margin-remove-top uk-padding-remove-bottom"
                                    >
                                        Identity & Contact Info
                                    </h3>
                                    <hr />
                                    <p
                                        class="uk-padding-small uk-padding-remove-top uk-text-meta"
                                    >
                                        Aliases, Birth Date, Residential
                                        History, Phone Numbers, Emails
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div
                                    class="uk-card uk-card-default uk-card-body uk-padding-remove uk-border-rounded"
                                >
                                    <div
                                        class="financial-feature uk-background-cover uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                                    ></div>
                                    <h3
                                        class="uk-text-primary uk-padding-small uk-margin-remove-top uk-padding-remove-bottom"
                                    >
                                        Financial History
                                    </h3>
                                    <hr />
                                    <p
                                        class="uk-padding-small uk-padding-remove-top uk-text-meta"
                                    >
                                        Assets, Properties, Bankruptcies,
                                        Judgements, Liens, UCC filings
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div
                                    class="uk-card uk-card-default uk-card-body uk-padding-remove uk-border-rounded"
                                >
                                    <div
                                        class="lifestyle-feature uk-background-cover uk-height-small uk-panel uk-flex uk-flex-center uk-flex-middle"
                                    ></div>
                                    <h3
                                        class="uk-text-primary uk-padding-small uk-margin-remove-top uk-padding-remove-bottom"
                                    >
                                        Lifestyle & Legal Data
                                    </h3>
                                    <hr />
                                    <p
                                        class="uk-padding-small uk-padding-remove-top uk-text-meta"
                                    >
                                        Education & Career details, Known
                                        Associates, Warrants & Arrests
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-divider-icon uk-padding"></div>
                    <div class="uk-grid" uk-grid>
                        <div class="uk-width-expand@m">
                            <ul
                                class="uk-list uk-text-center uk-text-left@m"
                                uk-scrollspy="cls: uk-animation-slide-left-medium; repeat: true"
                            >
                                <li>
                                    <div class="uk-text-large">
                                        Four Ways Of Searching
                                    </div>
                                </li>
                                <li>
                                    <span
                                        uk-icon="icon: user; ratio: 1.5"
                                        class="uk-text-bottom uk-text-primary"
                                    ></span>
                                    Search By Name
                                    <div class="uk-text-meta">
                                        Enter First & Last name along with the
                                        state and get a full background report
                                        in minutes.
                                    </div>
                                    <hr />
                                </li>
                                <li>
                                    <span
                                        uk-icon="icon: location; ratio: 1.5"
                                        class="uk-text-bottom uk-text-primary"
                                    ></span>
                                    Search By Address
                                    <div class="uk-text-meta">
                                        You can look up someone by entering any
                                        known current or past addresses.
                                    </div>
                                    <hr />
                                </li>
                                <li>
                                    <span
                                        uk-icon="icon: phone; ratio: 1.5"
                                        class="uk-text-bottom uk-text-primary"
                                    ></span>
                                    Search By Phone
                                    <div class="uk-text-meta">
                                        Enter a phone number and we will match
                                        it against known records.
                                    </div>
                                    <hr />
                                </li>
                                <li>
                                    <span
                                        uk-icon="icon: mail; ratio: 1.5"
                                        class="uk-text-bottom uk-text-primary"
                                    ></span>
                                    Search By Email
                                    <div class="uk-text-meta">
                                        Look up an email address for a
                                        comprehensive search trough known
                                        background history associated with the
                                        email address.
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="uk-width-1-2@m">
                            <img
                                uk-scrollspy="cls: uk-animation-slide-right-medium; repeat: true"
                                src="/media/search.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="uk-section-primary uk-light uk-animation-fade bulletcontainer"
            >
                <div class="uk-container uk-container-center uk-text-center">
                    <div class="uk-child-width-1-2@m uk-grid-match" uk-grid>
                        <div>
                            <div
                                class=""
                                uk-scrollspy="cls: uk-animation-slide-left; repeat: true"
                            >
                                <h3 class="features">
                                    <span
                                        uk-icon="icon: users; ratio: 1.5"
                                        class="uk-text-top"
                                    ></span>
                                    Used by Millions
                                </h3>
                                <p>
                                    After 10 years, we've developed a loyal user
                                    base that helps us improve every day.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div
                                class=""
                                uk-scrollspy="cls: uk-animation-slide-right; repeat: true"
                            >
                                <h3 class="features">
                                    <span
                                        uk-icon="icon: clock; ratio: 1.5"
                                        class="uk-text-top"
                                    ></span>
                                    Save Time
                                </h3>
                                <p>
                                    Stop endless Googling and running to local
                                    courthouses. We put everything in one spot.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div
                                class=""
                                uk-scrollspy="cls: uk-animation-slide-left; repeat: true"
                            >
                                <h3 class="features">
                                    <span
                                        uk-icon="icon: bolt; ratio: 1.5"
                                        class="uk-text-top"
                                    ></span>
                                    Fast & Convenient
                                </h3>
                                <p>
                                    You can get a full background report in
                                    minutes with minimal effort on your part.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div
                                class=""
                                uk-scrollspy="cls: uk-animation-slide-right; repeat: true"
                            >
                                <h3 class="features">
                                    <span
                                        uk-icon="icon: bookmark; ratio: 1.5"
                                        class="uk-text-top"
                                    ></span>
                                    Comprehensive
                                </h3>
                                <p>
                                    We cross-check billions of data points and
                                    dozens of data sources for you.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="faq uk-section uk-section-muted uk-padding-large">
                <div class="uk-container uk-container-center uk-text-center">
                    <h2 class="uk-text-primary">Frequently Asked Questions</h2>
                    <ul class="faqaccordion" uk-accordion>
                        <li>
                            <a class="uk-accordion-title" href="#"
                                >How is {{ getSiteName }} better than Google
                                ?</a
                            >
                            <div class="uk-accordion-content">
                                <p>
                                    Simply put, we have direct access to data
                                    Google doesn't. Our info comes directly from
                                    National, State, and Municipal databases
                                    that a Google search cannot access. While
                                    Google may be able to point you in a general
                                    direction, we connect you to concise,
                                    quality, background reports quickly,
                                    eliminating the "searching game" for you.
                                </p>
                            </div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"
                                >Can I find this information without
                                {{ getSiteName }} ?</a
                            >
                            <div class="uk-accordion-content">
                                <p>
                                    Sure! If you're willing to identify which
                                    databases you need. Figure out where each
                                    one is located. Stand in line and pay the
                                    fees at the DMV, County Assessors Office,
                                    Federal and Municpal Courthouses, just to
                                    name a few. Shall we go on?
                                </p>
                            </div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"
                                >What are the permitted uses of information
                                uncovered through {{ getSiteName }} ?</a
                            >
                            <div class="uk-accordion-content">
                                <ul
                                    style="list-style: none; margin:0; padding:0;"
                                >
                                    <li>Finding a long-lost family member.</li>
                                    <li>
                                        Reconnecting with a former classmate or
                                        military buddy.
                                    </li>
                                    <li>
                                        Checking your own public records and
                                        protecting your identity.
                                    </li>
                                    <li>
                                        Researching an individual before going
                                        on that first date.
                                    </li>
                                    <li>Finding someone's current address.</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#"
                                >Unlawful Uses of Our Service</a
                            >
                            <div class="uk-accordion-content">
                                <ul
                                    style="list-style: none; margin:0; padding:0;"
                                >
                                    <li>
                                        Establishing an individual's eligibility
                                        for personal credit.
                                    </li>
                                    <li>
                                        Evaluating a candidate for employment.
                                    </li>
                                    <li>Screening a new tenant.</li>
                                    <li>
                                        Looking up celebrity contact
                                        information.
                                    </li>
                                    <li>
                                        Stalking a person you are interested in.
                                    </li>
                                    <li>Stealing an individual's identify.</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div
            class="anonymous-cont uk-section uk-section-secondary uk-padding-large uk-background-cover uk-background-top-center"
            uk-parallax="bgy: -100"
        >
            <div class="uk-container uk-container-center">
                <div class="anonymous">
                    <div class="uk-padding-small">
                        <div class="uk-width-1-1 uk-text-center">
                            <h2
                                class="uk-text-primary"
                                style="color:#fff!important;"
                            >
                                <span
                                    uk-icon="icon: lock; ratio: 2"
                                    class="uk-text-bottom"
                                ></span>
                                Anonymous, Private & Secure Search
                            </h2>
                            <p
                                class="uk-width-1-1"
                                style="color:#aaa!important;"
                            >
                                The people you look up will never be notified
                                that you requested or accessed their public
                                information.
                            </p>
                        </div>
                        <div class="uk-width-1-1 uk-text-center">
                            <button
                                class="uk-width-1-2@s uk-width-1-1 uk-button uk-button-large uk-margin-small-bottom searchnow"
                            >
                                <span
                                    uk-icon="icon: lock; ratio: 1"
                                    class="uk-text-middle"
                                ></span>
                                Search Privately Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapFields } from "vee-validate";
import { mapGetters } from "vuex";
import { States } from "@/mixins/States";
import { PageHelpers } from "@/mixins/PageHelpers";
import VInputWithValidation from "@/components/VInputWithValidation";
import VSelectWithValidation from "@/components/VSelectWithValidation";

export default {
    middleware: "guest",
    name: "Home",
    metaInfo: {
        title: "Home"
    },
    mixins: [States, PageHelpers],
    components: {
        VInputWithValidation,
        VSelectWithValidation
    },
    computed: {
        ...mapGetters("site", { getSiteName: "name" }),
        ...mapFields([
            "first_name",
            "last_name",
            "person_state",
            "phone_number",
            "street_address_1",
            "street_address_2",
            "city",
            "age",
            "address_state",
            "postal_code"
        ])
    },
    data() {
        return {
            currentSearchType: "person",
            formData: {
                person: {
                    first_name: "",
                    last_name: "",
                    state: "",
                    age: ""
                },
                phone: {
                    phone_number: ""
                },
                address: {
                    street_address_1: "",
                    street_address_2: "",
                    city: "",
                    state: "",
                    postal_code: ""
                }
            }
        };
    },
    methods: {
        validateForm(formName) {
            this.$validator.validateAll(formName).then(result => {
                if (result) {
                    const submitMethod = formName + "Submit";
                    this[submitMethod]();
                }
            });
        },
        searchPersonSubmit() {
            this.$store.dispatch("search/saveSearch", {
                type: "person",
                params: this.formData.person
            });
            this.$router.push({ name: "buy" });
        },
        searchPhoneSubmit() {
            this.$store.dispatch("search/saveSearch", {
                type: "phone",
                params: this.formData.phone
            });
            this.$router.push({ name: "buy" });
        },
        searchAddressSubmit() {
            this.$store.dispatch("search/saveSearch", {
                type: "address",
                params: this.formData.address
            });
            this.$router.push({ name: "buy" });
        }
    }
};
</script>
