<template>
    <div class="uk-height-viewport uk-background-cover uk-flex uk-flex-column">
        <div class="uk-section uk-padding-large" style="flex-grow: 1">
            <div class="uk-container">
                <div class="uk-animation-slide-top" uk-grid>
                    <div class="uk-width-expand"></div>
                    <div class="uk-width-2-5@m uk-width-1-1 uk-text-center ">
                        <div>
                            <router-link to="/" class="uk-logo">
                                <img
                                    src="images/logo.png"
                                    alt=""
                                    width="200px"
                                />
                            </router-link>
                        </div>
                        <br />
                        <form
                            class="uk-card uk-card-default uk-card-body"
                            @submit.prevent="validateBeforeSubmit"
                        >
                            <fieldset class="uk-fieldset">
                                <legend class="uk-legend">
                                    RESET YOUR PASSWORD
                                </legend>
                                <p class="uk-text-small">
                                    Enter the email address or phone number
                                    associated with your account and we will
                                    email you a reset code.
                                </p>
                                <div class="uk-margin">
                                    <VInputWithValidation
                                        v-model="formData.email"
                                        v-validate="'required'"
                                        name="email"
                                        placeholder="Email Address"
                                        autocomplete="off"
                                        :error="errors.first('email')"
                                        :valid="email.valid"
                                    ></VInputWithValidation>
                                    <div
                                        uk-alert
                                        v-if="hasAlert"
                                        :class="
                                            isError ? errorClass : successClass
                                        "
                                    >
                                        {{ message }}
                                    </div>
                                </div>
                                <p
                                    class="uk-text-meta uk-text-small uk-text-danger"
                                >
                                    If you no longer have access to the email
                                    you signed up with, please contact our
                                    Member Support line at (800) 987-6543.
                                </p>
                                <div>
                                    <button
                                        class="uk-button uk-button-primary uk-width-1-1 uk-padding-small"
                                        type="submit"
                                    >
                                        Send Password Reset Link
                                    </button>
                                </div>
                                <div>
                                    <hr />
                                    <router-link to="/login"
                                        >Return to Login Page</router-link
                                    >
                                </div>
                            </fieldset>
                        </form>
                        <br />
                    </div>
                    <div class="uk-width-expand"></div>
                </div>
            </div>
        </div>
        <!-- End Home Section-->
    </div>
</template>

<script>
import axios from "axios";
import { mapFields } from "vee-validate";
import { PageHelpers } from "@/mixins/PageHelpers";
import VInputWithValidation from "@/components/VInputWithValidation";

export default {
    middleware: "guest",
    name: "ResetPassword",
    metaInfo: {
        title: "Reset Password"
    },
    mixins: [PageHelpers],
    components: { VInputWithValidation },
    data() {
        return {
            formData: { email: "" },
            message: "",
            hasAlert: "",
            errorClass: "uk-alert-danger",
            successClass: "uk-alert-success",
            isError: null
        };
    },
    computed: {
        ...mapFields(["email"])
    },
    methods: {
        validateBeforeSubmit() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.passwordRequest();
                }
            });
        },
        passwordRequest() {
            let self = this;
            axios
                .post("/api/password", { email: this.formData.email })
                .then(res => {
                    self.message = res.data.message;
                    self.hasAlert = true;
                    self.isError = !res.data.status;
                    if (!self.isError) {
                        setTimeout(function() {
                            self.$router.push({ name: "login" });
                        }, 4000);
                    }
                })
                .catch(err => {
                    self.message = `${err}, try again!`;
                    self.hasAlert = true;
                    self.isError = true;
                });
        }
    }
};
</script>
