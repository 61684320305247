<template>
    <div>
        <label v-if="label" :for="getIdValue" class="uk-form-label">{{
            label
        }}</label>
        <div class="uk-form-controls">
            <input
                :id="getIdValue"
                :name="name"
                :type="type"
                :disabled="disabled"
                :placeholder="placeholder"
                :autocomplete="autocomplete"
                :value="value"
                :class="[
                    { 'uk-form-danger': hasError, 'uk-form-success': valid },
                    'uk-input'
                ]"
                @change="updateValue"
                @input="updateValue"
                @blur="$emit('blur')"
                @focus="$emit('focus')"
            />
            <p v-show="getErrorMessage" class="form-error">
                {{ getErrorMessage }}
            </p>
        </div>
    </div>
</template>

<script>
import _ from "lodash/core";
export default {
    $_veeValidate: {
        name() {
            return this.getIdValue;
        },
        value() {
            return this.value;
        }
    },
    name: "VInputWithValidation",
    props: {
        name: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: "text",
            validator: val => {
                return (
                    [
                        "url",
                        "text",
                        "password",
                        "email",
                        "search",
                        "tel"
                    ].indexOf(val) !== -1
                );
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: String,
            default: "on"
        },
        error: {
            type: [String, Boolean],
            default: ""
        },
        valid: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        }
    },
    computed: {
        getIdValue() {
            if (!_.isEmpty(this.id)) {
                return this.id;
            }
            return this.name.match(/^[^[]+/)[0];
        },
        hasError() {
            const hasError = !_.isBoolean(this.error)
                ? !_.isEmpty(this.error)
                : this.error;
            return !this.valid && hasError;
        },
        getErrorMessage() {
            return !_.isBoolean(this.error) ? this.error : "";
        }
    },
    methods: {
        updateValue(e) {
            this.$emit("input", e.target.value);
        }
    }
};
</script>
