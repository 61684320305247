<template>
    <div class="uk-margin-small">
        <div class="uk-card uk-padding-remove uk-text-center uk-text-left@m">
            <div class="uk-card uk-card-default uk-margin-small">
                <div class="uk-card-header uk-padding-small ">
                    <div class="uk-grid uk-grid-collapse">
                        <div class="uk-width-1-1">
                            <h3 class="uk-margin-remove-bottom">{{ title }}</h3>
                        </div>
                    </div>
                </div>
                <div class="uk-card-body uk-padding-small uk-background-muted">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ReportEntry",
    props: {
        title: {
            type: String,
            default: ""
        }
    }
};
</script>
