<template>
    <div>
        <ReportEntry :title="getLocationString(report.location)">
            <div
                v-if="isPageLoading"
                :class="[
                    { 'uk-animation-fade': !isPageLoading },
                    'uk-animation-reverse',
                    'module-spinner'
                ]"
            >
                <div class="uk-position-center" uk-spinner></div>
            </div>
            <div v-else>
                <ul class="uk-list uk-grid-collapse uk-grid" uk-grid>
                    <li class="uk-width-1-1">
                        <h4>Residents</h4>
                        <hr />
                        <template v-for="(resident, index) in report.residents">
                            <p :key="'rname-' + index">
                                Name: {{ resident.identity.name }}
                            </p>
                            <p :key="'rdob-' + index">
                                DOB: {{ resident.identity.date_of_birth }}
                            </p>
                            <p :key="'rage-' + index">
                                Age: {{ resident.identity.age }}
                            </p>
                            <h6 :key="'rest-' + index">Residencies:</h6>
                            <template
                                v-for="(location, index) in resident.locations"
                            >
                                <p :key="'rloc-' + index">
                                    {{ getLocationString(location) }}
                                </p>
                            </template>
                            <h6 :key="'veht-' + index">Vehicles</h6>
                            <template
                                v-for="(vehicle, index) in resident.vehicles"
                            >
                                <p :key="'rveh-' + index">
                                    {{ getVehicleString(vehicle) }} | Vin:
                                    {{ vehicle.vin }}
                                </p>
                            </template>
                            <h6 :key="'crimt-' + index">Criminal Records</h6>
                            <template
                                v-for="(record,
                                index) in resident.criminal_records"
                            >
                                <p :key="'rcrio-' + index">
                                    {{ record.offense }}
                                </p>
                                <p :key="'rcrid-' + index">
                                    {{ record.filing_date }} |
                                    {{ record.source }}
                                </p>
                            </template>
                            <h6 :key="'sext-' + index">Sexual Offenses</h6>
                            <template
                                v-for="(offense,
                                index) in resident.sexual_offenses"
                            >
                                <p :key="'roffs-' + index">
                                    {{ offense.status }}
                                </p>
                                <p :key="'roffd-' + index">
                                    {{ offense.date_first_seen }}
                                </p>
                            </template>
                            <hr :key="'hr-' + index" />
                        </template>
                        <h4>Past Residents</h4>
                        <hr />
                        <template
                            v-for="(resident, index) in report.past_residents"
                        >
                            <p :key="'pname-' + index">
                                Name: {{ resident.identity.name }}
                            </p>
                            <p :key="'pdob-' + index">
                                DOB: {{ resident.identity.date_of_birth }}
                            </p>
                            <p :key="'page-' + index">
                                Age: {{ resident.identity.age }}
                            </p>
                            <h6 :key="'prest-' + index">Residencies:</h6>
                            <template
                                v-for="(location, index) in resident.locations"
                            >
                                <p :key="'ploc-' + index">
                                    {{ getLocationString(location) }}
                                </p>
                            </template>
                            <h6 :key="'veht-' + index">Vehicles</h6>
                            <template
                                v-for="(vehicle, index) in resident.vehicles"
                            >
                                <p :key="'pveh-' + index">
                                    {{ getVehicleString(vehicle) }} | Vin:
                                    {{ vehicle.vin }}
                                </p>
                            </template>
                            <h6 :key="'pcrimt-' + index">Criminal Records</h6>
                            <template
                                v-for="(record,
                                index) in resident.criminal_records"
                            >
                                <p :key="'pcrio-' + index">
                                    {{ record.offense }}
                                </p>
                                <p :key="'pcrid-' + index">
                                    {{ record.filing_date }} |
                                    {{ record.source }}
                                </p>
                            </template>
                            <h6 :key="'psext-' + index">Sexual Offenses</h6>
                            <template
                                v-for="(offense,
                                index) in resident.sexual_offenses"
                            >
                                <p :key="'poffs-' + index">
                                    {{ offense.status }}
                                </p>
                                <p :key="'poffd-' + index">
                                    {{ offense.date_first_seen }}
                                </p>
                            </template>
                            <hr :key="'phr-' + index" />
                        </template>
                        <h4>Property Documents</h4>
                        <hr />
                        <template v-for="(document, index) in report.documents">
                            <p :key="'rdoct-' + index">
                                {{ document.type }}
                            </p>
                            <template v-if="document.type === 'Deed'">
                                <p :key="'rdocdv-' + index">
                                    Value: {{ document.mortgage_value }}
                                </p>
                                <p :key="'rdocdb-' + index">
                                    Year Built: {{ document.year_built }}
                                </p>
                                <p :key="'rdocdd-' + index">
                                    Filing Date: {{ document.recording_date }}
                                </p>
                            </template>
                            <template v-if="document.type === 'Assessed'">
                                <template
                                    v-for="(identity,
                                    index) in document.identity"
                                >
                                    <p :key="'rdocan-' + index">
                                        Name: {{ identity.name }}
                                    </p>
                                </template>
                                <p :key="'rdocav-' + index">
                                    Value: {{ document.assessed_value }}
                                </p>
                                <p :key="'rdocab-' + index">
                                    Year Built: {{ document.year_built }}
                                </p>
                                <p :key="'rdocad-' + index">
                                    Filing Date: {{ document.recording_date }}
                                </p>
                            </template>
                            <hr :key="'dhr-' + index" />
                        </template>
                        <h4>Non Resident Records</h4>
                        <hr />
                        <h6>Criminal Records</h6>
                        <template
                            v-for="(record,
                            index) in report.non_resident_criminal_records"
                        >
                            <p :key="'nrcrio-' + index">
                                {{ record.offense }}
                            </p>
                            <p :key="'nrcrid-' + index">
                                {{ record.filing_date }} | {{ record.source }}
                            </p>
                        </template>
                        <h6>Sexual Offenses</h6>
                        <template
                            v-for="(offense,
                            index) in report.non_resident_sexual_offenses"
                        >
                            <p :key="'nroffs-' + index">
                                {{ offense.status }}
                            </p>
                            <p :key="'nroffd-' + index">
                                {{ offense.date_first_seen }}
                            </p>
                        </template>
                        <h6>Vehicles</h6>
                        <template
                            v-for="(vehicle,
                            index) in report.non_resident_vehicles"
                        >
                            <p :key="'nrveh-' + index">
                                {{ getVehicleString(vehicle) }} | Vin:
                                {{ vehicle.vin }}
                            </p>
                        </template>
                        <hr />
                    </li>
                </ul>
            </div>
        </ReportEntry>
    </div>
</template>

<script>
import _ from "lodash";
import { PageHelpers } from "@/mixins/PageHelpers";
import ReportEntry from "@/components/Report/ReportEntry";

export default {
    name: "AddressReport",
    mixins: [PageHelpers],
    components: {
        ReportEntry
    },
    props: {
        report: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },
    computed: {
        isPageLoading() {
            return _.isEmpty(this.report);
        }
    },
    methods: {
        getVehicleString(vehicle) {
            let vehicleArr = [];

            if (!_.isEmpty(vehicle.year)) {
                vehicleArr.push(vehicle.year);
            }

            if (!_.isEmpty(vehicle.color)) {
                vehicleArr.push(vehicle.color);
            }

            if (!_.isEmpty(vehicle.manufacturer)) {
                vehicleArr.push(vehicle.manufacturer);
            }

            if (!_.isEmpty(vehicle.model)) {
                vehicleArr.push(vehicle.model);
            }

            return vehicleArr.join(" ");
        }
    }
};
</script>
