import * as types from "../mutation-types";

// state
export const state = {
    funnel: [],
    current: {
        type: null,
        params: {}
    }
};

// getters
export const getters = {
    funnel: state => state.funnel,
    searchType: state => state.current.type,
    searchParams: state => state.current.params
};

// mutations
export const mutations = {
    [types.SAVE_SEARCH_CONFIG_DATA](state, { data }) {
        state.funnel = data.funnel;
    },
    [types.SAVE_SEARCH_FUNNEL](state, data) {
        state.funnel = data.funnel;
    },
    [types.SAVE_CURRENT_SEARCH_DATA](state, data) {
        state.current.type = data.type;
        state.current.params = data.params;
    }
};

// actions
export const actions = {
    saveFunnel({ commit }, payload) {
        commit(types.SAVE_SEARCH_FUNNEL, payload);
    },
    saveSearch({ commit }, payload) {
        commit(types.SAVE_CURRENT_SEARCH_DATA, payload);
    }
};
