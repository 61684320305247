<template>
    <div class="uk-section-muted uk-padding-large">
        <div class="uk-container">
            <div
                class="uk-card uk-card-default uk-card-body uk-text-left@m uk-text-center"
            >
                <h1>{{ getSiteName }} Terms and Conditions</h1>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi sit amet turpis eu leo bibendum dictum ac a eros.
                    Donec placerat gravida turpis, ac placerat ipsum scelerisque
                    at. Maecenas finibus semper ornare. Sed a nulla orci.
                    Phasellus iaculis velit vel velit tincidunt, at bibendum
                    lacus tempus. Nunc bibendum dui magna, semper pharetra nulla
                    posuere a. Nunc pharetra nisl a massa condimentum, id cursus
                    ante eleifend. Curabitur sapien dui, consectetur non lacus
                    non, mattis pretium nibh. Duis rutrum ipsum malesuada diam
                    interdum, eget pulvinar velit sagittis. Sed aliquam aliquet
                    tortor nec eleifend.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PageHelpers } from "@/mixins/PageHelpers";

export default {
    name: "Terms",
    metaInfo: {
        title: "Terms and Conditions"
    },
    mixins: [PageHelpers],
    computed: {
        ...mapGetters("site", { getSiteName: "name" })
    }
};
</script>
