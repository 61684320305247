<template>
    <div
        v-if="pageFailedToLoad"
        :class="[
            { 'uk-animation-slide-top-medium': pageFailedToLoad },
            'uk-height-large'
        ]"
    >
        <div
            class="page-error-cont uk-card uk-card-default uk-width-1-1 uk-width-1-2@m uk-align-center"
        >
            <div class="uk-card-header">
                <div class="uk-grid-small uk-flex-middle" uk-grid>
                    <div class="uk-width-auto">
                        <i class="fas fa-exclamation-circle"></i>
                    </div>
                    <div
                        class="uk-width-expand uk-padding-remove-left uk-margin-small-left"
                    >
                        <h3 class="uk-card-title uk-margin-remove-bottom">
                            An Error Has Occurred!
                        </h3>
                    </div>
                </div>
            </div>
            <div class="uk-card-body">
                <p>{{ getPageError }}</p>
            </div>
            <div class="uk-card-footer uk-clearfix">
                <a
                    @click="$router.go()"
                    class="uk-button uk-button-secondary uk-margin-remove-bottom uk-float-right uk-margin-small-left"
                >
                    <i class="fas fa-redo uk-margin-small-right"></i>
                    Refresh Page
                </a>
                <a
                    @click="$router.go(-1)"
                    class="uk-button uk-button-default uk-margin-remove-bottom uk-float-right"
                >
                    <i class="fas fa-chevron-left uk-margin-small-right"></i>
                    Go Back
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
    name: "PageError",
    computed: {
        ...mapGetters("page", {
            getPageError: "error"
        }),
        pageFailedToLoad() {
            return !_.isEmpty(this.getPageError);
        }
    }
};
</script>
