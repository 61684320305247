import store from "@/store";

//Access_token not required

//import HomeExtendedShowcase from "@/pages/HomeExtendedShowcase";
import Home from "@/pages/Home";
import Login from "@/pages/Login";
import Faq from "@/pages/Faq";
import Signup from "@/pages/Signup"; //Temp signup route
import ResetPassword from "@/pages/ResetPassword";
import Contact from "@/pages/Contact";
import Terms from "@/pages/Terms";
import Privacy from "@/pages/Privacy";
import Spam from "@/pages/Spam";
import Dmca from "@/pages/Dmca";
import Buy from "@/pages/Buy";

//Access_token required
import Dashboard from "@/pages/Dashboard";
import Report from "@/pages/Report";
import Settings from "@/pages/Settings";
import ReportSearch from "@/pages/ReportSearch";

export default [
    {
        //Temp signup route
        path: "/signup",
        name: "signup",
        component: Signup
    },
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/logout",
        name: "logout",
        beforeEnter: (to, from, next) => {
            store.dispatch("auth/logout").then(() => {
                next({ name: "login" });
            });
        }
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard
    },
    {
        path: "/search",
        name: "search",
        component: ReportSearch
    },
    {
        path: "/report/:type/:id",
        name: "report",
        component: Report,
        props: true
    },
    {
        path: "/settings",
        name: "settings",
        component: Settings
    },
    {
        path: "/contact",
        name: "contact",
        component: Contact
    },
    {
        path: "/faq",
        name: "faq",
        component: Faq
    },
    {
        path: "/terms",
        name: "terms",
        component: Terms
    },
    {
        path: "/privacy",
        name: "privacy",
        component: Privacy
    },
    {
        path: "/spam",
        name: "spam",
        component: Spam
    },
    {
        path: "/dmca",
        name: "dmca",
        component: Dmca
    },
    {
        path: "/buy",
        name: "buy",
        component: Buy
    },
    {
        path: "/reset/password",
        name: "ResetPassword",
        component: ResetPassword,
        props: true
    }
];
