import axios from "axios";
import store from "@/store";
import { router } from "@/router";

axios.interceptors.request.use(request => {
    const access_token = store.getters["auth/token"];

    if (access_token) {
        request.headers.common["Authorization"] = `Bearer ${access_token}`;
    }

    return request;
});

axios.interceptors.response.use(
    response => response,
    error => {
        const { status } = error.response;

        if (status === 401 && store.getters["auth/check"]) {
            store.commit("auth/LOGOUT");
            router.push({ name: "login" });
        }
        return Promise.reject(error);
    }
);
