<template>
    <div class="uk-section-muted uk-padding-large">
        <div class="uk-container">
            <div uk-grid>
                <div class="uk-width-1-3@m">
                    <div
                        class="uk-card uk-card-default uk-card-body uk-padding-small"
                        uk-sticky="media: 960; offset: 150"
                    >
                        <h3>Frequently Asked Questions</h3>
                        <ul class="uk-nav uk-nav-default">
                            <li>
                                <a href="#faq1" uk-scroll="offset:140"
                                    >Information Collection</a
                                >
                                <hr />
                            </li>
                            <li>
                                <a href="#faq2" uk-scroll="offset:140"
                                    >What can I use Unfaithful for ?</a
                                >
                                <hr />
                            </li>
                            <li>
                                <a href="#faq3" uk-scroll="offset:140"
                                    >Opting out</a
                                >
                                <hr />
                            </li>
                            <li>
                                <a href="#faq4" uk-scroll="offset:140"
                                    >Searching people from other countries</a
                                >
                                <hr />
                            </li>
                            <li>
                                <a href="#faq5" uk-scroll="offset:140"
                                    >Is the person I search for notified ?</a
                                >
                                <hr />
                            </li>
                            <li>
                                <a href="#faq6" uk-scroll="offset:140"
                                    >Account Cancellation</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="uk-width-2-3@m">
                    <ul class="uk-list">
                        <li id="faq1">
                            <h3>
                                How Does {{ getSiteName }} Use Information it
                                Collects When I Sign Up?
                            </h3>
                            <p>
                                {{ getSiteName }} uses the information you
                                submit during the registration process to manage
                                your relationship with {{ getSiteName }}. For
                                example, we use your email address to provide
                                you with information about your BeenVerified
                                account, product updates, and general
                                information about {{ getSiteName }}. We do not,
                                under any circumstances, use your account
                                information as part of our search results nor do
                                we sell your information to third party
                                providers of public record information.
                            </p>
                            <hr />
                        </li>
                        <li id="faq2">
                            <h3>What Can I Use {{ getSiteName }} For?</h3>
                            <p>
                                There are countless ways you can use
                                {{ getSiteName }}. This includes checking out
                                people you meet on online dating websites,
                                trying to locate old friends, and learning more
                                about those in you or your family members'
                                lives. You can also use {{ getSiteName }} to see
                                what information is out there on yourself. You
                                can use {{ getSiteName }} to find out who called
                                your phone, to
                                <a href="#">research property</a>, or find a
                                person's public social networking profiles.
                            </p>
                            <p>
                                What you can't do is use {{ getSiteName }} to
                                determine a person's eligibility or suitability
                                for employment, insurance, credit, loans,
                                education, housing, or other similar purposes.
                                Using {{ getSiteName }} information in these
                                ways violates both our
                                <a href="/terms">Terms & Conditions</a> and the
                                law, and can lead to possible criminal
                                penalties. We take this very seriously, and
                                reserve the right to terminate user accounts
                                and/or report violators to law enforcement as
                                appropriate.
                            </p>
                            <p>
                                If you are still not sure whether your use of
                                {{ getSiteName }} is OK, please visit
                                <a href="#"
                                    >Do's and Don'ts – Permitted and Prohibited
                                    Uses</a
                                >. In addition, we encourage you to review our
                                complete
                                <a href="/terms">Terms & Conditions</a>, which
                                provide the final say on how you can use
                                information from {{ getSiteName }}, or to
                                contact us at
                                <a :href="'mailto:' + getPrivacyEmail">{{
                                    getPrivacyEmail
                                }}</a
                                >.
                            </p>
                            <hr />
                        </li>
                        <li id="faq3">
                            <h3>Need to Opt-Out? We Make it Easy</h3>
                            <p>
                                If you see your information in our
                                <a href="#">people search results</a>, and don't
                                want it to show up any more, no problem.
                                {{ getSiteName }} offers you the ability to
                                remove this information from our people search
                                results by following our
                                <a href="#">easy instructions</a>. Sometimes,
                                however, different information about you might
                                appear because it can't conclusively be
                                associated with the information you previously
                                opted out – you can opt out this information
                                too. We try to be as transparent as possible
                                about how this works. For detailed information
                                about our opt-out process, please read our
                                <a href="#">Opt-Out Instructions</a> or contact
                                us at
                                <a :href="'mailto:' + getPrivacyEmail">{{
                                    getPrivacyEmail
                                }}</a
                                >.
                            </p>
                            <hr />
                        </li>
                        <li id="faq4">
                            <h3>Can I Search People from Other Countries?</h3>
                            <p>
                                At this time {{ getSiteName }} does not offer
                                the ability to search records pertaining to the
                                international community. Our services only
                                provide information on US-based people.
                            </p>
                            <hr />
                        </li>
                        <li id="faq5">
                            <h3>Is Anyone Notified I Am Searching for Them?</h3>
                            <p>
                                All of {{ getSiteName }} reports are
                                confidential. The person you are searching for
                                is not notified.
                            </p>
                            <hr />
                        </li>
                        <li id="faq6">
                            <h3>How do I Cancel my Account ?</h3>
                            <p>
                                {{ getSiteName }} provides hassle-free
                                cancellation. Simply call our customer service
                                line anytime at
                                <a :href="'tel:' + getSitePhone">{{
                                    getSitePhone
                                }}</a>
                                and have your 9-digit membership ID handy. If
                                you need additional assistance, our
                                <a href="/contact">support team</a> is available
                                on weekdays from 8:00 AM to 10:00 PM Eastern
                                Time, and 10:00 AM to 8:00 PM Eastern Time on
                                weekends. If you are unable to reach us by
                                phone, you can also email us with your 9-digit
                                membership ID at
                                <a :href="'mailto:' + getSupportEmail">{{
                                    getSupportEmail
                                }}</a
                                >. You can find the membership ID in your
                                Welcome New Member email, or in the
                                <a v-if="isLoggedIn" href="/settings"
                                    >Account Details section</a
                                >
                                <span v-else>Account Details section</span>
                                of your {{ getSiteName }} account.
                            </p>
                            <hr />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PageHelpers } from "@/mixins/PageHelpers";

export default {
    name: "Faq",
    metaInfo: {
        title: "FAQ"
    },
    mixins: [PageHelpers],
    computed: {
        ...mapGetters("site", {
            getSiteName: "name",
            getSitePhone: "phone",
            getPrivacyEmail: "privacyEmail",
            getSupportEmail: "supportEmail"
        }),
        isLoggedIn() {
            return this.$store.getters["auth/check"];
        }
    }
};
</script>
