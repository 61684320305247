<template>
    <div>
        <ReportEntry :title="report.identity.name">
            <div
                v-if="isPageLoading"
                :class="[
                    { 'uk-animation-fade': !isPageLoading },
                    'uk-animation-reverse',
                    'module-spinner'
                ]"
            >
                <div class="uk-position-center" uk-spinner></div>
            </div>
            <div v-else>
                <ul class="uk-list uk-grid-collapse uk-grid" uk-grid>
                    <li class="uk-width-1-1">
                        <h4>Identity:</h4>
                        <p>Name: {{ report.identity.name }}</p>
                        <p>DOB: {{ report.identity.date_of_birth }}</p>
                        <hr />
                        <h4>Locations:</h4>
                        <template v-for="(location, index) in report.locations">
                            <p :key="'loc-' + index">
                                {{ getLocationString(location) }}
                            </p>
                        </template>
                        <hr />
                        <h4>Phones:</h4>
                        <template v-for="(phone, index) in report.phones">
                            <p :key="'pho-' + index">
                                {{ phone.phone_number }}
                            </p>
                        </template>
                        <hr />
                        <h4>Emails:</h4>
                        <template v-for="(email, index) in report.emails">
                            <p :key="'ema-' + index">
                                {{ email.email_address }} | {{ email.provider }}
                            </p>
                        </template>
                        <hr />
                        <h4>Associates:</h4>
                        <template
                            v-for="(associate, index) in report.associates"
                        >
                            <p :key="'assn-' + index">
                                Name: {{ associate.identity.name }}
                            </p>
                            <p :key="'assr-' + index">
                                Relation: {{ getRelationString(associate) }}
                            </p>
                            <h6 :key="'loct-' + index">Locations:</h6>
                            <template
                                v-for="(location, index) in associate.locations"
                            >
                                <p :key="'assl-' + index">
                                    {{ getLocationString(location) }}
                                </p>
                            </template>
                            <h6 :key="'phot-' + index">Phones:</h6>
                            <template
                                v-for="(phone, index) in associate.phones"
                            >
                                <p :key="'assp-' + index">
                                    {{ phone.phone_number }}
                                </p>
                            </template>
                        </template>
                        <hr />
                        <h4>Documents:</h4>
                        <template v-for="(document, index) in report.documents">
                            <p :key="'rdoct-' + index">
                                {{ document.type }}
                            </p>
                            <template v-if="document.type === 'Deed'">
                                <p :key="'docdv-' + index">
                                    Value: {{ document.mortgage_value }}
                                </p>
                                <p :key="'docdb-' + index">
                                    Year Built: {{ document.year_built }}
                                </p>
                                <p :key="'docdd-' + index">
                                    Filing Date: {{ document.recording_date }}
                                </p>
                            </template>
                            <template v-if="document.type === 'Assessed'">
                                <template
                                    v-for="(identity,
                                    index) in document.identity"
                                >
                                    <p :key="'docan-' + index">
                                        Name: {{ identity.name }}
                                    </p>
                                </template>
                                <p :key="'docav-' + index">
                                    Value: {{ document.assessed_value }}
                                </p>
                                <p :key="'docab-' + index">
                                    Year Built: {{ document.year_built }}
                                </p>
                                <p :key="'docad-' + index">
                                    Filing Date: {{ document.recording_date }}
                                </p>
                            </template>
                        </template>
                        <hr />
                        <h4>Criminal Records:</h4>
                        <template
                            v-for="(record, index) in report.criminal_records"
                        >
                            <p :key="'crio-' + index">
                                {{ record.offense }}
                            </p>
                            <p :key="'crid-' + index">
                                {{ record.filing_date }} | {{ record.source }}
                            </p>
                        </template>
                        <hr />
                        <h4>Sexual Offenses:</h4>
                        <template
                            v-for="(offense, index) in report.sexual_offenses"
                        >
                            <p :key="'offs-' + index">
                                {{ offense.status }}
                            </p>
                            <p :key="'offd-' + index">
                                {{ offense.date_first_seen }}
                            </p>
                        </template>
                        <hr />
                    </li>
                </ul>
            </div>
        </ReportEntry>
    </div>
</template>

<script>
import _ from "lodash";
import { PageHelpers } from "@/mixins/PageHelpers";
import ReportEntry from "@/components/Report/ReportEntry";

export default {
    name: "PersonReport",
    mixins: [PageHelpers],
    components: {
        ReportEntry
    },
    props: {
        report: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },
    computed: {
        isPageLoading() {
            return _.isEmpty(this.report);
        }
    },
    methods: {
        getRelationString(associate) {
            return !_.isEmpty(associate.relation)
                ? associate.relation
                : "Associate";
        }
    }
};
</script>
