<template>
    <div>
        <ReportEntry title="Phone Owners">
            <div
                v-if="isPageLoading"
                :class="[
                    { 'uk-animation-fade': !isPageLoading },
                    'uk-animation-reverse',
                    'module-spinner'
                ]"
            >
                <div class="uk-position-center" uk-spinner></div>
            </div>
            <div v-else>
                <ul class="uk-list uk-grid-collapse uk-grid" uk-grid>
                    <template v-for="owner in report.owners">
                        <li
                            class="uk-width-1-1"
                            :key="'owner-' + owner.identifier"
                        >
                            <h3>{{ owner.identity.name }}</h3>
                            <h4>Locations:</h4>
                            <template
                                v-for="(location, index) in owner.locations"
                            >
                                <p :key="'location-' + index">
                                    {{ getLocationString(location) }}
                                </p>
                            </template>
                            <h4>Phones:</h4>
                            <template v-for="(phone, index) in owner.phones">
                                <p :key="'provider-' + index">
                                    Provider: {{ phone.provider }}
                                </p>
                                <p :key="'type-' + index">
                                    Line type: {{ phone.line_type.join(", ") }}
                                </p>
                            </template>
                            <hr />
                        </li>
                    </template>
                </ul>
            </div>
        </ReportEntry>
    </div>
</template>

<script>
import _ from "lodash";
import { PageHelpers } from "@/mixins/PageHelpers";
import ReportEntry from "@/components/Report/ReportEntry";

export default {
    name: "PhoneReport",
    mixins: [PageHelpers],
    components: {
        ReportEntry
    },
    props: {
        report: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },
    computed: {
        isPageLoading() {
            return _.isEmpty(this.report);
        }
    }
};
</script>
