<template>
    <div class="uk-height-viewport uk-background-cover uk-flex uk-flex-column">
        <div class="uk-section uk-padding-large" style="flex-grow: 1">
            <div class="uk-container">
                <div class="uk-animation-slide-top uk-grid" uk-grid="">
                    <div class="uk-width-expand uk-first-column"></div>
                    <div class="uk-width-2-5@m uk-width-1-1 uk-text-center ">
                        <form
                            class="uk-card uk-card-default uk-card-body"
                            @submit.prevent="validateBeforeSubmit"
                        >
                            <fieldset class="uk-fieldset">
                                <legend class="uk-legend">
                                    Temporary Sign Up
                                </legend>
                                <div class="uk-margin">
                                    <VInputWithValidation
                                        v-model="formData.name"
                                        v-validate="'required'"
                                        name="name"
                                        placeholder="Name"
                                        autocomplete="off"
                                        :error="errors.first('name')"
                                        :valid="name.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-margin">
                                    <VInputWithValidation
                                        v-model="formData.email"
                                        v-validate="'required'"
                                        name="email"
                                        placeholder="Email Address"
                                        autocomplete="off"
                                        :error="errors.first('email')"
                                        :valid="email.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-margin">
                                    <VInputWithValidation
                                        v-model="formData.password"
                                        v-validate="'required'"
                                        name="password"
                                        placeholder="Password"
                                        autocomplete="off"
                                        type="password"
                                        :error="errors.first('password')"
                                        :valid="password.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-margin">
                                    <VInputWithValidation
                                        v-model="formData.password_confirmation"
                                        v-validate="'required'"
                                        name="password_confirmation"
                                        placeholder="Confirm Password"
                                        autocomplete="off"
                                        type="password"
                                        :error="
                                            errors.first(
                                                'password_confirmation'
                                            )
                                        "
                                        :valid="password_confirmation.valid"
                                    ></VInputWithValidation>
                                </div>

                                <div>
                                    <button
                                        class="uk-button uk-button-primary uk-width-1-1 uk-padding-small"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapFields } from "vee-validate";
import { PageHelpers } from "@/mixins/PageHelpers";
import VInputWithValidation from "@/components/VInputWithValidation";

export default {
    middleware: "guest",
    name: "Signup",
    mixins: [PageHelpers],
    components: { VInputWithValidation },
    data: () => ({
        formData: {
            name: "",
            email: "",
            password: "",
            password_confirmation: ""
        }
    }),
    computed: {
        ...mapFields(["name", "email", "password", "password_confirmation"])
    },
    methods: {
        validateBeforeSubmit() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.submitForm();
                }
            });
        },
        submitForm() {
            axios
                .post("/api/register", this.formData)
                .then(result => {
                    this.saveUser(result.data);
                })
                .catch(error => {
                    const errors = error.response.data.errors;

                    if (
                        errors !== undefined &&
                        Object.keys(errors).length > 0
                    ) {
                        const fields = Object.keys(errors);

                        fields.forEach(name => {
                            //Add error to error bag
                            this.errors.add({
                                field: name,
                                msg: errors[name][0]
                            });
                            //Update the field's flag status'
                            this.$validator.flag(name, {
                                valid: false,
                                dirty: true
                            });
                        });
                    } else {
                        alert(error);
                    }
                });
        },
        async saveUser(data) {
            this.$store.dispatch("auth/saveToken", {
                access_token: data.access_token,
                remember: false
            });

            await this.$store.dispatch("auth/fetchUser");

            // Redirect home.
            this.$router.push({ name: "dashboard" });
        }
    }
};
</script>

<style scoped></style>
