var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-width-2-3@m"},[_c('br',{staticClass:"uk-hidden@m"}),_vm._v(" "),_c('div',{attrs:{"id":"reports-main"}},[_c('div',{staticClass:"uk-card uk-width-1-1"},[_c('div',{staticClass:"uk-card-header uk-padding-small uk-background-primary uk-light"},[_c('div',{staticClass:"uk-grid-small",attrs:{"uk-grid":""}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"uk-width-auto@m uk-width-1-1"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showActiveReports),expression:"showActiveReports"}],staticClass:"uk-margin"},[_c('select',{staticClass:"uk-select uk-text-small",attrs:{"sortByInput":_vm.sortByInput},on:{"change":function($event){return _vm.sortBy($event)}}},[_c('option',[_vm._v("Sort by:")]),_vm._v(" "),_c('option',{attrs:{"value":"latsOpened"}},[_vm._v("Recently Viewed")]),_vm._v(" "),_c('option',{attrs:{"value":"createdDate"}},[_vm._v("Search Date")])])])])])]),_vm._v(" "),(_vm.isPageLoading)?_c('div',{class:[
                    { 'uk-animation-fade': !_vm.isPageLoading },
                    'uk-animation-reverse',
                    'module-spinner',
                    'active-reports-spinner'
                ]},[_c('div',{staticClass:"uk-position-center",attrs:{"uk-spinner":""}})]):_c('div',{class:[
                    { 'uk-animation-fade': !_vm.isPageLoading },
                    'uk-card-body',
                    'uk-padding-small'
                ]},[(_vm.showActiveReports)?_c('div',{staticClass:"active-reports-cont uk-margin-small"},_vm._l((_vm.activeReports),function(activeReport){return _c('div',{key:activeReport.id,staticClass:"uk-card uk-padding-remove uk-text-center uk-text-left@m"},[_c('div',{staticClass:"uk-card uk-card-default uk-margin-small"},[_c('div',{staticClass:"uk-card-header uk-padding-small"},[_c('div',{staticClass:"uk-grid uk-grid-collapse"},[_c('div',{staticClass:"uk-width-3-4@m uk-width-1-1"},[_c('h3',{staticClass:"uk-margin-remove-bottom"},[_vm._v("\n                                            "+_vm._s(activeReport.fullName)+"\n                                            "),_c('span',{staticClass:"uk-text-meta"},[_vm._v(_vm._s(activeReport.city +
                                                    ", " +
                                                    activeReport.state))])]),_vm._v(" "),_c('p',{staticClass:"uk-text-meta uk-margin-remove"},[_vm._v("\n                                            Search date:\n                                            "+_vm._s(activeReport.createdDate)),_c('br'),_vm._v(" "),_c('span',{staticClass:"uk-text-meta"},[_vm._v("Last viewed:\n                                                "+_vm._s(activeReport.latsOpened))])])]),_vm._v(" "),_c('div',{staticClass:"uk-width-1-4@m uk-width-1-1 uk-flex uk-flex-middle uk-margin-small"},[_c('span',{staticClass:"uk-width-1-1"},[_c('router-link',{staticClass:"uk-button uk-button-primary uk-width-1-1 uk-margin-remove",attrs:{"to":{
                                                    name: 'report',
                                                    params: {
                                                        id: activeReport.id,
                                                        type: 'person'
                                                    }
                                                }}},[_vm._v("View Report")])],1)])])]),_vm._v(" "),_c('div',{staticClass:"uk-card-body uk-padding-small",attrs:{"id":'report-' + activeReport.id}},[_c('div',{staticClass:"uk-grid-collapse",attrs:{"uk-grid":""}},[_c('button',{staticClass:"uk-button uk-button-small uk-button-default uk-width-auto@m uk-width-1-3@s uk-width-1-1",on:{"click":function($event){$event.preventDefault();return _vm.confirmDelete(activeReport.id)}}},[_c('i',{staticClass:"fas fa-trash-alt"}),_vm._v(" "),_c('span',[_vm._v("Delete")])]),_vm._v(" "),_c('a',{staticClass:"uk-button uk-button-small uk-button-default uk-width-auto@m uk-width-1-3@s uk-width-1-1",attrs:{"href":activeReport.downloadLink,"target":"_blank"}},[_c('i',{staticClass:"fas fa-download"}),_vm._v("\n                                        Download\n                                    ")]),_vm._v(" "),_c('router-link',{staticClass:"uk-button uk-button-small uk-button-default uk-width-auto@m uk-width-1-3@s uk-width-1-1",attrs:{"to":"search"}},[_c('i',{staticClass:"fas fa-search"}),_vm._v(" Search\n                                        again\n                                    ")])],1)])])])}),0):_c('div',{staticClass:"uk-margin-small"},[_vm._m(1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-width-expand@m uk-width-1-1"},[_c('h3',{staticClass:"uk-margin-remove-bottom uk-text-left@m uk-text-center"},[_vm._v("\n                            Your Reports\n                        ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-card uk-padding-remove uk-text-center uk-text-left@m"},[_c('div',{staticClass:"uk-card uk-card-default uk-margin-small"},[_c('div',{staticClass:"uk-card-body uk-padding-small"},[_vm._v("\n                                No Records Found\n                            ")])])])}]

export { render, staticRenderFns }