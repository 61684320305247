var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('search'),_vm._v(" "),_c('div',{staticClass:"uk-section-muted uk-padding-large"},[_c('div',{staticClass:"uk-container"},[_c('div',{staticClass:"uk-grid uk-grid-small"},[_c('div',{staticClass:"uk-width-1-3@m"},[_c('div',{staticClass:"uk-card uk-card-default uk-padding-remove uk-text-small",attrs:{"uk-sticky":"media: 960; offset: 150"}},[_vm._m(0),_vm._v(" "),(_vm.isPageLoading)?_c('div',{class:[
                                { 'uk-animation-fade': !_vm.isPageLoading },
                                'uk-animation-reverse',
                                'module-spinner'
                            ]},[_c('div',{staticClass:"uk-position-center",attrs:{"uk-spinner":""}})]):_c('div',{class:[
                                { 'uk-animation-fade': !_vm.isPageLoading },
                                'uk-card-body',
                                'uk-padding-small'
                            ]},[_c('ul',{staticClass:"uk-list uk-grid-collapse uk-padding-small uk-padding-remove-top",attrs:{"uk-grid":""}},[_c('li',{staticClass:"uk-width-1-2@m uk-width-1-2"},[_c('h4',{staticClass:"uk-margin-remove-top"},[_vm._v("\n                                        "+_vm._s(_vm.user.name)+"\n                                    ")])]),_vm._v(" "),_c('li',{staticClass:"uk-width-1-2@m uk-width-1-2 uk-margin-remove-top"},[_c('router-link',{staticClass:"uk-button uk-button-default uk-button-small uk-width-1-1",attrs:{"to":"/settings"}},[_vm._v("My Account")])],1),_vm._v(" "),_c('li',{staticClass:"uk-width-1-1"},[_c('p',[_vm._v(_vm._s(_vm.user.email))])]),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._l((_vm.services),function(service,idx){return [_c('li',{key:'service-name-' + idx,staticClass:"uk-width-3-5"},[_vm._v("\n                                        "+_vm._s(service.serviceName)+"\n                                    ")]),_vm._v(" "),(service.available)?_c('li',{key:'service-status-' + idx,staticClass:"uk-width-2-5 uk-text-center"},[_c('i',{staticClass:"fas fa-check"}),_vm._v("\n                                        Available\n                                    ")]):_c('li',{key:'service-unlock' + idx,staticClass:"uk-width-2-5"},[_c('button',{staticClass:"uk-button uk-button-primary uk-button-small uk-width-1-1",on:{"click":function($event){return _vm.unlockService(service.id)}}},[_vm._v("\n                                            UNLOCK\n                                        ")])]),_vm._v(" "),_c('li',{key:'service-spacer-' + idx,staticClass:"uk-width-1-1 small uk-hidden@m"},[_c('hr')])]})],2)])])]),_vm._v(" "),_c('active-reports',{attrs:{"isPageLoading":_vm.isPageLoading,"activeReports":_vm.activeReports},on:{"delete-report":_vm.deleteReport}})],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-card-header uk-background-primary uk-light uk-padding-small uk-text-left@m uk-text-center"},[_c('h3',[_vm._v("Account Status")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"uk-width-1-1 small"},[_c('hr')])}]

export { render, staticRenderFns }