<template>
    <div>
        <label v-if="label" :for="getIdValue" class="uk-form-label">{{
            label
        }}</label>
        <div class="uk-form-controls">
            <textarea
                :id="getIdValue"
                :name="name"
                :rows="rows"
                :value="value"
                :placeholder="placeholder"
                :class="[
                    { 'uk-form-danger': hasError, 'uk-form-success': valid },
                    'uk-textarea'
                ]"
                @change="updateValue"
                @input="updateValue"
                @blur="$emit('blur')"
                @focus="$emit('focus')"
            ></textarea>
            <p v-show="error" class="form-error">{{ error }}</p>
        </div>
    </div>
</template>

<script>
import _ from "lodash/core";
export default {
    $_veeValidate: {
        name() {
            return this.getIdValue;
        },
        value() {
            return this.value;
        }
    },
    name: "VTextareaWithValidation",
    props: {
        name: {
            type: String,
            required: true
        },
        value: {
            type: null,
            default: () => null
        },
        placeholder: {
            type: String,
            default: ""
        },
        error: {
            type: String,
            default: ""
        },
        valid: {
            type: Boolean,
            default: false
        },
        rows: {
            type: String,
            required: true
        },
        id: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        }
    },
    data: () => ({
        innerValue: ""
    }),
    computed: {
        getIdValue() {
            if (!_.isEmpty(this.id)) {
                return this.id;
            }
            return this.name.match(/^[^[]+/)[0];
        },
        hasError() {
            return !this.valid && this.error.length > 0;
        }
    },
    methods: {
        updateValue(e) {
            this.$emit("input", e.target.value);
        }
    }
};
</script>
