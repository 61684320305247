<template>
    <div class="uk-section-muted uk-padding-large">
        <div class="uk-container">
            <div uk-grid>
                <div class="uk-width-1-3@m uk-flex-first@m uk-flex-last">
                    <div class="uk-padding-small uk-text-center">
                        <h3>Other ways of reaching us</h3>
                        <ul class="uk-nav uk-nav-default">
                            <li><hr /></li>
                            <li>
                                <div>
                                    <span class="fa fa-home"></span>
                                    {{ getSiteName }}<br />
                                    {{ getSiteAddress }}
                                </div>
                            </li>
                            <li><hr /></li>
                            <li>
                                <div>
                                    <span class="fa fa-phone"></span>
                                    <a :href="'tel:' + getSitePhone">{{
                                        getSitePhone
                                    }}</a
                                    ><br />
                                </div>
                                <span class="uk-text-meta"
                                    >Mon - Fri, 8:00 AM - 5:00 PM EST</span
                                >
                                <hr />
                            </li>
                            <li>
                                <div>
                                    <span class="fa fa-envelope"></span>
                                    <a :href="'mail:' + getSupportEmail">{{
                                        getSupportEmail
                                    }}</a
                                    ><br />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="!formSubmitSuccess" class="uk-width-2-3@m">
                    <form
                        class="uk-card uk-card-default uk-card-body uk-text-left@m uk-text-center"
                        @submit.prevent="validateBeforeSubmit"
                    >
                        <fieldset class="uk-fieldset">
                            <legend class="uk-legend">
                                How can we help you ?
                            </legend>
                            <div class="uk-margin">
                                <VSelectWithValidation
                                    name="subject"
                                    class="uk-select"
                                    :error="errors.first('subject')"
                                    :valid="subject.valid"
                                    v-model="formData.subject"
                                    v-validate="'required'"
                                >
                                    <option selected disabled value="">
                                        Choose Support Topic
                                    </option>
                                    <option value="1">Reason 1</option>
                                    <option value="2">Reason 2</option>
                                    <option value="3">Reason 3</option>
                                    <option value="4">Reason 4</option>
                                    <option value="5">Reason 5</option>
                                    <option value="6">Other</option>
                                </VSelectWithValidation>
                            </div>
                            <div class="uk-margin">
                                <VInputWithValidation
                                    name="name"
                                    placeholder="Name"
                                    autocomplete="off"
                                    :error="errors.first('name')"
                                    :valid="name.valid"
                                    v-model="formData.name"
                                    v-validate="'required'"
                                ></VInputWithValidation>
                            </div>
                            <div class="uk-margin">
                                <VInputWithValidation
                                    name="email"
                                    placeholder="Email Address"
                                    autocomplete="off"
                                    :error="errors.first('email')"
                                    :valid="email.valid"
                                    v-model="formData.email"
                                    v-validate="'required'"
                                ></VInputWithValidation>
                            </div>
                            <div class="uk-margin">
                                <VTextareaWithValidation
                                    name="message"
                                    class="uk-textarea"
                                    placeholder="Your Message"
                                    rows="5"
                                    :error="errors.first('message')"
                                    :valid="message.valid"
                                    v-model="formData.message"
                                    v-validate="'required'"
                                ></VTextareaWithValidation>
                            </div>
                            <div>
                                <button
                                    class="uk-button uk-button-primary uk-width-1-1 uk-padding-small"
                                >
                                    Submit
                                </button>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div v-else class="uk-width-2-3@m">
                    <div
                        class="uk-card uk-card-default uk-card-body uk-text-left@m uk-text-center"
                    >
                        <h3>Message sent successfully!</h3>
                        <p>We will contact your shortly.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { mapFields } from "vee-validate";
import { PageHelpers } from "@/mixins/PageHelpers";
import VInputWithValidation from "../components/VInputWithValidation";
import VTextareaWithValidation from "../components/VTextareaWithValidation";
import VSelectWithValidation from "../components/VSelectWithValidation";

export default {
    name: "Contact",
    metaInfo: {
        title: "Contact Us"
    },
    mixins: [PageHelpers],
    components: {
        VInputWithValidation,
        VTextareaWithValidation,
        VSelectWithValidation
    },
    data: () => ({
        formSubmitSuccess: false,
        formData: {
            subject: "",
            name: "",
            email: "",
            message: ""
        }
    }),
    computed: {
        ...mapFields(["subject", "name", "email", "message"]),
        ...mapGetters("site", {
            getSiteName: "name",
            getSitePhone: "phone",
            getSiteAddress: "address",
            getSupportEmail: "supportEmail"
        })
    },
    methods: {
        validateBeforeSubmit() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.submit();
                }
            });
        },
        submit() {
            axios
                .post("/api/contact", this.formData)
                .then(() => {
                    this.formSubmitSuccess = true;
                })
                .catch(error => {
                    this.formSubmitSuccess = false;
                    const errors = error.response.data.errors;
                    if (
                        errors !== undefined &&
                        Object.keys(errors).length > 0
                    ) {
                        const fields = Object.keys(errors);

                        fields.forEach(name => {
                            //Add error to error bag
                            this.errors.add({
                                field: name,
                                msg: errors[name][0]
                            });
                            //Update the field's flag status'
                            this.$validator.flag(name, {
                                valid: false,
                                dirty: true
                            });
                        });
                    } else {
                        this.showNotification(
                            "danger",
                            "An error has occurred!"
                        );
                    }
                });
        }
    }
};
</script>
