//Import base libraries needed to init Vue instance
import Vue from "vue";
import VeeValidate from "vee-validate";
import { router } from "@/router";
import store from "@/store";

//Import JS plugin configurations
import "@/plugins";

import App from "@/components/App";

Vue.use(VeeValidate);

// Finally initialize a new instance of Vue and the supporting libraries/config
const app = new Vue({
    el: "#app",
    router,
    store,
    render: h => h(App)
});

export default app;
