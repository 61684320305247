import * as types from "../mutation-types";

// state
export const state = {
    loading: false,
    error: "",
    component_name: ""
};

// getters
export const getters = {
    loading: state => state.loading,
    error: state => state.error,
    component_name: state => state.component_name
};

// mutations
export const mutations = {
    [types.UPDATE_LOADING_STATUS](state, bool) {
        state.loading = bool;
    },
    [types.UPDATE_PAGE_ERROR](state, errorMessage) {
        state.error = errorMessage;
    },
    [types.UPDATE_COMPONENT_NAME](state, componentName) {
        state.component_name = componentName;
    }
};

// actions
export const actions = {
    updateLoadingStatus({ commit }, bool) {
        commit(types.UPDATE_LOADING_STATUS, bool);
    },
    updatePageError({ commit }, errorMessage) {
        commit(types.UPDATE_PAGE_ERROR, errorMessage);
    },
    updateComponentName({ commit }, componentName) {
        commit(types.UPDATE_COMPONENT_NAME, componentName);
    }
};
