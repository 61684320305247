import UIkit from "uikit";
import _ from "lodash";

export const PageHelpers = {
    created() {
        setTimeout(() => {
            this.$store.dispatch("page/updateLoadingStatus", false);
        }, 1000);
    },
    methods: {
        showNotification(status, message) {
            if (status === "danger") {
                message = `<p class='uk-animation-scale-up uk-margin-remove'>
                                <i class='fas fa-times uk-margin-small-right'></i> 
                                ${message} 
                            </p>
                            <p class='uk-animation-scale-up uk-margin-remove'>
                                Please refresh the page and try again.
                            </p>`;
            }
            UIkit.notification(message, { pos: "top-right", status: status });
        },
        getLocationString(locationObj) {
            let locationArr = [];

            if (!_.isEmpty(locationObj.street_address_1)) {
                locationArr.push(locationObj.street_address_1);
            }

            if (!_.isEmpty(locationObj.street_address_2)) {
                locationArr.push(locationObj.street_address_2);
            }

            if (!_.isEmpty(locationObj.city)) {
                locationArr.push(locationObj.city);
            }

            if (!_.isEmpty(locationObj.state)) {
                locationArr.push(locationObj.state);
            }

            if (!_.isEmpty(locationObj.postal_code)) {
                locationArr.push(locationObj.postal_code.toString());
            }

            return locationArr.join(" ");
        }
    }
};
