import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const requireContext = require.context(
    // relative path to the modules dir
    "./modules",
    // look in subfolders
    false,
    // reg expression to find js files
    /.*\.js$/
);

const modules = requireContext
    .keys()
    .map(file => [file.replace(/(^.\/)|(\.js$)/g, ""), requireContext(file)])
    .reduce((modules, [name, module]) => {
        if (module.namespaced === undefined) {
            module.namespaced = true;
        }

        return { ...modules, [name]: module };
    }, {});

export default new Vuex.Store({
    modules
});
