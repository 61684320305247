<template>
    <div
        class="bg-cont uk-height-viewport uk-background-cover uk-flex uk-flex-column"
    >
        <div class="uk-section uk-padding-large" style="flex-grow: 1">
            <div class="uk-container">
                <div class="uk-animation-slide-top uk-grid" uk-grid="">
                    <div class="uk-width-expand uk-first-column"></div>
                    <div class="uk-width-2-5@m uk-width-1-1 uk-text-center ">
                        <form
                            class="uk-card uk-card-default uk-card-body"
                            @submit.prevent="validateBeforeSubmit"
                        >
                            <fieldset class="uk-fieldset">
                                <legend class="uk-legend">LOG IN</legend>
                                <div class="uk-margin">
                                    <VInputWithValidation
                                        v-model="formData.email"
                                        v-validate="'required'"
                                        name="email"
                                        placeholder="Email Address"
                                        autocomplete="off"
                                        :error="errors.first('email')"
                                        :valid="email.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-margin">
                                    <VInputWithValidation
                                        v-model="formData.password"
                                        v-validate="'required'"
                                        name="password"
                                        placeholder="Password"
                                        autocomplete="off"
                                        type="password"
                                        :error="errors.first('password')"
                                        :valid="password.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div
                                    class="uk-margin uk-grid-small uk-child-width-auto uk-grid"
                                >
                                    <label>
                                        <input
                                            v-model="formData.remember"
                                            name="remember"
                                            class="uk-checkbox"
                                            type="checkbox"
                                        />
                                        Remember Me
                                    </label>
                                </div>
                                <div>
                                    <button
                                        class="uk-button uk-button-primary uk-width-1-1 uk-padding-small"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                                <div>
                                    <hr />
                                    <router-link to="reset/password"
                                        >Forgot your password ?</router-link
                                    >
                                </div>
                            </fieldset>
                        </form>
                        <br />
                        <div class="uk-light">
                            Don't have an account ?
                            <a href="/signup">Sign Up</a>
                        </div>
                    </div>
                    <div class="uk-width-expand"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UIkit from "uikit";
import { mapFields } from "vee-validate";
import { PageHelpers } from "@/mixins/PageHelpers";
import VInputWithValidation from "@/components/VInputWithValidation";

export default {
    middleware: "guest",
    name: "Login",
    metaInfo: {
        title: "Login"
    },
    mixins: [PageHelpers],
    components: { VInputWithValidation },
    data: () => ({
        formData: {
            email: "",
            password: "",
            remember: false
        }
    }),
    computed: {
        ...mapFields(["email", "password", "remember"])
    },
    methods: {
        validateBeforeSubmit() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.submitForm();
                }
            });
        },
        submitForm() {
            axios
                .post("/api/login", this.formData)
                .then(result => {
                    this.saveLogin(result.data);
                })
                .catch(() => {
                    UIkit.notification(
                        "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-times uk-margin-small-right'></i> An error has occurred!</p><p class='uk-animation-scale-up uk-margin-remove'>Please refresh the page and try again.</p>",
                        { pos: "top-right", status: "danger" }
                    );
                });
        },
        async saveLogin(data) {
            this.$store.dispatch("auth/saveToken", {
                access_token: data.access_token,
                remember: this.remember
            });

            await this.$store.dispatch("auth/fetchUser");

            // Redirect home.
            this.$router.push({ name: "dashboard" });
        }
    }
};
</script>
