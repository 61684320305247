import * as types from "../mutation-types";

// state
export const state = {
    name: null,
    phone: null,
    address: null,
    email: {
        privacy: null,
        support: null
    }
};

// getters
export const getters = {
    name: state => state.name,
    phone: state => state.phone,
    address: state => state.address,
    privacyEmail: state => state.email.privacy,
    supportEmail: state => state.email.support
};

// mutations
export const mutations = {
    [types.SAVE_SITE_CONFIG_DATA](state, { data }) {
        state.name = data.name;
        state.phone = data.phone;
        state.address = data.address;
        state.email.privacy = data.email.privacy;
        state.email.support = data.email.support;
    }
};
