<template>
    <div class="social-media-progress-bar uk-text-center uk-padding">
        <transition
            enter-active-class="uk-animation-slide-bottom-medium"
            leave-active-class="uk-animation-slide-top-medium uk-animation-reverse"
        >
            <div
                v-if="isProgressSectionShown"
                class="uk-text-large uk-padding "
            >
                <span>Analyzing data and compiling final report</span>
                <div>{{ percentageVal }}%</div>
                <div class="uk-flex uk-flex-center">
                    <progress
                        class="uk-progress uk-width-1-3@m uk-width-1-1"
                        :value="percentageVal"
                        :max="percentageMax"
                    ></progress>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "AnalyzingProgressBar",
    props: {
        enabled: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        isProgressSectionShown: false,
        progressBarTickTime: 110,
        progressBarIntervalFunc: null,
        percentageVal: 0,
        percentageMax: 100
    }),
    watch: {
        enabled(val) {
            if (val) {
                this.startStepComponent();
            }
        }
    },
    created() {
        if (this.enabled) {
            this.startStepComponent();
        }
    },
    methods: {
        startStepComponent() {
            this.isProgressSectionShown = true;
            this.startProgressPercentage();
        },
        endStepComponent() {
            this.$emit("onStepComplete");
        },
        startProgressPercentage: function() {
            const self = this;
            self.progressBarIntervalFunc = setInterval(() => {
                self.percentageVal += Math.floor(Math.random() * 10);

                if (self.percentageVal >= self.percentageMax) {
                    this.endStepComponent();
                }
            }, self.progressBarTickTime);
        }
    }
};
</script>
