<template>
    <Layout>
        <router-view></router-view>
    </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import Layout from "@/components/Layout";

export default {
    name: "App",
    components: { Layout },
    computed: {
        ...mapGetters("site", { getSiteName: "name" })
    },
    metaInfo() {
        return {
            title: this.getSiteName,
            titleTemplate: "%s | " + this.getSiteName
        };
    },
    created() {
        this.$store.dispatch("config/fetchInitData");
    }
};
</script>
