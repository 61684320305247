<template>
    <div :class="getParentClass">
        <header class="header uk-section" uk-sticky>
            <div class="uk-container uk-container-center">
                <nav
                    class="uk-navbar-container uk-navbar-transparent"
                    uk-navbar="mode: click"
                >
                    <!-- Logo Desktop -->
                    <div class="uk-navbar-left uk-visible@m">
                        <router-link class="uk-logo" to="/">
                            <img src="/media/logo.png" alt="" width="300px" />
                        </router-link>
                    </div>
                    <!-- Logo Mobile -->
                    <div class="uk-navbar-center uk-hidden@m">
                        <router-link class="uk-logo" to="/">
                            <img src="/media/logo.png" alt="" width="200px" />
                        </router-link>
                    </div>
                    <!-- Nav Desktop -->
                    <div class="uk-navbar-right uk-visible@m uk-animation-fade">
                        <ul v-if="isLoggedIn" class="uk-navbar-nav">
                            <li class="menuItem">
                                <router-link
                                    to="/dashboard"
                                    exact-active-class="uk-active"
                                    >Dashboard</router-link
                                >
                            </li>
                            <li class="menuItem">
                                <a
                                    @click="goToAnchorTag('/dashboard#search')"
                                    :class="{
                                        'uk-active': isMenuItemActive('search')
                                    }"
                                >
                                    Search
                                </a>
                            </li>
                            <li>
                                <a href="#"
                                    >My Account
                                    <span
                                        uk-icon="icon: triangle-down; ratio: .8"
                                        class="uk-padding-remove"
                                    ></span
                                ></a>
                                <div class="uk-navbar-dropdown">
                                    <ul class="uk-nav uk-navbar-dropdown-nav">
                                        <li>
                                            <router-link
                                                to="/settings"
                                                exact-active-class="uk-active"
                                                >Settings</router-link
                                            >
                                        </li>
                                        <li>
                                            <router-link
                                                to="/logout"
                                                exact-active-class="uk-active"
                                                >Logout</router-link
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <a href="#"
                                    >Help
                                    <span
                                        uk-icon="icon: triangle-down; ratio: .8"
                                        class="uk-padding-remove"
                                    ></span
                                ></a>
                                <div class="uk-navbar-dropdown">
                                    <ul class="uk-nav uk-navbar-dropdown-nav">
                                        <li>
                                            <router-link
                                                to="/faq"
                                                exact-active-class="uk-active"
                                            >
                                                FAQ
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link
                                                to="/contact"
                                                exact-active-class="uk-active"
                                                >Contact Us</router-link
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <ul v-else class="uk-navbar-nav">
                            <li class="peopleSearch menuItem">
                                <a
                                    @click="goToAnchorTag('/#search')"
                                    :class="{
                                        'uk-active': isMenuItemActive('search')
                                    }"
                                >
                                    Search
                                </a>
                            </li>
                            <li class="whyus menuItem">
                                <a
                                    @click="goToAnchorTag('/#why')"
                                    :class="{
                                        'uk-active': isMenuItemActive('why')
                                    }"
                                >
                                    Why Us?
                                </a>
                            </li>
                            <li class="faq menuItem">
                                <router-link
                                    to="/faq"
                                    exact-active-class="uk-active"
                                >
                                    FAQ
                                </router-link>
                            </li>
                            <li class="login">
                                <router-link
                                    to="/login"
                                    exact-active-class="uk-active"
                                >
                                    <span
                                        uk-icon="icon: sign-in; ratio: 1.4"
                                        class="uk-icon"
                                    >
                                        <svg
                                            width="28"
                                            height="28"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            data-svg="sign-in"
                                        >
                                            <polygon
                                                points="7 2 17 2 17 17 7 17 7 16 16 16 16 3 7 3"
                                            ></polygon>
                                            <polygon
                                                points="9.1 13.4 8.5 12.8 11.28 10 4 10 4 9 11.28 9 8.5 6.2 9.1 5.62 13 9.5"
                                            ></polygon>
                                        </svg>
                                    </span>
                                    LOGIN
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="uk-navbar-right uk-hidden@m">
                        <a
                            href="#mobileNav"
                            uk-icon="icon: menu; ratio: 1.5"
                            uk-toggle
                        ></a>
                    </div>
                </nav>
                <!-- Nav Mobile -->
                <div
                    id="mobileNav"
                    uk-offcanvas="overlay: true; flip: true; mode: push;"
                >
                    <div class="uk-offcanvas-bar">
                        <a href="" uk-close class="uk-offcanvas-close"></a>
                        <ul
                            v-if="isLoggedIn"
                            class="uk-nav uk-nav-default uk-text-center"
                        >
                            <li class="uk-active uk-padding-small menuItem">
                                <router-link
                                    to="/dashboard"
                                    exact-active-class="uk-active"
                                >
                                    <i class="fas fa-home uk-display-block"></i>
                                    Dashboard
                                </router-link>
                                <hr class="uk-margin-remove-bottom" />
                            </li>
                            <li class="uk-padding-small menuItem">
                                <a
                                    @click="goToAnchorTag('/dashboard#search')"
                                    :class="{
                                        'uk-active': isMenuItemActive('search')
                                    }"
                                >
                                    <i
                                        class="fas fa-search uk-display-block"
                                    ></i>
                                    Search
                                </a>
                                <hr class="uk-margin-remove-bottom" />
                            </li>
                            <li class="uk-padding-small menuItem">
                                <router-link
                                    to="/settings"
                                    exact-active-class="uk-active"
                                >
                                    <i class="fas fa-cog uk-display-block"></i>
                                    Settings
                                </router-link>
                                <hr class="uk-margin-remove-bottom" />
                            </li>
                            <li class="uk-padding-small about">
                                <router-link
                                    to="/contact"
                                    exact-active-class="uk-active"
                                >
                                    <i
                                        class="far fa-question-circle uk-display-block"
                                    ></i>
                                    Help
                                </router-link>
                                <hr class="uk-margin-remove-bottom" />
                            </li>
                            <li class="uk-padding-small about">
                                <router-link
                                    to="/faq"
                                    exact-active-class="uk-active"
                                >
                                    <i
                                        class="far fa-comment-dots uk-display-block"
                                    ></i>
                                    FAQ
                                </router-link>
                                <hr class="uk-margin-remove-bottom" />
                            </li>
                            <li class="uk-padding-small about">
                                <router-link
                                    to="/logout"
                                    exact-active-class="uk-active"
                                >
                                    <i
                                        class="fas fa-sign-out-alt uk-display-block"
                                    ></i>
                                    Logout
                                </router-link>
                                <hr class="uk-margin-remove-bottom" />
                            </li>
                        </ul>
                        <ul v-else class="uk-nav uk-nav-default uk-text-center">
                            <li
                                class="uk-active uk-padding-small peopleSearch menuItem"
                            >
                                <a
                                    @click="goToAnchorTag('/#search')"
                                    :class="{
                                        'uk-active': isMenuItemActive('search')
                                    }"
                                >
                                    Search
                                </a>
                                <hr class="uk-margin-remove-bottom" />
                            </li>
                            <li class="uk-padding-small whyus menuItem">
                                <a
                                    @click="goToAnchorTag('/#why')"
                                    :class="{
                                        'uk-active': isMenuItemActive('why')
                                    }"
                                >
                                    Why Us?
                                </a>
                                <hr class="uk-margin-remove-bottom" />
                            </li>
                            <li class="uk-padding-small faq menuItem">
                                <router-link
                                    to="/faq"
                                    exact-active-class="uk-active"
                                >
                                    FAQ
                                </router-link>
                                <hr class="uk-margin-remove-bottom" />
                            </li>
                            <li class="uk-padding-small login">
                                <router-link
                                    to="/login"
                                    exact-active-class="uk-active"
                                >
                                    <span
                                        uk-icon="icon: sign-in; ratio: 1.8"
                                        class="uk-display-block uk-padding-remove uk-icon"
                                        ><svg
                                            width="36"
                                            height="36"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            data-svg="sign-in"
                                        >
                                            <polygon
                                                points="7 2 17 2 17 17 7 17 7 16 16 16 16 3 7 3"
                                            ></polygon>
                                            <polygon
                                                points="9.1 13.4 8.5 12.8 11.28 10 4 10 4 9 11.28 9 8.5 6.2 9.1 5.62 13 9.5"
                                            ></polygon></svg
                                    ></span>
                                    LOGIN
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
        <PageLoader></PageLoader>
        <PageError></PageError>
        <slot v-if="!pageFailedToLoad"></slot>
        <footer class="footer uk-section uk-padding-large">
            <div class="uk-container uk-container-center">
                <div class="">
                    <div id="disclaimer" class="uk-text-meta uk-text-justify">
                        DISCLAIMER: You may not use our service or the
                        information it provides to make decisions about consumer
                        credit, employment, insurance, tenant screening, or any
                        other purpose that would require FCRA compliance.
                        {{ getSiteName }} does not provide consumer reports and
                        is not a consumer reporting agency. (These terms have
                        special meanings under the Fair Credit Reporting Act, 15
                        USC 1681 et seq., ("FCRA"), which are incorporated
                        herein by reference.) The information available on our
                        website may not be 100% accurate, complete, or up to
                        date, so do not use it as a substitute for your own due
                        diligence, especially if you have concerns about a
                        person's criminal history. {{ getSiteName }} does not
                        make any representation or warranty about the accuracy
                        of the information available through our website or
                        about the character or integrity of the person about
                        whom you inquire. For more information, please review
                        {{ getSiteName }}
                        Terms of Use.
                    </div>
                </div>
                <hr />
                <div uk-grid>
                    <div
                        class="uk-logo uk-width-auto@m uk-width-1-1@s uk-text-center"
                    >
                        <img src="/media/logo_muted.png" alt="" width="150px" />
                    </div>
                    <div class="uk-width-expand@m uk-width-1-1@s">
                        <nav
                            class="uk-navbar-container uk-navbar-transparent"
                            uk-navbar
                        >
                            <div
                                class="uk-navbar-right uk-text-center uk-width-auto@m uk-width-1-1@s"
                            >
                                <ul class="uk-navbar-nav">
                                    <li>
                                        <router-link to="/contact"
                                            >Contact Us</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link to="/terms"
                                            >Terms</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link to="/privacy"
                                            >Privacy</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link to="/spam"
                                            >Anti-Spam</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link to="/dmca"
                                            >DMCA Notice</router-link
                                        >
                                    </li>
                                </ul>
                            </div>
                        </nav>
                        <div
                            class="uk-text-right@m uk-text-center@s uk-text-meta copy"
                        >
                            © {{ new Date().getFullYear() }} {{ getSiteName }}.
                            All rights reserved
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { routerHelperFuncs } from "@/router";
import PageLoader from "@/components/PageLoader";
import PageError from "@/components/PageError";

export default {
    name: "Layout",
    components: { PageError, PageLoader },
    mixins: [routerHelperFuncs],
    computed: {
        ...mapGetters("page", { getPageError: "error" }),
        ...mapGetters("site", { getSiteName: "name" }),
        pageFailedToLoad() {
            return !_.isEmpty(this.getPageError);
        },
        getParentClass() {
            return !this.pageFailedToLoad
                ? this.$store.getters["page/component_name"] + "Page"
                : "ErrorPage";
        },
        isLoggedIn() {
            return this.$store.getters["auth/check"];
        }
    },
    methods: {
        isMenuItemActive(anchorTag) {
            return location.hash.indexOf(anchorTag) >= 0;
        }
    }
};
</script>
