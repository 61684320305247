<template>
    <div>
        <search></search>
        <!-- End Top Section -->
        <!-- HOME SECTION -->
        <div class="uk-section-muted uk-padding-large">
            <div class="uk-container">
                <div
                    v-if="pageFailedToLoad"
                    :class="[
                        { 'uk-animation-slide-top-medium': pageFailedToLoad },
                        'uk-height-large'
                    ]"
                >
                    <div
                        class="page-error-cont uk-card uk-card-default uk-width-1-1 uk-width-1-2@m uk-align-center"
                    >
                        <div class="uk-card-header">
                            <div class="uk-grid-small uk-flex-middle" uk-grid>
                                <div class="uk-width-auto">
                                    <i class="fas fa-exclamation-circle"></i>
                                </div>
                                <div
                                    class="uk-width-expand uk-padding-remove-left uk-margin-small-left"
                                >
                                    <h3
                                        class="uk-card-title uk-margin-remove-bottom"
                                    >
                                        An Error Has Occurred!
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="uk-card-body">
                            <p>{{ pageLoadError }}</p>
                        </div>
                        <div class="uk-card-footer uk-clearfix">
                            <a
                                @click="$router.go()"
                                class="uk-button uk-button-secondary uk-margin-remove-bottom uk-float-right uk-margin-small-left"
                            >
                                <i
                                    class="fas fa-redo uk-margin-small-right"
                                ></i>
                                Refresh Page
                            </a>
                            <a
                                @click="$router.go(-1)"
                                class="uk-button uk-button-default uk-margin-remove-bottom uk-float-right"
                            >
                                <i
                                    class="fas fa-chevron-left uk-margin-small-right"
                                ></i>
                                Go Back
                            </a>
                        </div>
                    </div>
                </div>
                <div v-else class="uk-grid uk-grid-small">
                    <!-- account status -->
                    <div class="uk-width-1-3@m">
                        <div
                            uk-sticky="media: 960; offset: 150"
                            class="uk-card uk-card-default uk-padding-remove uk-text-small"
                        >
                            <div
                                class="uk-card-header uk-background-primary uk-light uk-padding-small uk-text-left@m uk-text-center"
                            >
                                <h3>Current Search:</h3>
                            </div>
                            <div
                                v-if="isPageLoading"
                                :class="[
                                    { 'uk-animation-fade': !isPageLoading },
                                    'uk-animation-reverse',
                                    'module-spinner'
                                ]"
                            >
                                <div
                                    class="uk-position-center"
                                    uk-spinner
                                ></div>
                            </div>
                            <div
                                v-else
                                :class="[
                                    { 'uk-animation-fade': !isPageLoading },
                                    'uk-card-body',
                                    'uk-padding-small'
                                ]"
                            >
                                <div v-if="isSearchType('phone')">
                                    <p>
                                        <i class="fas fa-phone"></i>
                                        {{ reportListings.query.phone_number }}
                                    </p>
                                </div>
                                <div v-else-if="isSearchType('address')">
                                    <p>
                                        <i class="fas fa-home"></i>
                                        {{
                                            getLocationString(
                                                reportListings.query
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-2-3@m">
                        <div class="uk-card uk-width-1-1">
                            <div
                                class="uk-card-header uk-padding-small uk-background-primary uk-light"
                            >
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-expand@m uk-width-1-1">
                                        <h3
                                            class="uk-margin-remove-bottom uk-text-left@m uk-text-center"
                                        >
                                            Search Results
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="isPageLoading"
                                :class="[
                                    { 'uk-animation-fade': !isPageLoading },
                                    'uk-animation-reverse',
                                    'module-spinner',
                                    'active-reports-spinner'
                                ]"
                            >
                                <div
                                    class="uk-position-center"
                                    uk-spinner
                                ></div>
                            </div>
                            <div
                                v-else
                                :class="[
                                    { 'uk-animation-fade': !isPageLoading },
                                    'uk-card-body',
                                    'uk-padding-small'
                                ]"
                            >
                                <div
                                    class="uk-margin-small"
                                    v-if="showSearchResults"
                                >
                                    <div v-if="isSearchType('phone')">
                                        <div
                                            class="uk-card uk-padding-remove uk-text-center uk-text-left@m"
                                            v-for="result in reportListings.results"
                                            :key="result.identifier"
                                        >
                                            <div
                                                class="uk-card uk-card-default uk-margin-small"
                                            >
                                                <h3>
                                                    {{
                                                        result.owners[0]
                                                            .phones[0]
                                                            .phone_number
                                                    }}
                                                </h3>
                                                <p>
                                                    {{
                                                        result.owners[0].phones[0].line_type.join(
                                                            ", "
                                                        )
                                                    }}
                                                </p>
                                                <p>
                                                    {{
                                                        getLocationString(
                                                            result.owners[0]
                                                                .locations[0]
                                                        )
                                                    }}
                                                </p>
                                                <router-link
                                                    :to="{
                                                        name: 'report',
                                                        params: {
                                                            id:
                                                                result.identifier,
                                                            type: 'phone'
                                                        }
                                                    }"
                                                >
                                                    View full report
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="isSearchType('address')">
                                        <div
                                            class="uk-card uk-padding-remove uk-text-center uk-text-left@m"
                                            v-for="result in reportListings.results"
                                            :key="result.identifier"
                                        >
                                            <div
                                                class="uk-card uk-card-default uk-margin-small"
                                            >
                                                <h3>
                                                    {{
                                                        getLocationString(
                                                            result.location
                                                        )
                                                    }}
                                                </h3>
                                                <hr />
                                                <div>
                                                    <p>
                                                        <b>
                                                            Current Residents:
                                                        </b>
                                                    </p>
                                                    <div
                                                        v-for="(resident,
                                                        index) in result.residents"
                                                        :key="
                                                            'resident' + index
                                                        "
                                                    >
                                                        <p>Name:</p>
                                                        <p>
                                                            {{
                                                                resident
                                                                    .identity
                                                                    .name
                                                            }}
                                                        </p>
                                                        <p>Phone numbers:</p>
                                                        <p
                                                            v-for="(phone,
                                                            index) in resident.phones"
                                                            :key="
                                                                'resident-phone' +
                                                                    index
                                                            "
                                                        >
                                                            {{
                                                                phone.phone_number
                                                            }}
                                                        </p>
                                                        <p>Associates:</p>
                                                        <p
                                                            v-for="(associate,
                                                            index) in resident.associates"
                                                            :key="
                                                                'resident-assoc' +
                                                                    index
                                                            "
                                                        >
                                                            {{
                                                                associate
                                                                    .identity
                                                                    .name
                                                            }}
                                                            |
                                                            {{
                                                                associate.relation
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div>
                                                    <p>
                                                        <b>
                                                            Previous Residents:
                                                        </b>
                                                    </p>
                                                    <div
                                                        v-for="(resident,
                                                        index) in result.past_residents"
                                                        :key="'prevres' + index"
                                                    >
                                                        <p>Name:</p>
                                                        <p>
                                                            {{
                                                                resident
                                                                    .identity
                                                                    .name
                                                            }}
                                                        </p>
                                                        <p>Phone numbers:</p>
                                                        <p
                                                            v-for="(phone,
                                                            index) in resident.phones"
                                                            :key="
                                                                'prevres-phone' +
                                                                    index
                                                            "
                                                        >
                                                            {{
                                                                phone.phone_number
                                                            }}
                                                        </p>
                                                        <p>Associates:</p>
                                                        <p
                                                            v-for="(associate,
                                                            index) in resident.associates"
                                                            :key="
                                                                'prevres-assoc' +
                                                                    index
                                                            "
                                                        >
                                                            {{
                                                                associate
                                                                    .identity
                                                                    .name
                                                            }}
                                                            |
                                                            {{
                                                                associate.relation
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <router-link
                                                    :to="{
                                                        name: 'report',
                                                        params: {
                                                            id:
                                                                result.identifier,
                                                            type: 'address'
                                                        }
                                                    }"
                                                >
                                                    View full report
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="isSearchType('person')">
                                        <div
                                            class="uk-card uk-padding-remove uk-text-center uk-text-left@m"
                                            v-for="result in reportListings.results"
                                            :key="result.identifier"
                                        >
                                            <div
                                                class="uk-card uk-card-default uk-margin-small"
                                            >
                                                <p>
                                                    Name:
                                                    {{ result.identity.name }}
                                                </p>
                                                <p>
                                                    DOB:
                                                    {{
                                                        result.identity
                                                            .date_of_birth
                                                    }}
                                                </p>
                                                <p>
                                                    Age:
                                                    {{ result.identity.age }}
                                                </p>
                                                <h6>Locations:</h6>
                                                <template
                                                    v-for="(location,
                                                    index) in result.locations"
                                                >
                                                    <p :key="'loc-' + index">
                                                        {{
                                                            getLocationString(
                                                                location
                                                            )
                                                        }}
                                                    </p>
                                                </template>
                                                <h6>Phones:</h6>
                                                <template
                                                    v-for="(phone,
                                                    index) in result.phones"
                                                >
                                                    <p :key="'pho-' + index">
                                                        {{ phone.phone_number }}
                                                    </p>
                                                </template>
                                                <h6>Emails:</h6>
                                                <template
                                                    v-for="(email,
                                                    index) in result.emails"
                                                >
                                                    <p :key="'ema-' + index">
                                                        {{
                                                            email.email_address
                                                        }}
                                                    </p>
                                                </template>
                                                <h6>Associates:</h6>
                                                <template
                                                    v-for="(associate,
                                                    index) in result.associates"
                                                >
                                                    <p :key="'assn-' + index">
                                                        Name:
                                                        {{
                                                            associate.identity
                                                                .name
                                                        }}
                                                    </p>
                                                    <p :key="'assr-' + index">
                                                        Relation:
                                                        {{ associate.relation }}
                                                    </p>
                                                </template>
                                                <router-link
                                                    :to="{
                                                        name: 'report',
                                                        params: {
                                                            id:
                                                                result.identifier,
                                                            type: 'person'
                                                        }
                                                    }"
                                                >
                                                    View full report
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-margin-small" v-else>
                                    <div
                                        class="uk-card uk-padding-remove uk-text-center uk-text-left@m"
                                    >
                                        <div
                                            class="uk-card uk-card-default uk-margin-small"
                                        >
                                            <div
                                                class="uk-card-body uk-padding-small"
                                            >
                                                No Results Found
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash/core";
import { PageHelpers } from "@/mixins/PageHelpers";
import Search from "@/components/Search";

export default {
    middleware: "auth",
    name: "ReportListings",
    mixins: [PageHelpers],
    components: { Search },
    metaInfo() {
        return { title: this.getPageTitle };
    },
    props: {
        reportListings: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        isPageLoading: true,
        pageLoadError: ""
    }),
    computed: {
        pageFailedToLoad() {
            return !_.isEmpty(this.pageLoadError);
        },
        getPageTitle() {
            let title = "Search Results";

            if (
                !_.isEmpty(this.reportListings) &&
                !_.isEmpty(this.reportListings.query)
            ) {
                if (this.reportListings.type === "person") {
                    title =
                        "Results for " +
                        this.reportListings.query.first_name +
                        " " +
                        this.reportListings.query.last_name;
                } else if (this.reportListings.type === "phone") {
                    title =
                        "Results for " + this.reportListings.query.phone_number;
                } else if (this.reportListings.type === "address") {
                    title = "Results for " + this.reportListings.query.address;
                }
            }
            return title;
        },
        showSearchResults() {
            return !_.isEmpty(this.reportListings.results);
        }
    },
    created() {
        if (_.isEmpty(this.reportListings)) {
            this.pageLoadError =
                "Page failed to load. Please refresh and try again";
        }

        this.isPageLoading = false;
        this.$store.dispatch("page/updateLoadingStatus", false);
    },
    methods: {
        isSearchType(type) {
            return type === this.reportListings.type;
        }
    }
};
</script>
