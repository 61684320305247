<template>
    <div>
        <Search></Search>
        <div class="uk-section-muted uk-padding-large">
            <div class="uk-container">
                <div class="uk-grid uk-grid-small">
                    <div class="uk-width-1-3@m">
                        <div
                            uk-sticky="media: 960; offset: 150"
                            class="uk-card uk-card-default uk-card-body uk-padding-remove uk-text-small uk-sticky"
                        >
                            <div
                                class="uk-card-header uk-background-primary uk-light uk-padding-small uk-text-left@m uk-text-center"
                            >
                                <h3>Report Profile</h3>
                            </div>
                        </div>
                        <div
                            class="uk-sticky-placeholder"
                            style="height: 559px; margin: 0px;"
                            hidden=""
                        ></div>
                    </div>
                    <div class="uk-width-2-3@m">
                        <br class="uk-hidden@m" />
                        <div>
                            <div class="uk-card uk-width-1-1">
                                <div
                                    class="uk-card-header uk-padding-small uk-background-primary uk-light"
                                >
                                    <div
                                        class="uk-grid-small uk-grid uk-grid-stack"
                                        uk-grid=""
                                    >
                                        <div
                                            class="uk-width-expand@m uk-width-1-1 uk-first-column"
                                        >
                                            <h3
                                                class="uk-margin-remove-bottom uk-text-left@m uk-text-center"
                                            >
                                                Report Findings
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <component
                                    :is="reportComponent"
                                    :report="report"
                                ></component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash/core";
import axios from "axios";
import { PageHelpers } from "@/mixins/PageHelpers";
import Search from "@/components/Search";
import ReportComponents from "@/components/Report";
import SocialIcons from "@/components/Report/SocialIcons";

export default {
    middleware: "auth",
    name: "Report",
    mixins: [PageHelpers],
    components: { Search, ...ReportComponents, SocialIcons },
    metaInfo() {
        if (!_.isEmpty(this.type)) {
            return { title: this.getReportTypeName(this.type) + " Report" };
        }
        return { title: "Report" };
    },
    props: {
        id: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: ""
        }
    },
    data: () => ({
        reportComponent: "",
        createdDate: "",
        reportType: "",
        report: {
            query: {},
            results: {}
        }
    }),
    computed: {
        hasSocialProfileAvatar() {
            return (
                !_.isEmpty(this.report.social) &&
                !_.isEmpty(this.report.social.avatar)
            );
        }
    },
    created() {
        this.reportComponent = this.type + "Report";
        if (!_.isEmpty(this.id) && !_.isEmpty(this.type)) {
            axios
                .get(`/api/search/${this.type}/${this.id}`)
                .then(result => {
                    this.createdDate = result.data.createdDate;
                    delete result.data.createdDate;
                    delete result.data.id;

                    this.report = result.data;
                    this.$store.dispatch("page/updateLoadingStatus", false);
                })
                .catch(() => {
                    this.$store.dispatch("page/updateLoadingStatus", false);
                    this.$store.dispatch(
                        "page/updatePageError",
                        "Page failed to load. Please refresh and try again"
                    );
                });
        } else {
            this.$store.dispatch("page/updateLoadingStatus", false);
            this.$store.dispatch(
                "page/updatePageError",
                "Page failed to load. Please refresh and try again"
            );
        }
    },
    methods: {
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        isReportTypeNotEmpty(reportType) {
            return !_.isEmpty(this.report[reportType]);
        },
        getReportTypeName(reportType) {
            if (reportType === "social") {
                return "Social Media";
            }
            return this.capitalize(reportType);
        },
        getSocialProfileIcon(siteName) {
            const validIcons = ["facebook", "twitter", "instagram"];
            const site = siteName.toLowerCase();

            if (validIcons.includes(site)) {
                return site;
            }
            return "default";
        }
    }
};
</script>
