<template>
    <div class="general-info-checklist uk-text-center">
        <transition
            enter-active-class="uk-animation-slide-bottom-medium"
            leave-active-class="uk-animation-slide-top-medium uk-animation-reverse"
        >
            <div v-if="isProgressSectionShown">
                <div class="uk-text-large uk-padding">
                    <span>Searching for {{ currentChecklistItem.title }}</span>
                    <div>{{ percentageVal }}%</div>
                    <div class="uk-flex uk-flex-center">
                        <progress
                            class="uk-progress uk-width-1-3@m uk-width-1-1"
                            :value="percentageVal"
                            :max="percentageMax"
                        ></progress>
                    </div>
                </div>
                <ul class="search-list uk-grid">
                    <li
                        v-for="(item, index) in checklistItems"
                        :key="index"
                        :class="[
                            {
                                'active-checklist': isChecklistItemActive(
                                    index
                                ),
                                'completed-checklist': isChecklistItemCompleted(
                                    index
                                )
                            },
                            'uk-width-1-3@m',
                            'uk-text-muted'
                        ]"
                    >
                        <span
                            v-show="isChecklistItemCompleted(index)"
                            uk-icon="check"
                        ></span>
                        <span
                            v-show="isChecklistItemSpinnerShown(index)"
                            uk-spinner="ratio:.4;"
                        ></span>
                        <span>{{ item }}</span>
                    </li>
                </ul>
            </div>
        </transition>
        <transition
            enter-active-class="uk-animation-slide-bottom-medium"
            leave-active-class="uk-animation-slide-top-medium uk-animation-reverse"
        >
            <div v-if="isSuccessSectionShown" class="uk-text-large uk-padding">
                <span class="success-message" uk-icon="check"></span> GENERAL
                INFORMATION RESEARCH COMPLETE
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "GeneralInfoChecklist",
    props: {
        enabled: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        isProgressSectionShown: true,
        isSuccessSectionShown: false,
        checklistItemRunTime: 500,
        successSectionRunTime: 1500,
        currentChecklistItem: {
            title: "",
            index: null,
            intervalFunc: null
        },
        checklistItems: [
            "Personal Info",
            "Location Data",
            "Contact Info",
            "Traffic Offenses",
            "Arrests & Convictions",
            "Financial problems",
            "Related Persons",
            "Licenses",
            "Career Info"
        ],
        percentageVal: 0,
        percentageMax: 100
    }),
    watch: {
        enabled(val) {
            if (val) {
                this.startStepComponent();
            }
        }
    },
    created() {
        if (this.enabled) {
            this.startStepComponent();
        }
    },
    methods: {
        startStepComponent() {
            this.currentChecklistItem.title = this.checklistItems[0];
            this.currentChecklistItem.index = 0;
        },
        endStepComponent() {
            const self = this;
            self.isProgressSectionShown = false;
            self.isSuccessSectionShown = true;
            setTimeout(() => {
                self.$emit("onStepComplete");
            }, self.successSectionRunTime);
        },
        isChecklistItemActive(index) {
            if (
                this.currentChecklistItem.index !== null &&
                Number(index) === this.currentChecklistItem.index
            ) {
                if (this.currentChecklistItem.intervalFunc === null) {
                    this.startProgressPercentage();
                }
                return true;
            }
            return false;
        },
        isChecklistItemCompleted(index) {
            return (
                this.currentChecklistItem.index !== null &&
                Number(index) < this.currentChecklistItem.index
            );
        },
        isChecklistItemSpinnerShown(index) {
            return (
                this.currentChecklistItem.index !== null &&
                Number(index) === this.currentChecklistItem.index
            );
        },
        startProgressPercentage: function() {
            const self = this;
            self.currentChecklistItem.intervalFunc = setInterval(() => {
                self.percentageVal += Math.floor(Math.random() * 10);

                if (self.percentageVal >= self.percentageMax) {
                    self.nextChecklistItem();
                }
            }, self.checklistItemRunTime);
        },
        nextChecklistItem() {
            this.percentageVal = 0;
            clearInterval(this.currentChecklistItem.intervalFunc);
            this.currentChecklistItem.intervalFunc = null;
            this.currentChecklistItem.index++;

            if (this.currentChecklistItem.index < this.checklistItems.length) {
                this.currentChecklistItem.title = this.checklistItems[
                    this.currentChecklistItem.index
                ];
            } else {
                this.currentChecklistItem = {
                    index: null,
                    title: "",
                    intervalFunc: null
                };
                this.endStepComponent();
            }
        }
    }
};
</script>
