import _ from "lodash";

// actions
export const actions = {
    fetchInitData({ commit }) {
        const configInitData = JSON.parse(window.configInitData);

        if (!_.isEmpty(configInitData)) {
            Object.keys(configInitData).forEach(function(moduleName) {
                let typeName =
                        "SAVE_" + moduleName.toUpperCase() + "_CONFIG_DATA",
                    commitPath = moduleName + "/" + typeName;

                commit(
                    commitPath,
                    { data: configInitData[moduleName] },
                    { root: true }
                );
            });
        }
    }
};
