<template>
    <div>
        <SearchProgress>
            <ReportListings :report-listings="reportListings"></ReportListings>
        </SearchProgress>
    </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import { mapGetters } from "vuex";
import { PageHelpers } from "@/mixins/PageHelpers";
import SearchProgress from "@/components/SearchProgress";
import ReportListings from "@/pages/ReportListings";

export default {
    middleware: "auth",
    name: "ReportSearch",
    mixins: [PageHelpers],
    components: { SearchProgress, ReportListings },
    data: () => ({
        pageLoadError: "",
        createdDate: "",
        reportListings: {},
        search: {
            type: "",
            params: {}
        }
    }),
    computed: {
        ...mapGetters("search", {
            getSearchType: "searchType",
            getSearchParams: "searchParams"
        })
    },
    created() {
        this.search = {
            type: this.getSearchType,
            params: this.getSearchParams
        };
        this.$store.dispatch("page/updateLoadingStatus", false);
    },
    mounted() {
        if (!_.isEmpty(this.search.type) && !_.isEmpty(this.search.params)) {
            axios
                .post(`/api/search/${this.search.type}`, this.search.params)
                .then(result => {
                    delete result.data.id;

                    this.reportListings = result.data;
                })
                .catch(() => {
                    this.$store.dispatch(
                        "page/updatePageError",
                        "Page failed to load. Please refresh and try again"
                    );
                });
        } else {
            this.$router.push({ name: "dashboard" });
        }
    }
};
</script>
