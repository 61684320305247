<template>
    <div>
        <div
            v-if="!isContentShown"
            class="search-progress-cont uk-section-muted uk-dark uk-animation-fade uk-padding-large"
        >
            <div>
                <div class="uk-container uk-container-center uk-text-center">
                    <div class="uk-heading-primary">
                        Gathering information on {{ getSearchParamString }}
                    </div>
                    <component
                        :is="component"
                        v-for="(component, step) in search.steps"
                        v-show="isStepShown(step)"
                        :key="step"
                        :enabled="isStepShown(step)"
                        class="uk-padding"
                        @onStepComplete="goToNextStep(step)"
                    ></component>
                    <div class="uk-divider-icon uk-padding"></div>
                </div>
            </div>
        </div>
        <div v-if="isContentShown">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { PageHelpers } from "@/mixins/PageHelpers";
import SearchStepComponents from "@/components/SearchStep";

export default {
    name: "SearchProgress",
    mixins: [PageHelpers],
    components: {
        ...SearchStepComponents
    },
    metaInfo() {
        if (!_.isEmpty(this.search) && !_.isEmpty(this.search.params)) {
            return { title: this.getSearchParamString + " Search" };
        }
        return { title: "Searching" };
    },
    props: {
        steps: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        currentStep: null,
        contentHidden: true,
        search: {
            steps: [],
            type: "",
            params: {}
        }
    }),
    computed: {
        ...mapGetters("search", {
            getSearchSteps: "funnel",
            getSearchType: "searchType",
            getSearchParams: "searchParams"
        }),
        isContentShown() {
            if (
                _.isEmpty(this.search.steps) ||
                (!this.contentHidden && this.currentStep === null)
            ) {
                return true;
            }
            return false;
        },
        getSearchParamString() {
            if (this.search.type === "person") {
                return (
                    this.search.params.first_name +
                    " " +
                    this.search.params.last_name
                );
            } else if (this.search.type === "address") {
                return this.getLocationString(this.search.params);
            } else if (this.search.type === "phone") {
                return this.search.params.phone_number;
            }
            return "";
        }
    },
    created() {
        this.search = {
            steps: !_.isEmpty(this.steps) ? this.steps : this.getSearchSteps,
            type: this.getSearchType,
            params: this.getSearchParams
        };
        this.currentStep = 0;
    },
    methods: {
        isStepShown(step) {
            return this.contentHidden && Number(step) === this.currentStep;
        },
        goToNextStep(step) {
            if (Number(step) !== this.currentStep) {
                return;
            }

            if (this.currentStep < this.search.steps.length - 1) {
                this.currentStep++;
            } else {
                this.currentStep = null;
                this.contentHidden = false;
            }
        }
    }
};
</script>
