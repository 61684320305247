<template>
    <div class="uk-section-muted uk-padding-large">
        <div class="uk-container">
            <div
                v-if="pageFailedToLoad"
                :class="[
                    { 'uk-animation-slide-top-medium': pageFailedToLoad },
                    'uk-height-large'
                ]"
            >
                <div
                    class="page-error-cont uk-card uk-card-default uk-width-1-1 uk-width-1-2@m uk-align-center"
                >
                    <div class="uk-card-header">
                        <div class="uk-grid-small uk-flex-middle" uk-grid>
                            <div class="uk-width-auto">
                                <i class="fas fa-exclamation-circle"></i>
                            </div>
                            <div
                                class="uk-width-expand uk-padding-remove-left uk-margin-small-left"
                            >
                                <h3
                                    class="uk-card-title uk-margin-remove-bottom"
                                >
                                    An Error Has Occurred!
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="uk-card-body">
                        <p>{{ pageLoadError }}</p>
                    </div>
                    <div class="uk-card-footer uk-clearfix">
                        <a
                            @click="$router.go()"
                            class="uk-button uk-button-secondary uk-margin-remove-bottom uk-float-right uk-margin-small-left"
                        >
                            <i class="fas fa-redo uk-margin-small-right"></i>
                            Refresh Page
                        </a>
                        <a
                            @click="$router.go(-1)"
                            class="uk-button uk-button-default uk-margin-remove-bottom uk-float-right"
                        >
                            <i
                                class="fas fa-chevron-left uk-margin-small-right"
                            ></i>
                            Go Back
                        </a>
                    </div>
                </div>
            </div>
            <div v-else class="uk-grid uk-grid-small">
                <div class="uk-width-1-3@m uk-width-1-1 uk-flex-first">
                    <div class="uk-width-1-1">
                        <div class="uk-card uk-card-default uk-padding-remove">
                            <div
                                class="uk-card-header uk-background-primary uk-light uk-padding-small uk-text-left@m uk-text-center"
                            >
                                <h3>Contact Information</h3>
                            </div>
                            <div
                                v-if="isPageLoading"
                                :class="[
                                    { 'uk-animation-fade': !isPageLoading },
                                    'uk-animation-reverse',
                                    'module-spinner'
                                ]"
                            >
                                <div
                                    class="uk-position-center"
                                    uk-spinner
                                ></div>
                            </div>
                            <div
                                v-else
                                :class="[
                                    { 'uk-animation-fade': !isPageLoading },
                                    'uk-card-body',
                                    'uk-padding-small'
                                ]"
                            >
                                <form
                                    class="uk-grid-small uk-width-1-1"
                                    method="post"
                                    @submit.prevent="validateForm('formEmail')"
                                    data-vv-scope="formEmail"
                                    uk-grid
                                >
                                    <VInputWithValidation
                                        v-model="formData.email"
                                        v-validate="'required'"
                                        name="email"
                                        placeholder="Email"
                                        autocomplete="off"
                                        type="text"
                                        class="uk-width-2-3"
                                        :disabled="emailDisabled"
                                        :error="hasError('formEmail.email')"
                                        :valid="email.valid"
                                    ></VInputWithValidation>
                                    <button
                                        v-if="emailDisabled"
                                        @click.prevent="editEmail"
                                        class="uk-button uk-button-primary uk-width-1-3"
                                    >
                                        Edit
                                    </button>
                                    <div
                                        v-else
                                        class="uk-width-1-3 uk-padding-remove-left"
                                    >
                                        <button
                                            type="submit"
                                            class="uk-button uk-button-primary uk-width-3-4 uk-width-2-3@m uk-float-left uk-padding-remove"
                                        >
                                            Save
                                        </button>
                                        <button
                                            @click.prevent="editEmail"
                                            class="uk-button uk-button-secondary uk-width-1-4 uk-width-1-3@m uk-float-left uk-padding-remove"
                                        >
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </div>
                                    <p
                                        v-show="errors.first('formEmail.email')"
                                        class="form-error"
                                    >
                                        {{ errors.first("formEmail.email") }}
                                    </p>
                                </form>
                                <form
                                    class="uk-grid-small uk-width-1-1"
                                    method="post"
                                    @submit.prevent="validateForm('formPhone')"
                                    data-vv-scope="formPhone"
                                    uk-grid
                                >
                                    <VInputWithValidation
                                        v-model="formData.phone_number"
                                        v-validate="'required'"
                                        name="phone_number"
                                        placeholder="Enter Phone Number"
                                        autocomplete="off"
                                        type="text"
                                        class="uk-width-2-3"
                                        :error="
                                            hasError('formPhone.phone_number')
                                        "
                                        :disabled="phoneDisabled"
                                        :valid="phone_number.valid"
                                    ></VInputWithValidation>
                                    <button
                                        v-if="phoneDisabled"
                                        @click.prevent="editPhone"
                                        class="uk-button uk-button-primary uk-width-1-3"
                                    >
                                        Edit
                                    </button>
                                    <div
                                        v-else
                                        class="uk-width-1-3 uk-padding-remove-left"
                                    >
                                        <button
                                            type="submit"
                                            class="uk-button uk-button-primary uk-width-3-4 uk-width-2-3@m uk-float-left uk-padding-remove"
                                        >
                                            Save
                                        </button>
                                        <button
                                            @click.prevent="editPhone"
                                            class="uk-button uk-button-secondary uk-width-1-4 uk-width-1-3@m uk-float-left uk-padding-remove"
                                        >
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </div>
                                    <p
                                        v-show="
                                            errors.first(
                                                'formPhone.phone_number'
                                            )
                                        "
                                        class="form-error"
                                    >
                                        {{
                                            errors.first(
                                                "formPhone.phone_number"
                                            )
                                        }}
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                    <form
                        class="uk-width-1-1"
                        method="post"
                        @submit.prevent="validateForm('formPassword')"
                        data-vv-scope="formPassword"
                    >
                        <div class="uk-card uk-card-default uk-padding-remove">
                            <div
                                class="uk-card-header uk-background-primary uk-light uk-padding-small uk-text-left@m uk-text-center"
                            >
                                <h3>Update Password</h3>
                            </div>
                            <div
                                v-if="isPageLoading"
                                :class="[
                                    { 'uk-animation-fade': !isPageLoading },
                                    'uk-animation-reverse',
                                    'uk-height-small'
                                ]"
                            >
                                <div
                                    class="uk-position-center"
                                    uk-spinner
                                ></div>
                            </div>
                            <div
                                v-else
                                :class="[
                                    { 'uk-animation-fade': !isPageLoading },
                                    'uk-card-body',
                                    'uk-padding-small'
                                ]"
                            >
                                <div class="uk-margin-small-bottom">
                                    <VInputWithValidation
                                        v-model="formData.password"
                                        v-validate="'required'"
                                        name="password"
                                        placeholder="Password"
                                        autocomplete="off"
                                        type="password"
                                        :error="
                                            errors.first(
                                                'formPassword.password'
                                            )
                                        "
                                        :valid="password.valid"
                                    ></VInputWithValidation>
                                </div>
                                <div class="uk-margin-small-top">
                                    <VInputWithValidation
                                        v-model="formData.password_confirmation"
                                        v-validate="'required'"
                                        name="password_confirmation"
                                        placeholder="Confirm Password"
                                        autocomplete="off"
                                        type="password"
                                        :error="
                                            errors.first(
                                                'formPassword.password_confirmation'
                                            )
                                        "
                                        :valid="password_confirmation.valid"
                                    ></VInputWithValidation>
                                </div>
                                <hr />
                                <div>
                                    <button
                                        type="submit"
                                        class="uk-button uk-button-primary uk-width-expand@s uk-width-1-1"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form
                        id="communicationForm"
                        class="uk-width-1-1"
                        method="post"
                        @submit.prevent="updateCommunicationSettings"
                    >
                        <div class="uk-card uk-card-default uk-padding-remove">
                            <div
                                class="uk-card-header uk-background-primary uk-light uk-padding-small uk-text-left@m uk-text-center"
                            >
                                <h3>Communication Settings</h3>
                            </div>
                            <div
                                v-if="isPageLoading"
                                :class="[
                                    { 'uk-animation-fade': !isPageLoading },
                                    'uk-animation-reverse',
                                    'uk-height-small'
                                ]"
                            >
                                <div
                                    class="uk-position-center"
                                    uk-spinner
                                ></div>
                            </div>
                            <div
                                v-else
                                :class="[
                                    { 'uk-animation-fade': !isPageLoading },
                                    'uk-card-body',
                                    'uk-padding-small'
                                ]"
                            >
                                <div
                                    v-for="(setting,
                                    index) in formData.communication"
                                    :key="index"
                                >
                                    <h5
                                        class="uk-text-center uk-text-bold uk-margin-small-top uk-margin-small-bottom"
                                    >
                                        {{
                                            getCommunicationSettingTitle(
                                                setting.type
                                            )
                                        }}
                                    </h5>
                                    <div
                                        class="uk-width-1-1 uk-child-width-1-2 uk-grid-small"
                                        uk-grid
                                    >
                                        <div>
                                            <label
                                                :class="[
                                                    {
                                                        'uk-button-secondary':
                                                            formData
                                                                .communication[
                                                                index
                                                            ]['email'],
                                                        'uk-button-default': !formData
                                                            .communication[
                                                            index
                                                        ]['email']
                                                    },
                                                    'uk-button',
                                                    'uk-width-1-1'
                                                ]"
                                            >
                                                <input
                                                    :id="
                                                        setting.type + '-email'
                                                    "
                                                    v-model="
                                                        formData.communication[
                                                            index
                                                        ]['email']
                                                    "
                                                    v-show="false"
                                                    :name="
                                                        setting.type + '-email'
                                                    "
                                                    class="uk-checkbox"
                                                    type="checkbox"
                                                />
                                                <i
                                                    class="far fa-envelope uk-margin-small-right"
                                                ></i>
                                                Email
                                            </label>
                                        </div>
                                        <div>
                                            <label
                                                :class="[
                                                    {
                                                        'uk-button-secondary':
                                                            formData
                                                                .communication[
                                                                index
                                                            ]['sms'],
                                                        'uk-button-default': !formData
                                                            .communication[
                                                            index
                                                        ]['sms']
                                                    },
                                                    'uk-button',
                                                    'uk-width-1-1'
                                                ]"
                                            >
                                                <input
                                                    :id="setting.type + '-sms'"
                                                    v-model="
                                                        formData.communication[
                                                            index
                                                        ]['sms']
                                                    "
                                                    v-show="false"
                                                    :name="
                                                        setting.type + '-sms'
                                                    "
                                                    class="uk-checkbox"
                                                    type="checkbox"
                                                />
                                                <i
                                                    class="fas fa-mobile-alt uk-margin-small-right"
                                                ></i>
                                                Sms
                                            </label>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        class="uk-button uk-button-primary uk-width-expand@s uk-width-1-1"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="uk-width-2-3@m uk-width-1-1">
                    <div class="uk-card uk-card-default uk-padding-remove">
                        <div
                            class="uk-card-header uk-background-primary uk-light uk-padding-small uk-text-left@m uk-text-center"
                        >
                            <h3>Billing Information</h3>
                        </div>
                        <div
                            v-if="isPageLoading"
                            :class="[
                                { 'uk-animation-fade': !isPageLoading },
                                'uk-animation-reverse',
                                'uk-height-large'
                            ]"
                        >
                            <div class="uk-position-center" uk-spinner></div>
                        </div>
                        <div
                            v-else
                            :class="[
                                { 'uk-animation-fade': !isPageLoading },
                                'uk-card-body',
                                'uk-padding-small'
                            ]"
                        >
                            <div class="uk-width-1-1  uk-text-center">
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-1-1">
                                        Charges will appear on your statement
                                        as:
                                    </div>
                                    <div class="uk-width-1-1">
                                        {{ account.processor.name }}
                                        <br />
                                        <div class="uk-text-meta">
                                            {{
                                                getAddressString(
                                                    account.processor
                                                )
                                            }}
                                            <br />
                                            {{ account.processor.phone }}
                                        </div>
                                        <br />
                                        <a href="/support">Customer Support</a>
                                    </div>
                                </div>
                            </div>
                            <div class="uk-width-1-1">
                                <hr />
                            </div>
                            <div v-if="hasPaymentMethod" class="uk-width-1-1">
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-2-5">
                                        Payment Method:
                                    </div>
                                    <div class="uk-width-3-5 uk-text-right">
                                        {{ account.paymentMethod }}
                                    </div>
                                    <div class="uk-width-1-1">
                                        <a
                                            class="uk-button uk-button-primary uk-width-1-1"
                                            href="#updatePaymentModal"
                                            uk-toggle
                                        >
                                            UPDATE</a
                                        >
                                        <hr />
                                    </div>
                                    <div
                                        id="updatePaymentModal"
                                        class="uk-flex-top"
                                        uk-modal
                                    >
                                        <div
                                            class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical"
                                        >
                                            <button
                                                class="uk-modal-close-default"
                                                type="button"
                                                uk-close
                                            ></button>
                                            <div class="uk-modal-header">
                                                <h2 class="uk-modal-title">
                                                    Update Payment Method
                                                </h2>
                                            </div>
                                            <div class="uk-modal-body">
                                                <div
                                                    v-if="showFormPaymentLoader"
                                                >
                                                    <div
                                                        uk-spinner="ratio: 5"
                                                    ></div>
                                                </div>
                                                <form
                                                    v-else-if="
                                                        showFormPaymentPerson
                                                    "
                                                    class="uk-grid-small uk-width-1-1"
                                                    method="post"
                                                    @submit.prevent="
                                                        validateForm(
                                                            'formPaymentPerson'
                                                        )
                                                    "
                                                    data-vv-scope="formPaymentPerson"
                                                    uk-grid
                                                >
                                                    <VInputWithValidation
                                                        v-model="
                                                            formData
                                                                .paymentMethod
                                                                .name
                                                        "
                                                        v-validate="'required'"
                                                        name="name"
                                                        placeholder="First Last Name"
                                                        type="text"
                                                        class="uk-width-1-1"
                                                        :error="
                                                            errors.first(
                                                                'formPaymentPerson.name'
                                                            )
                                                        "
                                                        :valid="name.valid"
                                                    >
                                                    </VInputWithValidation>
                                                    <VInputWithValidation
                                                        v-model="
                                                            formData
                                                                .paymentMethod
                                                                .postal_code
                                                        "
                                                        v-validate="'required'"
                                                        name="postal_code"
                                                        placeholder="Postal Code"
                                                        type="text"
                                                        class="uk-width-1-1"
                                                        :error="
                                                            errors.first(
                                                                'formPaymentPerson.postal_code'
                                                            )
                                                        "
                                                        :valid="
                                                            postal_code.valid
                                                        "
                                                    ></VInputWithValidation>
                                                    <VSelectWithValidation
                                                        v-model="
                                                            formData
                                                                .paymentMethod
                                                                .country
                                                        "
                                                        v-validate="'required'"
                                                        name="country"
                                                        label="Country"
                                                        :error="
                                                            errors.first(
                                                                'formPaymentPerson.country'
                                                            )
                                                        "
                                                        :valid="country.valid"
                                                    >
                                                        <option
                                                            class="hidden"
                                                            selected
                                                            disabled
                                                            value=""
                                                            >Select
                                                            Country</option
                                                        >
                                                        <option value="us"
                                                            >United
                                                            States</option
                                                        >
                                                    </VSelectWithValidation>
                                                    <button
                                                        type="submit"
                                                        class="uk-button uk-button-secondary uk-width-1-1"
                                                    >
                                                        Payment Info
                                                    </button>
                                                </form>
                                                <form
                                                    v-else
                                                    class="uk-grid-small uk-width-1-1"
                                                    method="post"
                                                    @submit.prevent="
                                                        validateForm(
                                                            'formPaymentCard'
                                                        )
                                                    "
                                                    data-vv-scope="formPaymentCard"
                                                    uk-grid
                                                >
                                                    <VInputWithValidation
                                                        v-model="
                                                            formData
                                                                .paymentMethod
                                                                .cc_number
                                                        "
                                                        v-validate="'required'"
                                                        name="cc_number"
                                                        placeholder="Credit Card Number"
                                                        type="text"
                                                        class="uk-width-1-1"
                                                        :error="
                                                            errors.first(
                                                                'formPaymentPerson.cc_number'
                                                            )
                                                        "
                                                        :valid="cc_number.valid"
                                                    >
                                                    </VInputWithValidation>
                                                    <div class="uk-width-1-1">
                                                        <VInputWithValidation
                                                            v-model="
                                                                formData
                                                                    .paymentMethod
                                                                    .cc_expiration
                                                            "
                                                            v-validate="
                                                                'required'
                                                            "
                                                            name="cc_expiration"
                                                            placeholder="MM / YY"
                                                            type="text"
                                                            class="uk-width-1-2"
                                                            :error="
                                                                errors.first(
                                                                    'formPaymentPerson.cc_expiration'
                                                                )
                                                            "
                                                            :valid="
                                                                cc_expiration.valid
                                                            "
                                                        ></VInputWithValidation>
                                                        <VInputWithValidation
                                                            v-model="
                                                                formData
                                                                    .paymentMethod
                                                                    .cc_cvv
                                                            "
                                                            v-validate="
                                                                'required'
                                                            "
                                                            name="cc_cvv"
                                                            placeholder="CVV"
                                                            type="text"
                                                            class="uk-width-1-2"
                                                            :error="
                                                                errors.first(
                                                                    'formPaymentPerson.cc_cvv'
                                                                )
                                                            "
                                                            :valid="
                                                                cc_cvv.valid
                                                            "
                                                        ></VInputWithValidation>
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        class="uk-button uk-button-primary uk-width-1-1"
                                                    >
                                                        Save
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="hasCurrentPackage" class="uk-width-1-1">
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-1-1">
                                        Current Package:
                                    </div>
                                    <div
                                        class="uk-width-3-5 uk-margin-small-top"
                                    >
                                        {{ account.packages.current.name }}
                                    </div>
                                    <div class="uk-width-2-5 uk-text-right">
                                        <div class="uk-text-meta">
                                            ${{
                                                account.packages.current.total
                                            }}/mo
                                        </div>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <div class="uk-text-meta">
                                            Last billed:
                                            {{
                                                account.packages.current
                                                    .lastDate
                                            }}
                                            | Next rebill:
                                            {{
                                                account.packages.current
                                                    .nextDate
                                            }}
                                        </div>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <div
                                            class="uk-padding-remove uk-margin-remove uk-width-1-1"
                                            uk-grid
                                        >
                                            <a
                                                class="uk-button uk-button-primary uk-width-expand@s uk-width-1-1"
                                                @click.prevent="
                                                    editAccountSetting(
                                                        account.packages.current
                                                            .id
                                                    )
                                                "
                                            >
                                                UPDATE PACKAGE
                                            </a>
                                            <a
                                                class="uk-button uk-button-danger uk-width-auto@s uk-width-1-1"
                                                @click.prevent="
                                                    deletePackage(
                                                        account.packages.current
                                                            .id
                                                    )
                                                "
                                            >
                                                REMOVE
                                            </a>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div v-if="hasService" class="uk-width-1-1">
                                <div class="uk-width-1-1">
                                    Other Services:
                                </div>
                                <div
                                    v-for="(service, index) in account.services"
                                    :key="index"
                                    class="uk-grid-small"
                                    uk-grid
                                >
                                    <div
                                        class="uk-width-3-5 uk-margin-small-top"
                                    >
                                        {{ service.name }}
                                    </div>
                                    <div class="uk-width-2-5 uk-text-right">
                                        <div class="uk-text-meta">
                                            ${{ service.total }}/mo
                                        </div>
                                    </div>
                                    <div
                                        class="uk-width-1-1 uk-margin-remove-top"
                                    >
                                        <div class="uk-text-meta">
                                            Last billed:
                                            {{ service.lastDate }} | Next
                                            rebill:
                                            {{ service.nextDate }}
                                        </div>
                                    </div>
                                    <div class="uk-width-1-1">
                                        <div
                                            class="uk-padding-remove uk-margin-remove uk-width-1-1"
                                            uk-grid
                                        >
                                            <button
                                                class="uk-button uk-button-primary uk-width-expand@s uk-width-1-1"
                                            >
                                                ADD OTHER SERVICE
                                            </button>
                                            <a
                                                class="uk-button uk-button-danger uk-width-auto@s uk-width-1-1"
                                                @click.prevent="
                                                    destroyAccountSetting(
                                                        'service',
                                                        service.id
                                                    )
                                                "
                                            >
                                                REMOVE
                                            </a>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="hasTransactionHistory"
                                class="uk-width-1-1"
                            >
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-1-1">
                                        Transaction History:
                                    </div>
                                    <div
                                        v-for="(transaction,
                                        index) in account.transactions"
                                        :key="index"
                                        class="uk-width-1-1"
                                    >
                                        <div
                                            class="uk-grid-small uk-text-meta"
                                            uk-grid
                                        >
                                            <div
                                                class="uk-width-expand"
                                                uk-leader="fill:."
                                            >
                                                {{ transaction.date }}
                                            </div>
                                            <div>${{ transaction.total }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash/core";
import axios from "axios";
import UIkit from "uikit";
import { mapFields } from "vee-validate";
import { mapGetters } from "vuex";
import { PageHelpers } from "@/mixins/PageHelpers";
import VInputWithValidation from "@/components/VInputWithValidation";
import VSelectWithValidation from "@/components/VSelectWithValidation";

export default {
    components: { VSelectWithValidation, VInputWithValidation },
    middleware: "auth",
    name: "Settings",
    metaInfo: {
        title: "Account Settings"
    },
    mixins: [PageHelpers],
    data: () => ({
        isPageLoading: true,
        pageLoadError: "",
        account: {},
        emailDisabled: true,
        phoneDisabled: true,
        showFormPaymentPerson: true,
        showFormPaymentLoader: false,
        formData: {
            email: "",
            phone_number: "",
            password: "",
            password_confirmation: "",
            communication: {},
            paymentMethod: {
                name: "",
                country: "",
                postal_code: "",
                cc_number: "",
                cc_cvv: "",
                cc_expiration: ""
            }
        }
    }),
    computed: {
        ...mapFields([
            "email",
            "phone_number",
            "password",
            "password_confirmation",
            "name",
            "country",
            "postal_code",
            "cc_number",
            "cc_cvv",
            "cc_expiration"
        ]),
        ...mapGetters("auth", {
            getUser: "user",
            getFormattedPhoneNumber: "getFormattedPhoneNumber"
        }),
        pageFailedToLoad() {
            return !_.isEmpty(this.pageLoadError);
        },
        hasPaymentMethod() {
            return !_.isEmpty(this.account.paymentMethod);
        },
        hasCurrentPackage() {
            return (
                !_.isEmpty(this.account.packages) &&
                !_.isEmpty(this.account.packages.current)
            );
        },
        hasService() {
            return !_.isEmpty(this.account.services);
        },
        hasTransactionHistory() {
            return !_.isEmpty(this.account.transactions);
        }
    },
    created() {
        axios
            .all([this.getAccountSettings(), this.getCommunicationSettings()])
            .then(
                axios.spread((accountResult, communicationResult) => {
                    if (
                        _.isEmpty(accountResult.data) ||
                        _.isEmpty(communicationResult.data)
                    ) {
                        this.pageLoadError =
                            "Page failed to load. Please refresh and try again";
                    } else {
                        this.account = accountResult.data;
                        this.formData.email = this.getUser.email;
                        this.formData.phone_number = this.getFormattedPhoneNumber;
                        this.formData.communication = communicationResult.data;
                    }
                    this.isPageLoading = false;
                    this.$store.dispatch("page/updateLoadingStatus", false);
                })
            )
            .catch(() => {
                this.pageLoadError =
                    "Page failed to load. Please refresh and try again";
                this.isPageLoading = false;
                this.$store.dispatch("page/updateLoadingStatus", false);
            });
    },
    methods: {
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        hasError(name) {
            return !_.isEmpty(this.errors.first(name));
        },
        clearError(findParams) {
            const field = this.$validator.fields.find(findParams);
            field.reset();
        },
        getAddressString(addressObj) {
            return (
                addressObj.street +
                " " +
                addressObj.city +
                ", " +
                addressObj.state +
                " " +
                addressObj.postal
            );
        },
        getCommunicationSettingTitle(settingType) {
            if (settingType === "activity") {
                return "Receive Site Activity Notifications:";
            } else if (settingType === "announcements") {
                return "Receive Announcements:";
            } else if (settingType === "partners") {
                return "Receive Partner Offers:";
            }
            return "";
        },
        getCommunicationSettings() {
            return axios.get("/api/communication");
        },
        getAccountSettings() {
            return axios.get("/api/billing");
        },
        validateForm(formName) {
            this.$validator.validateAll(formName).then(result => {
                if (result) {
                    const submitMethod = formName + "Submit";
                    this[submitMethod]();
                }
            });
        },
        updateCommunicationSettings() {
            axios
                .put("/api/communication", this.formData.communication)
                .then(() => {
                    UIkit.notification(
                        "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-check uk-margin-small-right'></i> Your settings have been updated!</p>",
                        { pos: "top-right", status: "success" }
                    );
                })
                .catch(() => {
                    UIkit.notification(
                        "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-times uk-margin-small-right'></i> An error has occurred!</p><p class='uk-animation-scale-up uk-margin-remove'>Please refresh the page and try again.</p>",
                        { pos: "top-right", status: "danger" }
                    );
                });
        },
        formPasswordSubmit() {
            axios
                .put("/api/user", {
                    password: this.formData.password,
                    password_confirmation: this.formData.password_confirmation
                })
                .then(() => {
                    this.formData.password = "";
                    this.formData.password_confirmation = "";
                    this.clearError({
                        name: "password",
                        scope: "formPassword"
                    });
                    this.clearError({
                        name: "password_confirmation",
                        scope: "formPassword"
                    });
                    this.$validator.reset();
                    UIkit.notification(
                        "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-check uk-margin-small-right'></i> Your password has been updated</p>",
                        { pos: "top-right", status: "success" }
                    );
                })
                .catch(error => {
                    const errors = error.response.data.errors;

                    if (
                        errors !== undefined &&
                        Object.keys(errors).length > 0
                    ) {
                        const fields = Object.keys(errors);

                        fields.forEach(name => {
                            //Set field's flags
                            const field = this.$validator.fields.find({
                                name: name,
                                scope: "formPassword"
                            });
                            field.setFlags({ valid: false, dirty: true });
                            //Add error to error bag
                            this.errors.add({
                                field: name,
                                scope: "formPassword",
                                msg: errors[name][0]
                            });
                        });
                    } else {
                        UIkit.notification(
                            "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-times uk-margin-small-right'></i> An error has occurred!</p><p class='uk-animation-scale-up uk-margin-remove'>Please refresh the page and try again.</p>",
                            { pos: "top-right", status: "danger" }
                        );
                    }
                });
        },
        formEmailSubmit() {
            axios
                .put("/api/user", { email: this.formData.email })
                .then(() => {
                    this.updateUser();
                    this.emailDisabled = true;
                    UIkit.notification(
                        "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-check uk-margin-small-right'></i> Your email address has been updated</p>",
                        { pos: "top-right", status: "success" }
                    );
                })
                .catch(error => {
                    const errors = error.response.data.errors;

                    if (
                        errors !== undefined &&
                        Object.keys(errors).length > 0
                    ) {
                        const fields = Object.keys(errors);

                        fields.forEach(name => {
                            //Set field's flags
                            const field = this.$validator.fields.find({
                                name: name,
                                scope: "formEmail"
                            });
                            field.setFlags({ valid: false, dirty: true });
                            //Add error to error bag
                            this.errors.add({
                                field: name,
                                scope: "formEmail",
                                msg: errors[name][0]
                            });
                        });
                    } else {
                        UIkit.notification(
                            "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-times uk-margin-small-right'></i> An error has occurred!</p><p class='uk-animation-scale-up uk-margin-remove'>Please refresh the page and try again.</p>",
                            { pos: "top-right", status: "danger" }
                        );
                    }
                });
        },
        formPhoneSubmit() {
            axios
                .put("/api/user", {
                    phone_number: this.formData.phone_number.replace(
                        /[^0-9.]/g,
                        ""
                    )
                })
                .then(() => {
                    this.updateUser();
                    this.phoneDisabled = true;
                    UIkit.notification(
                        "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-check uk-margin-small-right'></i> Your phone number has been updated</p>",
                        { pos: "top-right", status: "success" }
                    );
                })
                .catch(error => {
                    const errors = error.response.data.errors;

                    if (
                        errors !== undefined &&
                        Object.keys(errors).length > 0
                    ) {
                        const fields = Object.keys(errors);

                        fields.forEach(name => {
                            //Set field's flags
                            const field = this.$validator.fields.find({
                                name: name,
                                scope: "formPhone"
                            });
                            field.setFlags({ valid: false, dirty: true });
                            //Add error to error bag
                            this.errors.add({
                                field: name,
                                scope: "formPhone",
                                msg: errors[name][0]
                            });
                        });
                    } else {
                        UIkit.notification(
                            "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-times uk-margin-small-right'></i> An error has occurred!</p><p class='uk-animation-scale-up uk-margin-remove'>Please refresh the page and try again.</p>",
                            { pos: "top-right", status: "danger" }
                        );
                    }
                });
        },
        formPaymentPersonSubmit() {
            this.showFormPaymentPerson = false;
        },
        formPaymentCardSubmit() {
            this.showFormPaymentLoader = true;
            axios
                .put("/api/account", this.formData.paymentMethod)
                .then(() => {
                    this.updateUser();
                    this.showFormPaymentLoader = false;
                    this.showFormPaymentPerson = true;
                    this.formData.paymentMethod = {
                        name: "",
                        country: "",
                        postal_code: "",
                        cc_number: "",
                        cc_cvv: "",
                        cc_expiration: ""
                    };
                    UIkit.modal("#updatePaymentModal").hide();
                    UIkit.notification(
                        "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-check uk-margin-small-right'></i> Your new payment method has been saved</p>",
                        { pos: "top-right", status: "success" }
                    );
                })
                .catch(error => {
                    const errors = error.response.data.errors;
                    this.showFormPaymentLoader = false;

                    if (
                        errors !== undefined &&
                        Object.keys(errors).length > 0
                    ) {
                        const fields = Object.keys(errors);

                        fields.forEach(name => {
                            let scope = "formPaymentPerson";

                            if (
                                ["name", "country", "postal_code"].includes(
                                    name
                                )
                            ) {
                                this.showFormPaymentPerson = true;
                                scope = "formPaymentPerson";
                            }

                            //Set field's flags
                            const field = this.$validator.fields.find({
                                name: name,
                                scope: scope
                            });
                            field.setFlags({ valid: false, dirty: true });
                            //Add error to error bag
                            this.errors.add({
                                field: name,
                                scope: scope,
                                msg: errors[name][0]
                            });
                        });
                    } else {
                        UIkit.notification(
                            "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-times uk-margin-small-right'></i> An error has occurred!</p><p class='uk-animation-scale-up uk-margin-remove'>Please refresh the page and try again.</p>",
                            { pos: "top-right", status: "danger" }
                        );
                    }
                });
        },
        async updateUser() {
            await this.$store.dispatch("auth/fetchUser");
            this.formData.phone_number = this.getFormattedPhoneNumber;
        },
        editEmail() {
            this.emailDisabled = !this.emailDisabled;
            if (this.emailDisabled) {
                this.clearError({
                    name: "email",
                    scope: "formEmail"
                });
            }
        },
        editPhone() {
            this.phoneDisabled = !this.phoneDisabled;
            if (this.phoneDisabled) {
                this.clearError({
                    name: "phone_number",
                    scope: "formPhone"
                });
            }
        },
        editAccountSetting(packageId) {
            axios
                .put("/api/account", {
                    data: { type: "package", id: packageId }
                })
                .then(() => {
                    UIkit.notification(
                        "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-check uk-margin-small-right'></i> Subscription has been updated</p>",
                        { pos: "top-right", status: "success" }
                    );
                })
                .catch(() => {
                    UIkit.notification(
                        "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-times uk-margin-small-right'></i> An error has occurred!</p><p class='uk-animation-scale-up uk-margin-remove'>Please refresh the page and try again.</p>",
                        { pos: "top-right", status: "danger" }
                    );
                });
        },
        deletePackage(packageId) {
            axios
                .delete("/api/account", {
                    data: { type: "package", id: packageId }
                })
                .then(() => {
                    UIkit.notification(
                        "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-check uk-margin-small-right'></i> Subscription has been canceled</p>",
                        { pos: "top-right", status: "success" }
                    );
                })
                .catch(() => {
                    UIkit.notification(
                        "<p class='uk-animation-scale-up uk-margin-remove'><i class='fas fa-times uk-margin-small-right'></i> An error has occurred!</p><p class='uk-animation-scale-up uk-margin-remove'>Please refresh the page and try again.</p>",
                        { pos: "top-right", status: "danger" }
                    );
                });
        }
    }
};
</script>
