<template>
    <div class="social-media-progress-bar uk-text-center uk-padding">
        <transition
            enter-active-class="uk-animation-slide-bottom-medium"
            leave-active-class="uk-animation-slide-top-medium uk-animation-reverse"
        >
            <div v-if="isProgressSectionShown">
                <span>Searching for Social Media Profiles</span>
                <div>{{ percentageVal }}%</div>
                <div class="uk-flex uk-flex-center">
                    <progress
                        class="uk-progress uk-width-1-3@m uk-width-1-1"
                        :value="percentageVal"
                        :max="percentageMax"
                    ></progress>
                </div>
                <ul class="uk-flex uk-flex-center uk-padding-remove">
                    <li
                        v-for="(item, index) in socialItems"
                        v-show="isSocialItemActive(index)"
                        :key="index"
                    >
                        <img :src="item" width="300" />
                    </li>
                </ul>
            </div>
        </transition>
        <transition
            enter-active-class="uk-animation-slide-bottom-medium"
            leave-active-class="uk-animation-slide-top-medium uk-animation-reverse"
        >
            <div v-if="isSuccessSectionShown" class="uk-text-large uk-padding">
                <span class="success-message" uk-icon="check"></span> SOCIAL
                MEDIA RESEARCH COMPLETE
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "SocialMediaProgressBar",
    props: {
        enabled: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        isProgressSectionShown: true,
        isSuccessSectionShown: false,
        socialItemRunTime: 770,
        successSectionRunTime: 1500,
        progressBarIntervalFunc: null,
        currentSocialItemIndex: null,
        socialItems: [
            "/images/social/1.jpg",
            "/images/social/2.jpg",
            "/images/social/3.jpg",
            "/images/social/4.jpg",
            "/images/social/5.jpg",
            "/images/social/6.jpg",
            "/images/social/7.jpg",
            "/images/social/8.jpg",
            "/images/social/9.jpg",
            "/images/social/10.jpg",
            "/images/social/11.jpg",
            "/images/social/12.jpg",
            "/images/social/13.jpg"
        ],
        percentageVal: 0,
        percentageMax: 100
    }),
    watch: {
        enabled(val) {
            if (val) {
                this.startStepComponent();
            }
        }
    },
    created() {
        if (this.enabled) {
            this.startStepComponent();
        }
    },
    methods: {
        startStepComponent() {
            this.currentSocialItemIndex = 0;
        },
        endStepComponent() {
            const self = this;
            self.isProgressSectionShown = false;
            self.isSuccessSectionShown = true;
            setTimeout(() => {
                self.$emit("onStepComplete");
            }, self.successSectionRunTime);
        },
        isSocialItemActive(index) {
            if (
                this.currentSocialItemIndex !== null &&
                Number(index) === this.currentSocialItemIndex
            ) {
                if (this.progressBarIntervalFunc === null) {
                    this.startProgressPercentage();
                }
                return true;
            }
            return false;
        },
        startProgressPercentage: function() {
            const self = this;
            self.progressBarIntervalFunc = setInterval(() => {
                self.percentageVal += Math.floor(
                    this.percentageMax / this.socialItems.length
                );
                self.nextSocialItem();
            }, self.socialItemRunTime);
        },
        nextSocialItem() {
            this.currentSocialItemIndex++;

            if (this.currentSocialItemIndex >= this.socialItems.length) {
                this.progressBarIntervalFunc = null;
                this.currentSocialItemIndex = null;
                this.endStepComponent();
            }
        }
    }
};
</script>
